import { MaterialCommunityIcons } from "@expo/vector-icons";
import { Text } from "components";
import colors from "config/colors";
import { DateTime } from "luxon";
import { useEffect, useMemo, useState } from "react";
import {
  ActivityIndicator,
  Easing,
  TouchableOpacity,
  View,
} from "react-native";
import { Snackbar } from "react-native-paper";
import * as Progress from "react-native-progress";
import { useSelector } from "react-redux";
import usePrevious from "react-use/lib/usePrevious";
import { useAppDispatch } from "store/hooks";
import { OrderThunks } from "store/Orders";
import { ItemPreparationStatuses } from "store/Orders/enums";
import type { Order, OrderItem } from "store/Orders/types";
import { SettingSelectors } from "store/Settings";
import { UserSelectors } from "store/User";
import type { MaterialCommunityIconName } from "types/icons";
import {
  COUNTDOWN_INTERVAL_MS,
  getMetaForItem,
  getMetaForOrder,
  useCountdown,
} from "./helpers";
import { styles } from "./styles";

type ActionButtonBaseProps = {
  iconName: MaterialCommunityIconName;
  iconColor: string;
  labelKey: string;
  onPress: () => void | Promise<void>;
  order: Order;
  isScheduled?: boolean;
  isDisabled?: boolean;
};

const ActionButtonBase = (props: ActionButtonBaseProps) => {
  const {
    iconName,
    iconColor,
    labelKey,
    onPress: onPressProp,
    order,
    isScheduled,
    isDisabled: isDisabledProps = false,
  } = props;

  const isScheduledLaterDay =
    isScheduled &&
    order.scheduled_for &&
    DateTime.fromISO(order.scheduled_for) > DateTime.now().endOf("day");

  const [isSnackbarVisible, setSnackbarVisibility] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const isDelayEnabled = useSelector(
    SettingSelectors.selectItemCookDelayEnabled
  );
  const delayInSeconds = useSelector(
    SettingSelectors.selectItemCookDelayInSeconds
  );
  const delayInSecondsPrev = usePrevious(delayInSeconds);

  const [countdownValue, countdownIsRunning, [startCountdown, resetCountdown]] =
    useCountdown(delayInSeconds);

  const countdownValuePrev = usePrevious(countdownValue);

  useEffect(() => {
    setSnackbarVisibility(false);
    resetCountdown();
  }, [order.uuid]);

  useEffect(() => {
    if (delayInSeconds !== delayInSecondsPrev) {
      resetCountdown();
    }
  }, [delayInSeconds, delayInSecondsPrev, resetCountdown]);

  useEffect(() => {
    if (
      countdownValue !== countdownValuePrev &&
      countdownValue <= 0 &&
      countdownValuePrev > 0 &&
      countdownIsRunning
    ) {
      resetCountdown();
      changeStatus();
    }
  }, [countdownValue, countdownValuePrev, countdownIsRunning]);

  const changeStatus = async () => {
    setIsFetching(true);
    await onPressProp();
    setIsFetching(false);
  };

  const isDisabled = isDisabledProps || isScheduledLaterDay;

  const onPress = () => {
    if (isDisabled) {
      setSnackbarVisibility(true);
      return;
    }

    if (countdownIsRunning) {
      resetCountdown();
    } else if (isDelayEnabled && delayInSeconds > 0) {
      startCountdown();
    } else {
      changeStatus();
    }
  };

  return (
    <>
      <TouchableOpacity
        disabled={isDisabledProps || isFetching}
        onPress={(e) => {
          e.stopPropagation();
          e.preventDefault();

          onPress();
        }}
        activeOpacity={isDisabled ? 0.4 : 0.75}
        style={[styles.container, isDisabled ? { opacity: 0.4 } : {}]}
      >
        {isFetching ? (
          <ActivityIndicator size="large" color="#333" />
        ) : (
          <MaterialCommunityIcons
            name={countdownIsRunning ? "undo" : iconName}
            color={countdownIsRunning ? "#ff8218" : iconColor}
            size={36}
          />
        )}
        {!isFetching && (
          <Text
            font={"bold"}
            color={"#888888"}
            id={countdownIsRunning ? "common.actionButton.undoStart" : labelKey}
            size={12}
            align="center"
          />
        )}
        <Progress.Bar
          progress={
            // this is needed in order to start the animation instantly after the button is pressed
            countdownIsRunning ? (countdownValue - 1) / delayInSeconds : 1
          }
          width={72}
          height={7}
          borderWidth={0}
          color={
            countdownIsRunning ? colors.ui.statuses.cooking : "transparent"
          }
          unfilledColor={"transparent"}
          animated={countdownIsRunning}
          animationType={"timing"}
          animationConfig={{
            duration: COUNTDOWN_INTERVAL_MS,
            easing: Easing.linear,
            useNativeDriver: true,
          }}
        />
      </TouchableOpacity>
      <Snackbar
        visible={isSnackbarVisible}
        duration={5000}
        onDismiss={() => setSnackbarVisibility(false)}
        style={styles.snackbar}
      >
        <Text
          id="common.actionButton.snackbar.tooEarly"
          numberOfLines={3}
          style={styles.snackbarText}
        />
      </Snackbar>
    </>
  );
};

interface ActionButtonItemProps {
  order: Order;
  item: OrderItem;
  isScheduled: boolean;
  includePrintButton?: boolean;
  onPressFinish?: (
    status: ItemPreparationStatuses,
    duration: number,
    startDate: Date,
    endDate: Date
  ) => void;
  onPressFinishAndPrint?: (
    status: ItemPreparationStatuses,
    duration: number,
    startDate: Date,
    endDate: Date
  ) => void;
}

const ActionButtonItem = ({
  item,
  onPressFinish: onPressFinishProp,
  onPressFinishAndPrint: onPressFinishAndPrintProp,
  includePrintButton,
  order,
  isScheduled,
}: ActionButtonItemProps) => {
  const dispatch = useAppDispatch();

  const featureFlags = useSelector(UserSelectors.selectFeatureFlags);

  const { iconName, iconColor, labelKey, statusToUpdate } = useMemo(() => {
    return getMetaForItem(item.status, item.cookable);
  }, [item]);

  const updateItemStatus = async () => {
    return await dispatch(
      OrderThunks.updateItemStatus({
        id: item.uuid,
        status: statusToUpdate,
      })
    );
  };

  const printLabel = async () => {
    return await dispatch(
      OrderThunks.printItem({
        id: item.uuid,
      })
    );
  };

  const onPressFinish = async () => {
    const startDate = new Date();
    const updateItemStatusResponse = await updateItemStatus();
    if (updateItemStatusResponse.meta.requestStatus !== "fulfilled") return;

    const endDate = new Date();

    const duration = endDate.getTime() - startDate.getTime();

    onPressFinishProp &&
      onPressFinishProp(statusToUpdate, duration, startDate, endDate);
  };

  const onPressFinishAndPrint = async () => {
    const startDate = new Date();

    const updateItemStatusResponse = await updateItemStatus();
    if (updateItemStatusResponse.meta.requestStatus !== "fulfilled") return;
    const endDate = new Date();
    const duration = endDate.getTime() - startDate.getTime();

    printLabel();

    onPressFinishAndPrintProp &&
      onPressFinishAndPrintProp(statusToUpdate, duration, startDate, endDate);
  };

  if (!statusToUpdate) return null;

  const shoudDisplayActionButton =
    !includePrintButton ||
    featureFlags.display_finish_button ||
    statusToUpdate !== ItemPreparationStatuses.PREPARED;

  const shouldDsplayPrintButton =
    includePrintButton &&
    featureFlags.display_finish_and_print_button &&
    statusToUpdate === ItemPreparationStatuses.PREPARED;

  return (
    <View style={styles.buttonGroup}>
      {shoudDisplayActionButton && (
        <ActionButtonBase
          iconName={iconName}
          iconColor={iconColor}
          labelKey={labelKey}
          onPress={onPressFinish}
          order={order}
          isScheduled={isScheduled}
        />
      )}
      {shouldDsplayPrintButton && (
        <ActionButtonBase
          iconName={"printer"}
          iconColor={iconColor}
          labelKey={"common.actionButton.finishAndPrint"}
          onPress={onPressFinishAndPrint}
          order={order}
          isScheduled={isScheduled}
        />
      )}
    </View>
  );
};

interface ActionButtonOrderProps {
  order: Order;
  items: OrderItem[];
  isScheduled: boolean;
  includePrintButton?: boolean;
  onPressFinish?: (
    status: ItemPreparationStatuses,
    itemsToUpdate: OrderItem[],
    duration: number,
    startDate: Date,
    endDate: Date
  ) => void;
  onPressFinishAndPrint?: (
    status: ItemPreparationStatuses,
    itemsToUpdate: OrderItem[],
    duration: number,
    startDate: Date,
    endDate: Date
  ) => void;
}

const ActionButtonOrder = ({
  items,
  onPressFinish: onPressFinishProp,
  onPressFinishAndPrint: onPressFinishAndPrintProp,
  order,
  isScheduled,
  includePrintButton,
}: ActionButtonOrderProps) => {
  const dispatch = useAppDispatch();

  const featureFlags = useSelector(UserSelectors.selectFeatureFlags);

  const { iconName, iconColor, labelKey, statusToUpdate, itemsToUpdate } =
    useMemo(() => {
      const { iconName, iconColor, labelKey, statusToUpdate } = getMetaForOrder(
        order,
        items
      );

      const itemsToUpdate =
        statusToUpdate === ItemPreparationStatuses.BEING_PREPARED
          ? items.filter((item) => item.cookable)
          : items;

      return {
        iconName,
        iconColor,
        labelKey,
        statusToUpdate,
        itemsToUpdate,
      };
    }, [order, items]);

  const updateItemStatus = async () => {
    return await dispatch(
      OrderThunks.updateItemStatuses({
        uuids: itemsToUpdate.map((item) => item.uuid),
        status: statusToUpdate,
      })
    );
  };

  const printLabel = async () => {
    return await dispatch(
      OrderThunks.printOrder({
        id: order.uuid,
        itemUUIDs: itemsToUpdate.map((item) => item.uuid),
      })
    );
  };

  const onPressFinish = async () => {
    const startDate = new Date();

    const updateItemStatusResponse = await updateItemStatus();
    if (updateItemStatusResponse.meta.requestStatus !== "fulfilled") return;
    const endDate = new Date();
    const duration = endDate.getTime() - startDate.getTime();

    onPressFinishProp &&
      onPressFinishProp(
        statusToUpdate,
        itemsToUpdate,
        duration,
        startDate,
        endDate
      );
  };

  const onPressFinishAndPrint = async () => {
    const startDate = new Date();

    const updateItemStatusResponse = await updateItemStatus();
    if (updateItemStatusResponse.meta.requestStatus !== "fulfilled") return;
    const endDate = new Date();
    const duration = endDate.getTime() - startDate.getTime();

    printLabel();

    onPressFinishAndPrintProp &&
      onPressFinishAndPrintProp(
        statusToUpdate,
        itemsToUpdate,
        duration,
        startDate,
        endDate
      );
  };

  if (!statusToUpdate) return null;

  const shoudDisplayActionButton =
    !includePrintButton ||
    featureFlags.display_finish_button ||
    statusToUpdate !== ItemPreparationStatuses.PREPARED;

  const shouldDsplayPrintButton =
    includePrintButton &&
    featureFlags.display_finish_and_print_button &&
    statusToUpdate === ItemPreparationStatuses.PREPARED;

  return (
    <View style={styles.buttonGroup}>
      {shoudDisplayActionButton && (
        <ActionButtonBase
          iconName={iconName}
          iconColor={iconColor}
          labelKey={labelKey}
          onPress={onPressFinish}
          order={order}
          isScheduled={isScheduled}
        />
      )}
      {shouldDsplayPrintButton && (
        <ActionButtonBase
          iconName={"printer"}
          iconColor={iconColor}
          labelKey={"common.actionButton.finishAndPrintAll"}
          onPress={onPressFinishAndPrint}
          order={order}
          isScheduled={isScheduled}
        />
      )}
    </View>
  );
};

export { ActionButtonOrder, ActionButtonItem };
