import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import useHarmonicIntervalFn from "react-use/lib/useHarmonicIntervalFn";

export function useCountDownFromNowLabel(
  timeUntil?: string,
  threshold = 20,
  isEnabled = true
) {
  const getBlinking = () => {
    if (!isEnabled || !threshold) return false;

    const duration = DateTime.fromISO(timeUntil).diffNow();
    const minutes = Math.floor(duration.minutes);

    return minutes < threshold;
  };

  const getLabel = () => {
    if (!timeUntil) return "";

    const duration = DateTime.fromISO(timeUntil)
      .diffNow()
      .shiftTo("minutes", "seconds", "milliseconds");

    const minutes = duration.minutes;
    const roundedMinutes = Math.floor(Math.abs(minutes));

    return `${Math.sign(minutes) === -1 ? "-" : ""}${roundedMinutes
      .toString()
      .padStart(2, "0")}:${Math.abs(duration.seconds)
      .toString()
      .padStart(2, "0")}`;
  };

  const [label, setLabel] = useState(() => getLabel());
  const [isBlinking, setBlinking] = useState(() => getBlinking());

  useHarmonicIntervalFn(() => {
    setLabel(getLabel());

    setBlinking(getBlinking());
  }, 1000);

  useEffect(() => {
    setLabel(getLabel());
  }, [timeUntil]);

  useEffect(() => {
    setBlinking(getBlinking());
  }, [threshold, isEnabled]);

  return { label, isBlinking };
}
