import { createAction } from "@reduxjs/toolkit";
import { DefaultFailActionPayload } from "store/types";
import { Account } from "store/User/types";

const fetch = createAction("USER/FETCH/REQUEST");
const update = createAction("USER/UPDATE/REQUEST");

const fetchAccount = createAction("USER/FETCH_ACCOUNT/REQUEST");
const fetchAccountSuccess = createAction<Account>("USER/FETCH_ACCOUNT/SUCCESS");
const fetchAccountFailure = createAction<DefaultFailActionPayload>(
  "USER/FETCH_ACCOUNT/FAILURE"
);

const updateEstimatedPreparationTime = createAction<
  Account["estimated_preparation_time"]
>("USER/UPDATE_EPT/REQUEST");
const updateEstimatedPreparationTimeSuccess = createAction(
  "USER/UPDATE_EPT/SUCCESS"
);
const updateEstimatedPreparationTimeFailure =
  createAction<DefaultFailActionPayload>("USER/UPDATE_EPT/FAILURE");

const updateAccount = createAction<any>("USER/UPDATE_ACCOUNT");
const selectAccount = createAction<Account | undefined>("USER/SELECT_ACCOUNT");

const setSelectedAccountReady = createAction<boolean>(
  "USER/SET_SELECTED_ACCOUNT_READY"
);

const UserActions = {
  fetch,
  update,
  fetchAccount,
  fetchAccountSuccess,
  fetchAccountFailure,

  updateEstimatedPreparationTime,
  updateEstimatedPreparationTimeSuccess,
  updateEstimatedPreparationTimeFailure,

  updateAccount,
  selectAccount,
  setSelectedAccountReady,
};

export { UserActions };
