import { FlashList } from "@shopify/flash-list";
import colors from "config/colors";
import { isEmpty } from "lodash";
import { ItemSectionHeader } from "modules/ChefScene/components";
import { EmptyList } from "modules/ChefScene/components/EmptyList";
import { OrderCell } from "modules/OverviewScene/components";
import { memo, useCallback, useContext, useMemo } from "react";
import type { ComponentProps } from "react";
import { useTranslation } from "react-i18next";
import { SectionList, StyleSheet, View } from "react-native";
import { useSelector } from "react-redux";
import { AppActions, AppSelectors } from "store/App";
import { TabBarKey } from "store/App/constants";
import { useAppDispatch } from "store/hooks";
import { OrderSelectors } from "store/Orders";
import { Order } from "store/Orders/types";
import { useFormatDateTime } from "util/helpers";
import { usePullToRefreshOrder } from "util/hooks";

type NavItemProps = {
  order: Order;
  isScheduled?: boolean;
  tab: TabBarKey;
};

export const NavItem = memo((props: NavItemProps) => {
  const { order, isScheduled, tab } = props;

  const dispatch = useAppDispatch();
  const selectOrderUUID = useSelector(AppSelectors.selectOrderUUID);

  const handlePress: ComponentProps<typeof OrderCell>["onPress"] = useCallback(
    (id) => {
      dispatch(AppActions.setSelectedOrderUUID({ orderUUID: id }));
    },
    []
  );

  return (
    <OrderCell
      tab={tab}
      order={order}
      isSelected={selectOrderUUID === order.uuid}
      isScheduled={isScheduled}
      onPress={handlePress}
    />
  );
});

NavItem.displayName = "NavItem";

const Scheduled = memo(() => {
  const { t } = useTranslation();

  const [today, rest] = useSelector(OrderSelectors.selectScheduledGrouped);
  const [isFetching, onRefresh] = usePullToRefreshOrder();

  const formatDateTime = useFormatDateTime();

  const sections = useMemo(() => {
    return [
      ...(!isEmpty(today)
        ? [{ data: today, date: new Date(), isToday: true }]
        : []),
      ...rest.map((group) => ({
        data: group.items,
        date: group.date,
        isToday: false,
      })),
    ];
  }, [rest]);

  return (
    <View style={styles.container}>
      {!isEmpty(sections) ? (
        <SectionList
          sections={sections}
          renderItem={({ item }) => (
            <NavItem order={item} tab={"scheduled"} isScheduled={true} />
          )}
          renderSectionHeader={({ section }) => {
            return (
              <ItemSectionHeader
                label={
                  section.isToday
                    ? t("common.today")
                    : formatDateTime(section.date, {
                        weekday: "long",
                        day: "numeric",
                        month: "long",
                      })
                }
                color={colors.ui.statuses.scheduled}
              />
            );
          }}
          onRefresh={onRefresh}
          refreshing={isFetching}
          style={styles.sectionList}
        />
      ) : (
        <EmptyList onRefresh={onRefresh} isFetching={isFetching} />
      )}
    </View>
  );
});

Scheduled.displayName = "Scheduled";

const Prepared = memo(() => {
  const { t } = useTranslation();

  const preparedOrders = useSelector<unknown, Order[]>(
    OrderSelectors.selectPrepared
  );
  const [isFetching, onRefresh] = usePullToRefreshOrder();

  return (
    <View style={styles.container}>
      <View style={styles.flastListContainer}>
        <ItemSectionHeader
          label={t("overview.drawer.preparedOrders")}
          color={colors.ui.statuses.prepared}
        />
        {!isEmpty(preparedOrders) ? (
          <FlashList
            estimatedItemSize={100}
            data={preparedOrders}
            onRefresh={onRefresh}
            refreshing={isFetching}
            renderItem={({ item }) => <NavItem tab={"prepared"} order={item} />}
            keyExtractor={(item) => item.uuid}
          />
        ) : (
          <EmptyList onRefresh={onRefresh} isFetching={isFetching} />
        )}
      </View>
    </View>
  );
});
Prepared.displayName = "Prepared";

const New = memo(() => {
  const { t } = useTranslation();

  const newOrders = useSelector<unknown, Order[]>(
    OrderSelectors.selectNotBeingPrepared
  );
  const [isFetching, onRefresh] = usePullToRefreshOrder();

  return (
    <View style={styles.container}>
      <View style={styles.flastListContainer}>
        <ItemSectionHeader
          label={t("overview.drawer.newOrders")}
          color={colors.ui.statuses.active}
        />
        {!isEmpty(newOrders) ? (
          <FlashList
            estimatedItemSize={100}
            data={newOrders}
            onRefresh={onRefresh}
            refreshing={isFetching}
            renderItem={({ item }) => <NavItem order={item} tab={"new"} />}
            keyExtractor={(item) => item.uuid}
          />
        ) : (
          <EmptyList onRefresh={onRefresh} isFetching={isFetching} />
        )}
      </View>
    </View>
  );
});
New.displayName = "New";

const Cooking = memo(() => {
  const { t } = useTranslation();

  const cookingOrders = useSelector<unknown, Order[]>(
    OrderSelectors.selectBeingPrepared
  );
  const [isFetching, onRefresh] = usePullToRefreshOrder();

  return (
    <View style={styles.container}>
      <View style={styles.flastListContainer}>
        <ItemSectionHeader
          label={t("overview.drawer.cookingOrders")}
          color={colors.ui.statuses.cooking}
        />
        {!isEmpty(cookingOrders) ? (
          <FlashList
            estimatedItemSize={100}
            data={cookingOrders}
            onRefresh={onRefresh}
            refreshing={isFetching}
            renderItem={({ item }) => <NavItem order={item} tab={"cooking"} />}
            keyExtractor={(item) => item.uuid}
          />
        ) : (
          <EmptyList onRefresh={onRefresh} isFetching={isFetching} />
        )}
      </View>
    </View>
  );
});

Cooking.displayName = "Cooking";

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    flexShrink: 1,
    marginHorizontal: 16,
    marginTop: 12,
    marginBottom: 12,
    flexDirection: "row",
  },
  flastListContainer: {
    flex: 1,
  },
  sectionList: {
    flex: 1,
    paddingVertical: 4,
  },
});

export { Scheduled, Prepared, New, Cooking };
