import { AnalyticsSagas } from "store/Analytics";
import { AppSagas } from "store/App";
import { NotificationsSaga } from "store/Notifications";
import { OrdersSaga } from "store/Orders";
import { SettingsSaga } from "store/Settings";
import { UserSaga } from "store/User";
import { ErrorSaga } from "./util/sagas";

export function runMiddlewares(store, sagaMiddleware) {
  sagaMiddleware.run(ErrorSaga);
  sagaMiddleware.run(UserSaga);
  sagaMiddleware.run(SettingsSaga);
  sagaMiddleware.run(OrdersSaga);
  sagaMiddleware.run(NotificationsSaga);
  sagaMiddleware.run(AnalyticsSagas);
  sagaMiddleware.run(AppSagas);
}
