import { MaterialCommunityIcons } from "@expo/vector-icons";
import { Text, WebImage } from "components";
import colors from "config/colors";
import { DEFAULT_ACTIVE_OPACITY } from "config/constants";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import type { Account } from "store/User/types";

type Props = Account & {
  onPress: (accountUUID: Account["uuid"]) => void;
  isSelected?: boolean;
};

export function Store({ onPress, isSelected, ...account }: Props) {
  const { logo_url, name, address } = account;

  return (
    <TouchableOpacity
      activeOpacity={DEFAULT_ACTIVE_OPACITY}
      style={[
        styles.container,
        { backgroundColor: isSelected ? `${colors.green}77` : "white" },
      ]}
      onPress={() => onPress(account.uuid)}
    >
      {logo_url ? (
        <WebImage
          uri={logo_url}
          width={50}
          height={50}
          style={styles.image}
          shape="rect"
          borderRadius={4}
        />
      ) : (
        <View style={styles.imagePlaceholder}>
          <MaterialCommunityIcons name="store" size={32} color="white" />
        </View>
      )}
      <View style={styles.textContainer}>
        <Text font="bold" size={18}>
          {name}
        </Text>
        <Text>{address.line_1}</Text>
      </View>
      {isSelected && (
        <MaterialCommunityIcons name="check" size={36} color={colors.green} />
      )}
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    padding: 8,
    alignItems: "center",
    borderRadius: 4,
    marginVertical: 4,
  },
  textContainer: { marginLeft: 8, flexGrow: 1 },
  image: { borderWidth: 1, borderColor: "white" },
  imagePlaceholder: {
    width: 50,
    height: 50,
    borderWidth: 1,
    borderColor: "white",
    backgroundColor: `${colors.primary}AA`,
    borderRadius: 4,
    alignItems: "center",
    justifyContent: "center",
  },
});
