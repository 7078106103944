import i18n from "i18next";
import { StyleSheet, Text, View } from "react-native";
import {
  cssTransition,
  toast as toastifyToast,
  ToastContainer as ToastifyToastContainer,
} from "react-toastify";
import { styles } from "./styles";
import type { ToastOptions } from "./types";

const slideFade = cssTransition({
  enter: "animate__animated animate__faster animate__fadeInDown",
  exit: "animate__animated animate__faster animate__fadeOut",
});

type ToastProps = Pick<
  ToastOptions,
  "title" | "textBody" | "titleStyle" | "textBodyStyle" | "withCloseButton"
>;

const Toast = ({ title, textBody, titleStyle, textBodyStyle }: ToastProps) => {
  return (
    <View style={styles.labelContainer}>
      {!!title && (
        <Text style={StyleSheet.flatten([styles.titleLabel, titleStyle])}>
          {title}
        </Text>
      )}
      {!!textBody && (
        <Text style={StyleSheet.flatten([styles.descLabel, textBodyStyle])}>
          {textBody}
        </Text>
      )}
    </View>
  );
};

export const toast = ({
  title,
  titleKey,
  textBody,
  textBodyKey,
  titleStyle,
  textBodyStyle,
  type = "success",
  onHide,
  onShow,
  onPress,
  ...options
}: ToastOptions) => {
  toastifyToast(
    <Toast
      title={titleKey ? i18n.t(titleKey) : title}
      textBody={textBodyKey ? i18n.t(textBodyKey) : textBody}
      titleStyle={titleStyle}
      textBodyStyle={textBodyStyle}
    />,
    {
      onClose: onHide,
      onOpen: onShow,
      onClick: onPress,
      ...options,
      type,
      position: "top-center",
    }
  );
};

export const ToastContainer = ({ children }: React.PropsWithChildren) => {
  return (
    <>
      {children}
      <ToastifyToastContainer
        hideProgressBar
        pauseOnHover
        pauseOnFocusLoss={false}
        transition={slideFade}
        theme="light"
        stacked
      />
    </>
  );
};
