import { Text } from "components";

const ItemSectionHeader = (props) => {
  const { label, color } = props;

  return (
    <Text
      font="bold"
      color={color}
      size={16}
      style={{ margin: 4, marginTop: 16 }}
    >
      {label}
    </Text>
  );
};

export default ItemSectionHeader;
