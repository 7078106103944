import { Button, Text } from "components";
import colors from "config/colors";
import { DateTime } from "luxon";
import { useState } from "react";
import { TouchableOpacity, View } from "react-native";
import { useAppDispatch } from "store/hooks";
import { NotificationThunks } from "store/Notifications";
import type { AppNotification } from "store/Notifications/types";
import { useFormatDateTime } from "util/helpers";

interface Props {
  notificationUUID: AppNotification["uuid"];
  acceptedAt: AppNotification["accepted_at"];
  onPress?: () => void;
}

const AcceptButton = (props: Props) => {
  const { notificationUUID, acceptedAt, onPress } = props;
  const dispatch = useAppDispatch();
  const [isFetching, setIsFetching] = useState(false);

  const formatDateTime = useFormatDateTime();

  const onAccept = async () => {
    setIsFetching(true);
    await dispatch(
      NotificationThunks.markAsReadAndAccepted({ uuids: [notificationUUID] })
    );
    setIsFetching(false);

    onPress();
  };

  return acceptedAt ? (
    <View style={{ marginLeft: "auto" }}>
      <Text
        color={"#9b9b9b"}
        font={"medium"}
        id={"notifications.accepted"}
        size={12}
      />
      <Text color={"#9b9b9b"} font={"medium"} size={12}>
        {formatDateTime(acceptedAt, DateTime.DATETIME_MED)}
      </Text>
    </View>
  ) : (
    <View pointerEvents={"box-none"}>
      <TouchableOpacity
        activeOpacity={1}
        onPress={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <Button
          titleId={"notifications.accept"}
          iconName={"check-bold"}
          style={{ backgroundColor: colors.brightGreen, minHeight: 24 }}
          font={"medium"}
          onPress={onAccept}
          isFetching={isFetching}
          size={12}
        />
      </TouchableOpacity>
    </View>
  );
};

export default AcceptButton;
