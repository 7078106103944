import { MaterialCommunityIcons } from "@expo/vector-icons";
import {
  CancelledOrderBadge,
  DeliveryStatusBadge,
  NonCookableBadge,
  OrderStartPreparationStatusBadge,
  Text,
} from "components";
import colors from "config/colors";
import { DateTime } from "luxon";
import { RemakeOrderDescription } from "modules/OverviewScene/components";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";
import { useSelector } from "react-redux";
import { ItemPreparationStatuses, OrderStatuses } from "store/Orders/enums";
import { Order, OrderItem } from "store/Orders/types";
import { UserSelectors } from "store/User";
import { getLabelKeyForOrderKind, useFormatDateTime } from "util/helpers";

type ScheduledForBadgeProps = {
  scheduledFor?: string;
};

const ScheduledForBadge = ({ scheduledFor }: ScheduledForBadgeProps) => {
  const formatDateTime = useFormatDateTime();

  if (!scheduledFor) return null;

  return (
    <View style={styles.scheduledFor}>
      <MaterialCommunityIcons
        name={"calendar-clock"}
        color="#FFF"
        size={20}
        style={{ marginRight: 5 }}
      />
      <Text font="medium" size={14} color="#FFF">
        {formatDateTime(scheduledFor, {
          day: "2-digit",
          month: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
        })}
      </Text>
    </View>
  );
};

type StatusProps = {
  order: Order;
  item: OrderItem;
  isScheduled?: boolean;
};

const Status = ({ order, item, isScheduled }: StatusProps) => {
  const { t } = useTranslation();

  const featureFlags = useSelector(UserSelectors.selectFeatureFlags);

  const formatDateTime = useFormatDateTime();

  return (
    <View style={styles.container}>
      <Text color={"#bebebe"} size={12} font="medium" align="center">
        {formatDateTime(order.created_at, DateTime.TIME_SIMPLE)} |{" "}
        {t(getLabelKeyForOrderKind(order.kind))}
      </Text>
      <Text
        style={{ marginTop: 4 }}
        color={colors.text.dark}
        font="bold"
        size={16}
        align="center"
      >
        #{order.description}
      </Text>
      {!!order.remake_for_order_description && (
        <RemakeOrderDescription
          prefix=""
          description={order.remake_for_order_description}
        />
      )}
      {isScheduled && <ScheduledForBadge scheduledFor={order.scheduled_for} />}
      {featureFlags.kds_enabled_traffic_light_system &&
        item.status === ItemPreparationStatuses.NOT_BEING_PREPARED && (
          <View style={{ marginTop: 4 }}>
            <OrderStartPreparationStatusBadge
              status={order.start_preparation_status}
            />
          </View>
        )}
      {!item.cookable && <NonCookableBadge />}
      {order.order_status === OrderStatuses.CANCELLED ? (
        <View style={{ marginTop: 4 }}>
          <CancelledOrderBadge />
        </View>
      ) : order.job &&
        [
          ItemPreparationStatuses.PREPARED,
          ItemPreparationStatuses.COMPLETED,
        ].includes(item.status) ? (
        <View style={{ marginTop: 4 }}>
          <DeliveryStatusBadge
            status={order.job.delivery_status}
            geoVerified={order.job.geo_verified}
          />
        </View>
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginVertical: 8,
    paddingHorizontal: 8,
    width: 110,
    borderRightColor: "#d3d3d3",
    borderRightWidth: StyleSheet.hairlineWidth,
  },
  scheduledFor: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 3,
    paddingHorizontal: 5,
    borderRadius: 3,
    marginTop: 4,
    backgroundColor: colors.ui.statuses.scheduled,
  },
  readyBadge: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: "auto",
    alignSelf: "center",
  },
  readyBadgeIcon: {
    backgroundColor: "#94dc72",
    width: 15,
    height: 15,
    borderRadius: 90,
    marginRight: 5,
  },
});

export default Status;
