import { Button } from "components/Button";
import { Modal, ModalProps } from "components/Modal";
import Text from "components/Text";
import { StyleSheet, View } from "react-native";

interface Props extends Omit<ModalProps, "children"> {
  title: string;
  message: string;
  onConfirm: () => void;
}

const Alert = (props: Props) => {
  const { visible, onRequestClose, onConfirm, title, message } = props;

  return (
    <Modal
      visible={visible}
      onRequestClose={onRequestClose}
      contentContainerStyle={{
        marginHorizontal: "30%",
        marginVertical: "30%",
      }}
    >
      <View style={styles.container}>
        <View style={styles.headerContainer}>
          <Text color={"white"} style={{ flex: 1 }} size={22} font={"bold"}>
            {title}
          </Text>
        </View>
        <View style={styles.contentContainer}>
          <Text size={18}>{message}</Text>
          <Button
            titleId={"components.alerts.dialogOk"}
            onPress={onConfirm}
            style={{ marginHorizontal: 50 }}
          />
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  backdrop: {
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
  },
  container: {
    minHeight: 200,
    borderRadius: 5,
  },
  headerContainer: {
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    padding: 5,
    width: "100%",
    flexDirection: "row",
    backgroundColor: "orange",
  },
  contentContainer: {
    padding: 10,
    flex: 1,
    justifyContent: "space-between",
  },
});

export default Alert;
