import { executeRequest } from "API/util/functions";

const fetch = () =>
  executeRequest(
    (api) => api.get("/v1/categories"),
    (data) => ({ items: data })
  );

export default {
  fetch,
};
