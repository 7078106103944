import { Button, ButtonProps } from "components/Button";
import colors from "config/colors";
import { StyleSheet } from "react-native";

export const ModalCloseButton = (props: ButtonProps) => {
  return (
    <Button
      innerColor={colors.gray}
      color="transparent"
      titleId={"common.close"}
      style={styles.button}
      textStyle={styles.buttonLabel}
      variant="outline"
      {...props}
    />
  );
};

const styles = StyleSheet.create({
  button: {
    marginTop: 24,
    alignSelf: "center",
  },
  buttonLabel: {
    // textTransform: 'uppercase',
    marginLeft: 0,
    paddingHorizontal: 10,
  },
});
