import { MaterialCommunityIcons } from "@expo/vector-icons";
import { useLinkProps } from "@react-navigation/native";
import colors from "config/colors";
import { DEFAULT_ACTIVE_OPACITY } from "config/constants";
import {
  StyleProp,
  StyleSheet,
  TextStyle,
  TouchableOpacity,
  TouchableOpacityProps,
} from "react-native";
import { MaterialCommunityIconName } from "types/icons";
import Text from "../Text";

type Props = TouchableOpacityProps & {
  to: string;
  iconName?: MaterialCommunityIconName;
  params?: any;
  textStyle?: StyleProp<TextStyle>;
};

export const Link = ({
  to,
  iconName,
  params,
  children,
  textStyle,
  onPress,
  ...props
}: Props) => {
  const navigationProps = useLinkProps({
    to: {
      screen: to,
      params,
    },
  });

  return (
    <TouchableOpacity
      activeOpacity={DEFAULT_ACTIVE_OPACITY}
      hitSlop={8}
      {...(onPress ? { onPress } : navigationProps)}
      {...props}
    >
      <Text font="medium" color={colors.main} style={[styles.text, textStyle]}>
        {Boolean(iconName) && (
          <MaterialCommunityIcons
            name={iconName}
            style={styles.icon}
            size={16}
          />
        )}
        {children}
      </Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  text: {
    alignItems: "center",
  },
  icon: {
    marginRight: 4,
  },
});
