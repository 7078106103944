import { HorizontalDivider, Text, withErrorBoundary } from "components";
import { xor } from "lodash";
import { RouteNames } from "navigation/linkingConfig";
import { Fragment, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";
import { useSelector } from "react-redux";
import { DeviceSelectors } from "store/Device";
import { useAppDispatch } from "store/hooks";
import { OrderSelectors } from "store/Orders";
import { OrderKinds } from "store/Orders/enums";
import { SettingActions, SettingSelectors } from "store/Settings";
import type { OrderKind } from "store/Settings/types";
import { AnalyticsEvents, useLogEventCallback } from "util/analytics";
import { getIconNameForOrderKind, getLabelKeyForOrderKind } from "util/helpers";
import { CheckBox, CheckBoxSection } from "./components/CheckBox";
import Setting from "./components/Setting";
import { SettingError } from "./components/SettingError";

function NoOrderKindsPlaceholder() {
  return (
    <View>
      <Text id={"settings.orderKinds.noContent.title"} size={16} />
    </View>
  );
}

interface ScreenOrderKindsProps {
  orderKinds: OrderKind[];
}

function ScreenOrderKinds(props: ScreenOrderKindsProps) {
  const { orderKinds } = props;
  const awaitingItemsByOrderKind = useSelector(
    OrderSelectors.selectItemsCountByOrderKind
  );
  const screen = useSelector(DeviceSelectors.selectScreen);
  const { t } = useTranslation();

  return (
    <CheckBoxSection
      title={t("settings.orderKinds.screen.title", {
        screenName: screen.name,
        amount: orderKinds.length,
      })}
      subtitleKey={"settings.orderKinds.screen.subtitle"}
    >
      {orderKinds.length > 0 ? (
        orderKinds.map((orderKind: string, index: number) => {
          const isLast = index === orderKinds.length - 1;

          return (
            <Fragment key={orderKind}>
              <CheckBox
                iconName={getIconNameForOrderKind(orderKind)}
                labelKey={getLabelKeyForOrderKind(orderKind)}
                isSelected={true}
                isDisabled={true}
                awaitingItems={awaitingItemsByOrderKind[orderKind]}
              />
              {!isLast && <HorizontalDivider style={styles.divider} />}
            </Fragment>
          );
        })
      ) : (
        <NoOrderKindsPlaceholder />
      )}
    </CheckBoxSection>
  );
}

interface OtherOrderKindsProps {
  orderKinds: OrderKind[];
  hasScreen: boolean;
}

function OtherOrderKinds(props: OtherOrderKindsProps) {
  const { orderKinds, hasScreen } = props;

  const dispatch = useAppDispatch();
  const logEvent = useLogEventCallback();

  const selectedOrderKinds = useSelector(
    SettingSelectors.selectUserSelectedOrderKinds
  );
  const screenOrderKinds = useSelector(DeviceSelectors.selectScreenOrderKinds);
  const isSelectAllEnabled = useSelector(
    SettingSelectors.selectIsSelectedAllOrderKinds
  );
  const awaitingItemsByOrderKind = useSelector(
    OrderSelectors.selectItemsCountByOrderKind
  );
  const { t } = useTranslation();

  const onToggle = (orderKind: OrderKind) => {
    logEvent(AnalyticsEvents.orderKindsFilterUpdated({ orderKind }));

    if (isSelectAllEnabled) {
      dispatch(SettingActions.toggleAllOrderKinds(false));
      dispatch(
        SettingActions.setSelectedOrderKinds(xor(orderKinds, [orderKind]))
      );
    } else {
      const newSelection = xor(selectedOrderKinds, [orderKind]);
      const isAllSelected = orderKinds.every((item) =>
        newSelection.includes(item)
      );
      if (isAllSelected) {
        dispatch(SettingActions.toggleAllOrderKinds(true));
        dispatch(SettingActions.setSelectedOrderKinds([]));
      } else {
        dispatch(SettingActions.setSelectedOrderKinds(newSelection));
      }
    }
  };

  const onToggleAll = () => {
    const newValue = !isSelectAllEnabled;

    dispatch(SettingActions.toggleAllOrderKinds(newValue));

    logEvent(
      AnalyticsEvents.orderKindsFilterUpdated({
        orderKind: newValue ? "all" : "none",
      })
    );
  };

  return (
    <CheckBoxSection
      title={t(
        hasScreen
          ? "settings.orderKinds.other.title"
          : "settings.orderKinds.other.titleNoScreen",
        {
          amount: orderKinds.length,
        }
      )}
      subtitleKey={
        hasScreen
          ? "settings.orderKinds.other.subtitle"
          : "settings.orderKinds.other.subtitleNoScreen"
      }
    >
      {orderKinds.length > 0 ? (
        <>
          <CheckBox
            isSelected={isSelectAllEnabled}
            onPress={onToggleAll}
            iconName="view-list"
            labelKey="common.all"
          />
          <HorizontalDivider style={styles.divider} />
          {orderKinds.map((orderKind, index) => {
            const isLast = index === orderKinds.length - 1;

            return (
              <Fragment key={orderKind}>
                <CheckBox
                  iconName={getIconNameForOrderKind(orderKind)}
                  labelKey={getLabelKeyForOrderKind(orderKind)}
                  isSelected={
                    isSelectAllEnabled ||
                    selectedOrderKinds.includes(orderKind as OrderKinds)
                  }
                  isDisabled={screenOrderKinds.includes(orderKind)}
                  awaitingItems={awaitingItemsByOrderKind[orderKind]}
                  onPress={() => onToggle(orderKind)}
                />
                {!isLast && <HorizontalDivider style={styles.divider} />}
              </Fragment>
            );
          })}
        </>
      ) : (
        <NoOrderKindsPlaceholder />
      )}
    </CheckBoxSection>
  );
}

const OrderKindsSetting = () => {
  const screenOrderKinds = useSelector(DeviceSelectors.selectScreenOrderKinds);
  const orderKinds = useSelector(SettingSelectors.selectAllOrderKinds);
  const screen = useSelector(DeviceSelectors.selectScreen);

  const otherOrderKinds = useMemo(() => {
    return xor(orderKinds, screenOrderKinds);
  }, [screenOrderKinds, orderKinds]);

  const hasScreen = Boolean(screen);

  return (
    <Setting
      titleKey={"settings.orderKinds.title"}
      subTitleKey={"settings.orderKinds.subtitle"}
      iconName="silverware-variant"
    >
      <View style={{ flexDirection: "row" }}>
        {hasScreen && <ScreenOrderKinds orderKinds={screenOrderKinds} />}
        <OtherOrderKinds orderKinds={otherOrderKinds} hasScreen={hasScreen} />
      </View>
    </Setting>
  );
};

const styles = StyleSheet.create({
  divider: {
    marginVertical: 16,
  },
});

export default withErrorBoundary(OrderKindsSetting, {
  screen: RouteNames.Home.Settings.Categories,
  key: "settings-categories",
  fallback: <SettingError />,
});
