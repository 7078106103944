import { createSlice } from "@reduxjs/toolkit";
import { AuthActions } from "store/Auth";
import { notificationsAdapter } from "store/Notifications/entities";
import Actions from "./actions";
import NotificationThunks from "./thunks";
import { AppNotification } from "./types";

const notificationSlice = createSlice({
  name: "notifications",
  initialState: notificationsAdapter.getInitialState(),
  reducers: {
    addNotifications: (state, action) => {
      notificationsAdapter.upsertMany(state, action.payload);
    },
    updateNotifications: (state, action) => {
      notificationsAdapter.updateMany(state, action.payload);
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(AuthActions.signOut, notificationsAdapter.removeAll)
      .addCase(Actions.fetchSuccess, (state, action) => {
        const data = action.payload.map((notification) => {
          const { read_at, accepted_at, ...changes } = notification;
          return {
            ...changes,
            ...(read_at ? { read_at } : {}),
            ...(accepted_at ? { accepted_at } : {}),
          };
        });

        const missingUuids = Object.values(state.entities)
          .filter(
            (notification) =>
              !data.find(
                (newNotification) => newNotification.uuid === notification.uuid
              )
          )
          .map((notification) => notification.uuid);

        notificationsAdapter.removeMany(state, missingUuids);
        notificationsAdapter.upsertMany(state, data as AppNotification[]);
      })
      .addCase(Actions.markAsReadSuccess, (state, action) => {
        const { uuids } = action.payload;

        const nowDateString = new Date().toISOString();

        notificationsAdapter.updateMany(
          state,
          uuids.map((uuid) => {
            return {
              id: uuid,
              changes: { read_at: nowDateString },
            };
          })
        );
      })
      .addCase(
        NotificationThunks.markAsReadAndAccepted.fulfilled,
        (state, action) => {
          const { uuids } = action.meta.arg;

          const nowDateString = new Date().toISOString();

          notificationsAdapter.updateMany(
            state,
            uuids.map((uuid) => {
              return {
                id: uuid,
                changes: { accepted_at: nowDateString, read_at: nowDateString },
              };
            })
          );
        }
      ),
});

export default notificationSlice.reducer;

const sliceActions = notificationSlice.actions;

export { sliceActions };
