import Clipboard from "@react-native-clipboard/clipboard";
import { useCallback, useEffect, useState } from "react";

export interface UseClipboardOptions {
  timeout?: number;
}

export function useCopyToClipboard(initialValue: string, timeout = 5000) {
  const [hasCopied, setHasCopied] = useState(false);

  const [value, setValue] = useState(initialValue);

  const onCopy = useCallback(() => {
    Clipboard.setString(value);

    setHasCopied(true);
  }, [value]);

  useEffect(() => {
    let timeoutId: number | null = null;

    if (hasCopied) {
      timeoutId = window.setTimeout(() => {
        setHasCopied(false);
      }, timeout);
    }

    return () => {
      if (timeoutId) {
        window.clearTimeout(timeoutId);
      }
    };
  }, [timeout, hasCopied]);

  return { value, setValue, setHasCopied, onCopy, hasCopied };
}
