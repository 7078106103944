import { MaterialCommunityIcons } from "@expo/vector-icons";
import { Text } from "components";
import colors from "config/colors";
import { View } from "react-native";
import { MaterialCommunityIconName } from "types/icons";

type Props = {
  iconName?: MaterialCommunityIconName;
  titleKey?: string;
};

export function NoNotifications({
  titleKey = "notifications.noNotifications",
  iconName = "bell-check",
}: Props) {
  return (
    <View
      style={{
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      <MaterialCommunityIcons
        name={iconName}
        size={32}
        style={{ marginRight: 15 }}
        color={colors.brightGreen}
      />
      <Text size={20} id={titleKey} font={"medium"} />
    </View>
  );
}
