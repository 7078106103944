import { locale as defaultLocale } from "expo-localization";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { UserSelectors } from "store/User";

const getLocaleNumberString = (
  value: number | string,
  options: Intl.NumberFormatOptions = {},
  locale = defaultLocale
): string => {
  let _value = value;

  if (typeof _value === "string") {
    _value = parseFloat(_value);
  }

  return _value.toLocaleString(locale, {
    ...options,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const getCurrencyString = (
  value: number | string,
  currency?: string,
  locale = defaultLocale
) => {
  if (!value) return "-";

  if (currency) {
    return getLocaleNumberString(
      value,
      {
        currencyDisplay: "narrowSymbol",
        style: "currency",
        currency,
      },
      locale
    ).replace(/^[a-z]+(?!(\s|\w))/gi, "");
  }

  return getLocaleNumberString(value, undefined, locale);
};

export const useCurrencyString = () => {
  const currency = useSelector(UserSelectors.selectCurrencyCode);
  const {
    i18n: { resolvedLanguage },
  } = useTranslation();

  return useCallback(
    (value: number | string) => {
      return getCurrencyString(value, currency, resolvedLanguage);
    },
    [currency, resolvedLanguage]
  );
};
