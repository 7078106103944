import { createEntityAdapter } from "@reduxjs/toolkit";
import { Order, OrderItem } from "./types";

export const ordersAdapter = createEntityAdapter<Order>({
  selectId: (order) => order.uuid,
});

export const orderItemsAdapter = createEntityAdapter<OrderItem>({
  selectId: (item) => item.uuid,
});
