import { MaterialCommunityIcons } from "@expo/vector-icons";
import Colors from "config/colors";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import Text from "../Text";

type Props = {
  id: string;
  children?: React.ReactNode;
  style?: StyleProp<ViewStyle>;
};

const ErrorText = ({ id, children, style }: Props) => {
  return (
    <View style={[style, { flexGrow: 1 }]}>
      <View style={styles.content}>
        <View style={styles.header}>
          <MaterialCommunityIcons
            name={"robot-dead"}
            size={28}
            color={Colors.error}
          />
          <Text
            id={id}
            style={styles.headerLabel}
            color={Colors.error}
            size={16}
          />
        </View>
        {children && <View style={styles.child}>{children}</View>}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  content: {
    flexGrow: 1,
    backgroundColor: "white",
    padding: 16,
    justifyContent: "center",
    alignItems: "center",
  },
  header: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  headerLabel: {
    marginLeft: 8,
  },
  child: {
    marginTop: 8,
  },
});

export default ErrorText;
