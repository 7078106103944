import { createSelector } from "@reduxjs/toolkit";
import type { AppState } from "./reducer";

const select = (state) => state.app as AppState;
const selectEnvironment = createSelector(select, (app) => app.environment);
const selectLocale = createSelector(select, (app) => app.locale);
const selectUpdatedAt = createSelector(select, (slice) => slice.updatedAt);
const selectCurrentTabBar = createSelector(
  select,
  (slice) => slice.selectedTabBar
);
const selectIsSidebarMinimized = createSelector(
  select,
  (slice) => slice.isSidebarMinimized
);
const selectErrors = createSelector(select, (slice) => slice.errors);
const selectOrderUUID = createSelector(
  select,
  (slice) => slice.selectedOrderUUID
);

export default {
  selectUpdatedAt,
  selectCurrentTabBar,
  selectEnvironment,
  selectIsSidebarMinimized,
  selectLocale,
  selectErrors,
  selectOrderUUID,
};
