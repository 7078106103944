import { Button } from "components";
import colors from "config/colors";
import { isEmpty } from "lodash";
import { AuthBackground } from "modules/Auth/components/AuthBackground";
import {
  EnvironmentServer,
  SelectEnvironmentModal,
} from "modules/Auth/components/SelectEnvironmentModal";
import { ReactNode, useEffect, useState } from "react";
import { ActivityIndicator, StyleSheet, View } from "react-native";
import { useSelector } from "react-redux";
import { AppActions, AppSelectors } from "store/App";
import { AuthActions, AuthThunks } from "store/Auth";
import { useAppDispatch } from "store/hooks";
import { UserSelectors } from "store/User";
import { Content } from "./Content";

type Props = {
  children?: ReactNode;
};

export function EnvironmentWatcher({ children }: Props) {
  const dispatch = useAppDispatch();
  const [environments, setEnvironments] = useState<EnvironmentServer[]>([]);
  const [isUnauthorized, setUnauthorized] = useState(false);
  const [isError, setError] = useState(false);
  const [retryCount, setRetryCount] = useState(0);

  const token = useSelector(UserSelectors.selectToken);
  const accountUUID = useSelector(UserSelectors.selectAccountUUID);
  const selectedEnvironment = useSelector(AppSelectors.selectEnvironment);

  const onSignOut = () => {
    setEnvironments([]);

    dispatch(AuthActions.signOut());
  };

  const onError = () => {
    setError(true);
  };

  const onUnauthorized = () => {
    setUnauthorized(true);
  };

  const onSelectEnvironment = (environment: EnvironmentServer) => {
    setEnvironments([]);

    dispatch(AppActions.setEnvironment(environment));
  };

  useEffect(() => {
    if (token && !selectedEnvironment) {
      setUnauthorized(false);
      setError(false);
      setEnvironments([]);

      const promise = dispatch(
        AuthThunks.fetchEnvironments({
          authentication_token: token,
          account_uuid: accountUUID,
        })
      );

      promise.then(
        ({
          payload,
        }: {
          payload?: {
            found_environments?: EnvironmentServer[];
          };
        }) => {
          if (!payload?.found_environments) {
            onError();
            return;
          }

          const environmentsPayload = payload?.found_environments ?? [];

          if (isEmpty(environmentsPayload)) {
            onUnauthorized();
            return;
          }

          if (environmentsPayload.length === 1) {
            onSelectEnvironment(environmentsPayload[0]);
            return;
          }

          setEnvironments(environmentsPayload);
        }
      );

      return () => {
        promise.abort();
      };
    }
  }, [token, selectedEnvironment, accountUUID, retryCount]);

  return (
    <>
      {token && !selectedEnvironment ? (
        <AuthBackground>
          {isError ? (
            <>
              <View style={styles.container}>
                <Content
                  titleKey="authScreen.selectEnvironment.error.title"
                  descriptionKey="authScreen.selectEnvironment.error.description"
                />
                <Button
                  titleId="common.retry"
                  onPress={() => {
                    setRetryCount((retryCountCurrent) => retryCountCurrent + 1);
                  }}
                  color={colors.primary}
                  style={{
                    marginTop: 12,
                  }}
                  innerStyle={styles.buttonInnerStyle}
                />
              </View>

              <Button
                titleId="authScreen.selectEnvironment.error.button.label"
                onPress={onSignOut}
                innerColor="white"
                color="transparent"
                variant="outline"
                iconName="chevron-left"
                style={{
                  position: "absolute",
                  top: 16,
                  left: 16,
                }}
              />
            </>
          ) : isUnauthorized ? (
            <View style={styles.container}>
              <Content
                titleKey="authScreen.selectEnvironment.unauthorized.title"
                descriptionKey="authScreen.selectEnvironment.unauthorized.description"
              />
              <Button
                titleId="authScreen.selectEnvironment.unauthorized.button.label"
                onPress={onSignOut}
                innerColor={colors.primary}
                color="transparent"
                variant="outline"
                style={{
                  marginTop: 8,
                }}
                innerStyle={styles.buttonInnerStyle}
              />
            </View>
          ) : (
            <View
              style={[
                styles.container,
                {
                  maxWidth: null,
                },
              ]}
            >
              <Content
                titleKey="authScreen.selectEnvironment.loading.title"
                descriptionKey="authScreen.selectEnvironment.loading.description"
              />
              <ActivityIndicator
                size="large"
                animating
                color={colors.primary}
                style={{ marginTop: 8 }}
              />
            </View>
          )}
        </AuthBackground>
      ) : (
        children
      )}
      <SelectEnvironmentModal
        isVisible={!isEmpty(environments) && !selectedEnvironment}
        // onClose={onSignOut}
        onConfirm={onSelectEnvironment}
        data={environments}
      />
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: "white",
    borderRadius: 8,
    padding: 16,
    maxWidth: 400,
    alignSelf: "center",
  },
  buttonInnerStyle: {
    flexShrink: 0,
    flexGrow: 0,
  },
});
