import { store } from "./index";

export type RootState = ReturnType<typeof store.getState>;
export type Dispatch = typeof store.dispatch;
export type StoreType = typeof store;

export enum RequestErrors {
  TIMEOUT = "TIMEOUT",
  BAD_STATUS = "BAD_STATUS",
  NO_CONNECTION = "NO_CONNECTION",
  UNAUTHORIZED = "UNAUTHORIZED",
  NETWORK_ERROR = "NETWORK_ERROR",
  SERVER_ERROR = "SERVER_ERROR",
}

export enum InternalErrors {
  SAGA_ERROR = "SAGA_ERROR",
}

export enum AuthErrors {
  EMAIL_NOT_FOUND = "EMAIL_NOT_FOUND",
  PASSWORD_INVALID = "PASSWORD_INVALID",
  SIGN_IN_INCORRECT_CREDENTIALS = "SIGN_IN_INCORRECT_CREDENTIALS",
  FORBIDDEN = "FORBIDDEN",
}

type ActionError = RequestErrors | InternalErrors | AuthErrors;

export interface DefaultFailActionPayload<T = object> {
  error: ActionError;
  meta?: T;
}
