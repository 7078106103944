import type { ExtendedAxiosRequestConfig } from "API/types";
import { RequestError } from "API/util/ExtendedError";
import axios, { AxiosError } from "axios";
import axiosRetry from "axios-retry";
import Constants from "expo-constants";
import * as Device from "expo-device";
import { Platform } from "react-native";
import AppActions from "store/App/actions";
import storeRegistry from "store/storeRegistry";
import { APP_VARIANT } from "util/helpers";
import { apiInstance } from "./instances";

apiInstance.defaults.headers.common["x-app-variant"] = APP_VARIANT;
apiInstance.defaults.headers.common["x-app-device"] = Device.modelName;
apiInstance.defaults.headers.common["x-app-platform"] = Platform.OS;
apiInstance.defaults.headers.common["x-app-os-version"] = Device.osVersion;
apiInstance.defaults.headers.common["x-app-app-version"] =
  Constants.expoConfig.version;

apiInstance.interceptors.response.use((response) => {
  const store = storeRegistry.getStore();

  store.dispatch(AppActions.setUpdatedAt());

  return response;
});

apiInstance.interceptors.response.use(
  (response) => {
    const store = storeRegistry.getStore();

    if (response.status === 401) {
      store.dispatch(AppActions.setEnvironment(null));
    }

    return response;
  },
  (error) => {
    const store = storeRegistry.getStore();

    if (error.status === 401) {
      store.dispatch(AppActions.setEnvironment(null));
    }

    return Promise.reject(error);
  },
  {
    synchronous: true,
    runWhen: (config: ExtendedAxiosRequestConfig) => {
      return !config.disableUnauthorisedHandle;
    },
  }
);

apiInstance.interceptors.request.use(
  (config) => {
    config.validateStatus = axios.defaults.validateStatus;
    return config;
  },
  null,
  {
    synchronous: true,
    runWhen: (config: ExtendedAxiosRequestConfig) => {
      return Boolean(config.enableErrorHandling);
    },
  }
);

apiInstance.interceptors.response.use(
  undefined,
  (error: AxiosError) => {
    const config = error?.config as ExtendedAxiosRequestConfig | null;

    if (!config?.enableErrorHandling) {
      return Promise.reject(error);
    }

    const requestError = new RequestError(error);
    return Promise.reject(requestError);
  },
  {
    synchronous: true,
    runWhen: () => true,
  }
);

apiInstance.interceptors.response.use(
  undefined,
  (error: RequestError) => {
    const axiosError = error.error as AxiosError;

    const config = axiosError?.config as ExtendedAxiosRequestConfig | null;

    if (!config || !config.enableErrorHandling || !config.enableToastHandler) {
      return Promise.reject(error);
    }

    const store = storeRegistry.getStore();

    store.dispatch(AppActions.enqueueError(error.genericUserFacingMessage));

    return Promise.reject(error);
  },
  {
    synchronous: true,
  }
);

axiosRetry(apiInstance, {
  retries: 0,
  retryDelay: axiosRetry.exponentialDelay,
});

export { apiInstance };
