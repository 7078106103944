import colors from "config/colors";
import { ImageStyle, StyleProp, View } from "react-native";
import Text from "./Text";
import WebImage from "./WebImage";

interface Props {
  uri: Driver["profile_picture_url"];
  fullName: Driver["full_name"];
  size: number;
  style?: StyleProp<ImageStyle>;
}

const getInitialsFromFullName = (fullName) =>
  fullName.split(" ").map((part) => part[0]);

const NoUriPlaceholder = (props) => {
  const { label } = props;

  return (
    <View
      style={{
        backgroundColor: colors.main,
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Text font={"bold"} color={"white"} size={20}>
        {label}
      </Text>
    </View>
  );
};

const DriverAvatar = (props: Props) => {
  const { uri, fullName, size, style } = props;

  return (
    <WebImage
      uri={uri}
      width={size}
      height={size}
      shape={"rect"}
      borderRadius={3}
      // @ts-ignore
      containerStyle={style}
    >
      {!uri && (
        <NoUriPlaceholder
          label={fullName ? getInitialsFromFullName(fullName) : "?"}
        />
      )}
    </WebImage>
  );
};

export default DriverAvatar;
