import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignContent: "center",
    justifyContent: "center",
    paddingVertical: 2,
    paddingHorizontal: 6,
    borderRadius: 5,
    marginBottom: 4,
  },
  label: {
    marginHorizontal: 4,
    justifyContent: "center",
  },
  content: {
    justifyContent: "center",
    flexDirection: "row",
    alignItems: "center",
  },
});
