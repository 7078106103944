import { ErrorText } from "components";
import { StyleSheet } from "react-native";

export const SettingError = () => {
  return <ErrorText id={"settings.error"} style={styles.errorText} />;
};

const styles = StyleSheet.create({
  errorText: {
    margin: 20,
  },
});
