import colors from "config/colors";
import { Drawer as CustomDrawer } from "modules/OverviewScene/Drawer";
import { StyleSheet, View } from "react-native";
import OrderDetails from "./OrderDetails";
import { OverviewNavigationContextProvider } from "./OverviewNavigationContext";

const OverviewNavigatorComponent = () => {
  return (
    <View style={{ flexDirection: "row", height: "100%" }}>
      <View
        style={{
          width: "30%",
          minWidth: 330,
          borderRightWidth: 0,
          backgroundColor: colors.darkBlue,
        }}
      >
        <CustomDrawer />
      </View>
      <OrderDetails />
    </View>
  );
};

const OverviewNavigator = () => {
  return (
    <View style={styles.container}>
      <OverviewNavigationContextProvider>
        <OverviewNavigatorComponent />
      </OverviewNavigationContextProvider>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.lightBlue2,
    flex: 1,
    height: "100%",
    width: "100%",
  },
});

export default OverviewNavigator;
