import { OrderKinds } from "store/Orders/enums";

export enum SortingOrders {
  BY_SCHEDULED_FOR_ASC = "BY_SCHEDULED_FOR_ASC",
  BY_PROMISED_TIME_AT_ASC = "BY_PROMISED_TIME_AT_ASC",
  BY_PLACED_AT_ASC = "BY_PLACED_AT_ASC",
  BY_START_PREPARATION_STATUS_DESC = "BY_START_PREPARATION_STATUS_DESC",
}

export const DEFAULT_ACTIVE_OPACITY = 0.75;

export const ORDER_KINDS = Object.values(OrderKinds);

export const DEFAULT_SPRING_CONFIG = {
  damping: 17,
  mass: 1,
  stiffness: 100,
  overshootClamping: true,
};
