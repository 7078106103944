import colors from "config/colors";
import { AuthBackground } from "modules/Auth/components/AuthBackground";
import { Content } from "navigation/components/EnvironmentWatcher/Content";
import { ActivityIndicator, StyleSheet, View } from "react-native";

export function ReadyAccountScreen() {
  return (
    <AuthBackground>
      <View style={styles.container}>
        <Content
          titleKey="authScreen.readyAccount.loading.title"
          descriptionKey="authScreen.readyAccount.loading.description"
        />
        <ActivityIndicator
          size="large"
          animating
          color={colors.primary}
          style={{ marginTop: 8 }}
        />
      </View>
    </AuthBackground>
  );
}

const styles = StyleSheet.create({
  container: {
    alignSelf: "center",
    backgroundColor: "white",
    borderRadius: 8,
    maxWidth: 400,
    padding: 16,
  },
});
