import colors from "config/colors";
import { ColorValue, StyleSheet, View, ViewProps } from "react-native";

type Props = ViewProps & {
  color?: ColorValue;
};

export function VerticalDivider({
  style,
  color = colors.gray,
  ...props
}: Props) {
  return (
    <View
      {...props}
      style={[styles.vertical, { borderLeftColor: color }, style]}
    />
  );
}

export function HorizontalDivider({
  style,
  color = colors.gray,
  ...props
}: Props) {
  return (
    <View
      {...props}
      style={[styles.horizontal, { borderTopColor: color }, style]}
    />
  );
}

const styles = StyleSheet.create({
  horizontal: {
    alignSelf: "stretch",
    borderTopWidth: StyleSheet.hairlineWidth,
    opacity: 0.2,
  },
  vertical: {
    alignSelf: "stretch",
    width: StyleSheet.hairlineWidth,
    borderLeftWidth: StyleSheet.hairlineWidth,
    opacity: 0.2,
  },
});
