import { MaterialCommunityIcons } from "@expo/vector-icons";
import { Text, TextOverflow } from "components";
import colors from "config/colors";
import { useMemo } from "react";
import { StyleSheet, View } from "react-native";
import { useSelector } from "react-redux";
import { Order, OrderItem } from "store/Orders/types";
import { SettingSelectors } from "store/Settings";
import { mergeInternalNotes } from "util/helpers";
import Addons from "../Addons";

type ItemsProps = Pick<
  OrderItem,
  "name" | "quantity" | "category" | "addons"
> & {
  addonsType: OrderItem["addons_type"];
  specialInstructions: OrderItem["special_instructions"];
  internalNotes: Order["internal_notes"];
};

function InternalNotes({
  internalNotes,
}: {
  internalNotes: Order["internal_notes"];
}) {
  const notesMerged = useMemo(
    () =>
      internalNotes && internalNotes.length > 0
        ? mergeInternalNotes(internalNotes)
        : null,
    [internalNotes]
  );

  return (
    !!notesMerged && (
      <TextOverflow
        text={notesMerged}
        style={StyleSheet.flatten([
          { backgroundColor: colors.veryLightRed2 },
          styles.textContainer,
        ])}
        textColor={colors.darkRed}
        Overflow={
          <MaterialCommunityIcons
            name={"chevron-double-right"}
            color={colors.darkRed}
            size={20}
          />
        }
      />
    )
  );
}

const Items = (props: ItemsProps) => {
  const {
    name,
    quantity,
    category,
    addons,
    addonsType,
    specialInstructions,
    internalNotes,
  } = props;
  const { shouldDisplayInternalNotes } = useSelector(
    SettingSelectors.selectItemListInterfaceSettings
  );

  return (
    <View style={styles.container}>
      <View>
        <Text>
          <Text color={colors.text.dark} font={"bold"} size={14}>
            {quantity} - {name}
          </Text>{" "}
          {!!category && (
            <Text color={"#ccc"} font={"bold"} size={12}>
              {category}
            </Text>
          )}
        </Text>
        {addons && <Addons addonsType={addonsType} addons={addons} />}
      </View>
      {!!specialInstructions && (
        <TextOverflow
          text={specialInstructions}
          style={StyleSheet.flatten([
            { backgroundColor: "#ffbec0" },
            styles.textContainer,
          ])}
          textColor={"#4f1113"}
          Overflow={
            <MaterialCommunityIcons
              name={"chevron-double-right"}
              color={"#4f1113"}
              size={20}
            />
          }
        />
      )}
      {shouldDisplayInternalNotes && (
        <InternalNotes internalNotes={internalNotes} />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "space-between",
    padding: 8,
  },
  textContainer: {
    alignItems: "center",
    borderRadius: 5,
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 4,
  },
});

export default Items;
