import * as z from "zod";

export const schema = z.object({
  email: z
    .string({ required_error: "" })
    .nonempty({ message: "common.form.email.errorEmpty" })
    .email({ message: "common.form.email.errorMalformed" }),
  password: z.string().nonempty({ message: "common.form.password.errorEmpty" }),
});

export type FormValue = z.infer<typeof schema>;
