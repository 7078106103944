import { withErrorBoundary } from "components";
import { LanguageSelector } from "components/LanguageSelector";
import Setting from "modules/Settings/components/Setting";
import { RouteNames } from "navigation/linkingConfig";
import { useTranslation } from "react-i18next";
import { Section, SectionContainer } from "./components/Section";
import { SettingError } from "./components/SettingError";

function LanguageSection() {
  const { t } = useTranslation();

  return (
    <SectionContainer>
      <Section
        title={t("settings.general.language.title")}
        switchComponent={<LanguageSelector />}
      />
    </SectionContainer>
  );
}
export function General() {
  const { t } = useTranslation();

  return (
    <Setting title={t("settings.general.title")} iconName="cog-outline">
      <LanguageSection />
    </Setting>
  );
}

export default withErrorBoundary(General, {
  screen: RouteNames.Home.Settings._,
  key: "settings-sound",
  fallback: <SettingError />,
});
