import ErrorBoundary, { ErrorBoundaryProps } from "./ErrorBoundary";

type Options = {
  screen?: string;
  key?: string;
  fallback?: ErrorBoundaryProps["fallback"];
  renderFallback?: (props: unknown) => ErrorBoundaryProps["fallback"];
  tags?: Record<string, string>;
};

const withErrorBoundary = <T,>(
  WrappedComponent: React.ComponentType<T>,
  { screen, key, fallback, renderFallback, tags = {} }: Options
) => {
  const ComponentWithErrorBoundary = (props: T) => {
    return (
      <ErrorBoundary
        fallback={renderFallback ? renderFallback(props) : fallback}
        beforeCapture={([scope], errorUUID) => {
          scope.setTag("errorUUID", errorUUID);

          screen && scope.setTag("screen", screen);
          key && scope.setTag("key", key);

          Object.entries(tags).forEach(([key, value]) =>
            scope.setTag(key, value)
          );
        }}
      >
        <WrappedComponent {...props} />
      </ErrorBoundary>
    );
  };

  return ComponentWithErrorBoundary;
};

export default withErrorBoundary;
