import { initialSettingsState } from "store/Settings/reducer";
import type {
  ItemDetailsInterfaceSettingKey,
  OrderDetailsInterfaceSettingKey,
  OrderListInterfaceSettingKey,
  SettingState,
} from "store/Settings/types";
import type { InterfaceSettings } from "store/User/types";

export const MAP_ORDER_LIST_FLAG_TO_STATE: Record<
  keyof InterfaceSettings["order_overview_card"],
  OrderListInterfaceSettingKey
> = {
  customer_name: "shouldDisplayCustomerName",
  address_first_line: "shouldDisplayCustomerAddress",
};

export const MAP_ORDER_DETAILS_FLAG_TO_STATE: Partial<
  Record<
    keyof InterfaceSettings["order_details_card"],
    OrderDetailsInterfaceSettingKey
  >
> = {
  financial_details: "shouldDisplayFinancialInfo",
  customer_details: "shouldDisplayCustomerAddress",
  driver_details: "shouldDisplayDriverDetails",
  order_channel: "shouldDisplayOrderChannel",
  item_pricing: "shouldDisplayItemPricing",
  order_internal_notes: "shouldDisplayInternalNotes",
  kds_custom_fields: "shouldDisplayCustomFields",
};

export const MAP_ITEM_DETAILS_FLAG_TO_STATE: Partial<
  Record<
    keyof InterfaceSettings["item_details"],
    ItemDetailsInterfaceSettingKey
  >
> = {
  order_internal_notes: "shouldDisplayInternalNotes",
};

export const MAP_ITEM_LIST_FLAG_TO_STATE: Partial<
  Record<
    keyof InterfaceSettings["item_list_card"],
    ItemDetailsInterfaceSettingKey
  >
> = {
  order_internal_notes: "shouldDisplayInternalNotes",
};

export function resetInterfaceSettings(
  state: SettingState,
  featureFlags?: InterfaceSettings
) {
  if (!featureFlags) return;

  // If a feature flag has been disabled - reset the value of the interface setting to undefined.
  // If a feature flag has been enabled - reset the value to default.
  function reset(
    featureFlagKey: keyof InterfaceSettings,
    interfaceKey: keyof SettingState["interface"],
    map
  ) {
    Object.entries(featureFlags[featureFlagKey] ?? {}).forEach(
      ([key, value]) => {
        const stateKey = map[key];
        if (value) {
          if (state.interface[interfaceKey][stateKey] === undefined) {
            state.interface[interfaceKey][stateKey] =
              initialSettingsState.interface[interfaceKey][stateKey];
          }
          return;
        }
        state.interface[interfaceKey][stateKey] = undefined;
      }
    );
  }

  reset("order_overview_card", "orderList", MAP_ORDER_LIST_FLAG_TO_STATE);

  reset("order_details_card", "orderDetails", MAP_ORDER_DETAILS_FLAG_TO_STATE);

  reset("item_list_card", "itemList", MAP_ITEM_LIST_FLAG_TO_STATE);

  reset("item_details", "itemDetails", MAP_ITEM_DETAILS_FLAG_TO_STATE);
}
