import colors from "config/colors";
import { ImageBackground, SafeAreaView, StyleSheet } from "react-native";

type Props = {
  children: React.ReactNode;
  backgroundColor?: string;
};

export const AuthBackground = ({
  children,
  backgroundColor = colors.primary,
}: Props) => {
  return (
    <ImageBackground
      resizeMode="cover"
      source={require("assets/images/user/login/bg-transparent.png")}
      style={[styles.backgroundImage, { backgroundColor: backgroundColor }]}
    >
      <SafeAreaView style={styles.container}>{children}</SafeAreaView>
    </ImageBackground>
  );
};

const styles = StyleSheet.create({
  backgroundImage: {
    flex: 1,
  },
  container: {
    flex: 1,
    justifyContent: "center",
    margin: 24,
  },
});
