import { apiInstance } from "API/instances";
import { ExtendedAxiosRequestConfig } from "API/types";
import { executeRequest } from "API/util/functions";
import _ from "lodash";
import { normalize, schema } from "normalizr";
import { ItemPreparationStatuses } from "store/Orders/enums";
import type { Order, OrderItem } from "store/Orders/types";

const orderItemEntity = new schema.Entity(
  "orderItems",
  {},
  {
    idAttribute: "uuid",
    processStrategy: (value, parent, key) => ({
      ...value,
      order_uuid: parent.uuid,
    }),
  }
);
export const orderEntity = new schema.Entity(
  "orders",
  {
    item_uuids: [orderItemEntity],
  },
  {
    idAttribute: "uuid",
    processStrategy: (value, parent, key) => ({
      ..._.omit(value, "items"),
      item_uuids: value.items,
    }),
  }
);

const fetch = () =>
  executeRequest(
    (api) =>
      api.get("/v1/orders", {
        params: {
          cancelled_order: true,
        },
      }),
    (data) => normalize(data, [orderEntity]).entities
  );

const fetchById = (uuid: Order["uuid"]) =>
  executeRequest(
    (api) => api.get(`/v1/orders/${uuid}`),
    (data) => normalize(data, orderEntity).entities
  );

const updateItem = async (
  id: OrderItem["uuid"],
  changes: Partial<OrderItem>,
  config?: ExtendedAxiosRequestConfig
) => {
  const response = await apiInstance.patch(
    `/v1/items/${id}`,
    { item: changes },
    {
      enableErrorHandling: true,
      enableToastHandler: true,
      ...config,
    }
  );

  return response.data;
};

const updateItemStatuses = async (
  uuids: OrderItem["uuid"][],
  status: ItemPreparationStatuses,
  config?: ExtendedAxiosRequestConfig
) => {
  const response = await apiInstance.patch(
    `/v1/items/preparation_status`,
    { item: { uuids, status } },
    {
      enableErrorHandling: true,
      enableToastHandler: true,
      ...config,
    }
  );

  return response.data;
};

const printItem = async (
  id: Order["uuid"],
  config?: ExtendedAxiosRequestConfig
) => {
  const response = await apiInstance.get(`/v1/items/${id}/reprint`, {
    enableErrorHandling: true,
    enableToastHandler: true,
    ...config,
  });

  return response.data;
};

const printOrder = async (
  id: Order["uuid"],
  itemUUIDs: OrderItem["uuid"][],
  config?: ExtendedAxiosRequestConfig
) => {
  const response = await apiInstance.post(
    `/v1/orders/${id}/print`,
    {
      item_uuids: itemUUIDs,
    },
    {
      enableErrorHandling: true,
      enableToastHandler: true,
      ...config,
    }
  );

  return response.data;
};

export default {
  fetch,
  fetchById,
  updateItem,
  updateItemStatuses,
  printItem,
  printOrder,
};
