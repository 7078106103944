import { MaterialCommunityIcons } from "@expo/vector-icons";
import { Checkbox as CommonCheckBox, RadioButton, Text } from "components";
import colors from "config/colors";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";
import { MaterialCommunityIconName } from "types/icons";
import { SectionContainer, SectionContainerProps } from "./Section";

export type CheckBoxProps = {
  iconName?: MaterialCommunityIconName;
  isSelected?: boolean;
  onPress?: (value: boolean) => void;
  awaitingItems?: number;
  isDisabled?: boolean;
} & RequireAtLeastOne<{
  label: string;
  labelKey: string;
}>;

export const CheckBox = ({
  label,
  labelKey,
  iconName,
  isSelected = false,
  onPress,
  awaitingItems = 0,
  isDisabled = false,
}: CheckBoxProps) => {
  const { t } = useTranslation();

  return (
    <View style={styles.category}>
      <View style={styles.categoryTitleContainer}>
        <CommonCheckBox
          isChecked={isSelected}
          onPress={onPress}
          isDisabled={isDisabled}
        />
        <View style={styles.rowContent}>
          {Boolean(iconName) && (
            <View style={styles.iconContainer}>
              <MaterialCommunityIcons
                name={iconName}
                color={colors.darkBlue}
                size={24}
              />
            </View>
          )}
          <View style={{ alignItems: "baseline", flexDirection: "row" }}>
            <Text
              size={18}
              font={"medium"}
              color={colors.text.dark}
              style={{ textTransform: "uppercase" }}
              id={labelKey}
            >
              {label}
            </Text>
            {Boolean(awaitingItems) && (
              <Text
                size={14}
                font={"medium"}
                color={colors.ui.statuses.active}
                style={{ marginLeft: 8 }}
              >
                {t("settings.itemsNotCooking", {
                  value: awaitingItems,
                })}
              </Text>
            )}
          </View>
        </View>
      </View>
    </View>
  );
};

type CheckBoxSectionProps = {
  title?: string;
  titleKey?: string;
  subtitle?: string;
  subtitleKey?: string;
  onPressCheckBox?: () => void;
  isSelectedAll?: boolean;
  hideHeader?: boolean;
} & SectionContainerProps;

export const CheckBoxSection = ({
  title,
  titleKey,
  subtitle,
  subtitleKey,
  onPressCheckBox,
  isSelectedAll,
  isDisabled,
  children,
  hideHeader = false,
}: CheckBoxSectionProps) => {
  return (
    <View style={{ flex: 1 }}>
      {!hideHeader && (
        <View style={styles.titleContainer}>
          {onPressCheckBox && (
            <RadioButton
              value={isSelectedAll}
              isChecked={isSelectedAll}
              onPress={onPressCheckBox}
            />
          )}
          <View style={{ flex: 1 }}>
            <Text size={18} font="bold" id={titleKey}>
              {title}
            </Text>
            {!!(subtitle || subtitleKey) && (
              <Text style={styles.subtitle} id={subtitleKey}>
                {subtitle}
              </Text>
            )}
          </View>
        </View>
      )}
      {!!children && (
        <SectionContainer isDisabled={isDisabled}>{children}</SectionContainer>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  sectionContainer: {},
  titleContainer: {
    paddingHorizontal: 20,
    flexDirection: "row",
    alignItems: "flex-start",
  },
  subtitle: {
    flexWrap: "wrap",
    flexShrink: 1,
    marginBottom: 15,
    marginTop: 5,
  },
  category: {
    flexDirection: "column",
    height: 40,
    justifyContent: "flex-start",
    alignItems: "stretch",
  },
  categoryTitleContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  rowContent: { flexDirection: "row", alignItems: "center", paddingLeft: 4 },
  iconContainer: {
    paddingRight: 8,
    paddingLeft: 4,
    justifyContent: "center",
    alignItems: "center",
    color: colors.darkBlue,
    borderRadius: 4,
    marginRight: 4,
  },
});
