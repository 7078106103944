import { MaterialCommunityIcons } from "@expo/vector-icons";
import Color from "color";
import {
  CancelledOrderBadge,
  DeliveryStatusBadge,
  ErrorText,
  OrderStartPreparationStatusBadge,
  Text,
  withErrorBoundary,
} from "components";
import colors from "config/colors";
import { DateTime } from "luxon";
import { CountDownLabel } from "modules/ChefScene/components/Item/CountDownLabel";
import { RemakeOrderDescription } from "modules/OverviewScene/components/RemakeOrderDescription";
import { RouteNames } from "navigation/linkingConfig";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { useSelector } from "react-redux";
import useUpdateEffect from "react-use/lib/useUpdateEffect";
import { TabBarKey } from "store/App/constants";
import { NotificationsSelectors } from "store/Notifications";
import { OrderSelectors } from "store/Orders";
import { OrderInternalStatuses } from "store/Orders/enums";
import { Order } from "store/Orders/types";
import { getOrderInternalStatus } from "store/Orders/utils";
import { SettingSelectors } from "store/Settings";
import { UserSelectors } from "store/User";
import { AnalyticsEvents, useLogEventCallback } from "util/analytics";
import { getIconNameForOrderKind, useFormatDateTime } from "util/helpers";
import { useSumOrderItems } from "util/hooks";

export const OrderCellError = ({ order, style, resetError }) => {
  const { t } = useTranslation();

  useUpdateEffect(() => {
    resetError && resetError();
  }, [order?.uuid]);

  return (
    <ErrorText id="overview.error" style={[styles.container, style]}>
      {order && (
        <>
          <Text
            color={colors.text.dark}
            font={"bold"}
            size={14}
            style={styles.errorText}
            align="center"
          >
            #{order.description}
            {order.remake_for_order_description && (
              <RemakeOrderDescription
                style={{ marginLeft: 4, marginTop: 4 }}
                description={order.remake_for_order_description}
              />
            )}
          </Text>
          <Text
            color={colors.gray}
            font={"medium"}
            size={14}
            style={styles.errorText}
            align="center"
          >
            {t("common.orderUUID")}: {order.uuid}
          </Text>
        </>
      )}
    </ErrorText>
  );
};

type OrderCellComponentProps = {
  order: Order;
  isSelected: boolean;
  isScheduled: boolean;
  tab: TabBarKey;
  onPress?: (orderId: string) => void;
};
const OrderCellComponent = memo((props: OrderCellComponentProps) => {
  const { order, isSelected, tab, onPress } = props;

  const { t } = useTranslation();

  const hasUnacceptedNotifications = useSelector((state) =>
    // @ts-ignore
    NotificationsSelectors.selectOrderHasUnattended(state, order.uuid)
  );
  const featureFlags = useSelector(UserSelectors.selectFeatureFlags);
  const orderListInterfaceSettings = useSelector(
    SettingSelectors.selectOrderListInterfaceSettings
  );

  const logEvent = useLogEventCallback();

  const items = useSelector((state) =>
    order
      ? OrderSelectors.selectItemsForOrderWithCategories(state, order.uuid)
      : null
  );

  const itemCount = useSumOrderItems(items);
  const orderInternalStatus = getOrderInternalStatus(order, items);
  const statusColor = !isSelected ? colors.white : colors.darkGray;
  const subColor = !isSelected ? colors.darkGray : colors.white;

  const formatDateTime = useFormatDateTime();

  // if (!itemCount) return null

  return (
    <TouchableOpacity
      activeOpacity={0.75}
      style={styles.container}
      onPress={(event) => {
        logEvent(
          AnalyticsEvents.orderCellPressed({
            screen: RouteNames.Home.Main.Drawer.Tabs.Overview._,
            tab,
          })
        );
        onPress?.(order.uuid);
      }}
    >
      <View
        style={[
          styles.content,
          {
            backgroundColor: isSelected
              ? statusColor
              : hasUnacceptedNotifications
              ? Color(colors.ui.notifications.unread)
                  .darken(0.3)
                  .fade(0.1)
                  .hexa()
              : statusColor,
          },
        ]}
      >
        <View
          style={{
            width: 60,
            backgroundColor: "black",
            alignItems: "center",
            paddingVertical: 4,
          }}
        >
          <MaterialCommunityIcons
            name={getIconNameForOrderKind(order.kind)}
            color={"white"}
            size={32}
          />
          {Boolean(order.latest_pickup_time) &&
            [
              OrderInternalStatuses.NOT_BEING_PREPARED,
              OrderInternalStatuses.BEING_PREPARED,
            ].includes(orderInternalStatus) && (
              <CountDownLabel latestPickupTime={order.latest_pickup_time} />
            )}
        </View>

        <View style={styles.leftColumn}>
          <View
            style={{
              flexDirection: "row",
              alignItems: "flex-start",
              flexWrap: "wrap",
              marginBottom: 4,
            }}
          >
            <Text
              font={"bold"}
              color={subColor}
              size={16}
              style={{ flexWrap: "wrap", flexShrink: 1, flexGrow: 1 }}
            >
              #{order.description}{" "}
              {order.remake_for_order_description && (
                <RemakeOrderDescription
                  description={order.remake_for_order_description}
                  color={subColor}
                  style={{ marginLeft: 4, marginTop: 4 }}
                  prefix=""
                />
              )}
            </Text>
          </View>
          {featureFlags.display_customer_address && (
            <View
              style={{
                alignItems: "flex-start",
              }}
            >
              {orderListInterfaceSettings.shouldDisplayCustomerName && (
                <Text
                  font={"medium"}
                  size={12}
                  style={{ marginLeft: 4 }}
                  numberOfLines={1}
                  color={subColor}
                >
                  {order.recipient.full_name}
                </Text>
              )}
              {Boolean(
                orderListInterfaceSettings.shouldDisplayCustomerAddress &&
                  order.dropoff_location?.line_1
              ) && (
                <Text
                  font={"medium"}
                  size={12}
                  style={{ marginLeft: 4 }}
                  numberOfLines={1}
                  color={subColor}
                >
                  {order.dropoff_location.line_1}
                </Text>
              )}
            </View>
          )}
        </View>

        <View style={[styles.rightColumn, { borderLeftColor: subColor }]}>
          <View>
            {featureFlags.kds_enabled_traffic_light_system &&
              orderInternalStatus ===
                OrderInternalStatuses.NOT_BEING_PREPARED && (
                <OrderStartPreparationStatusBadge
                  status={order.start_preparation_status}
                  style={{ marginBottom: 0 }}
                />
              )}
            {orderInternalStatus === OrderInternalStatuses.CANCELLED ? (
              <CancelledOrderBadge style={styles.badge} />
            ) : order.job &&
              orderInternalStatus === OrderInternalStatuses.PREPARED ? (
              <DeliveryStatusBadge
                status={order.job.delivery_status}
                geoVerified={order.job.geo_verified}
                style={styles.badge}
              />
            ) : null}
            {!!order.scheduled_for && (
              <View
                style={[
                  styles.badge,
                  {
                    flexDirection: "row",
                    alignItems: "center",
                    backgroundColor: colors.ui.statuses.scheduled,
                    borderRadius: 4,
                    paddingVertical: 4,
                    paddingHorizontal: 2,
                    justifyContent: "center",
                  },
                ]}
              >
                <MaterialCommunityIcons
                  name={"calendar-clock"}
                  color={"white"}
                  size={14}
                />
                <Text
                  color={"white"}
                  font={"medium"}
                  size={12}
                  style={{ marginLeft: 4 }}
                >
                  {formatDateTime(order.scheduled_for, DateTime.TIME_SIMPLE)}
                </Text>
              </View>
            )}
          </View>
          <View
            style={{
              marginTop: 8,
              alignItems: "flex-end",
              justifyContent: "space-between",
            }}
          >
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <MaterialCommunityIcons name={"at"} color={subColor} size={16} />
              <Text
                font="bold"
                align="right"
                color={subColor}
                size={14}
                style={{ marginLeft: 4 }}
              >
                {formatDateTime(order.created_at, DateTime.TIME_SIMPLE)}
              </Text>
            </View>
            <Text
              font="bold"
              align="right"
              color={subColor}
              size={14}
              style={{ marginLeft: 4 }}
            >
              {t("overview.drawer.orderItem.items", {
                count: itemCount,
              })}
            </Text>
          </View>
        </View>
      </View>
    </TouchableOpacity>
  );
});

OrderCellComponent.displayName = "OrderCellComponent";

const styles = StyleSheet.create({
  container: {
    minHeight: 100,
    marginTop: StyleSheet.hairlineWidth,
    paddingHorizontal: 4,
    flexShrink: 1,
    flexGrow: 1,
  },
  content: {
    flexGrow: 1,
    flexDirection: "row",
  },
  times: {
    flexDirection: "row",
  },
  leftColumn: {
    flex: 1,
    padding: 8,
    justifyContent: "space-between",
  },
  rightColumn: {
    width: 100,
    marginVertical: 8,
    paddingHorizontal: 4,
    borderLeftWidth: StyleSheet.hairlineWidth,
    justifyContent: "space-between",
  },
  errorText: {
    marginTop: 4,
    marginHorizontal: 4,
  },
  badge: {
    marginTop: 4,
  },
});

export const OrderCell = withErrorBoundary(OrderCellComponent, {
  screen: RouteNames.Home.Main.Drawer.Tabs.Overview._,
  key: "overview-drawer-cell",
  renderFallback: (props: any) => <OrderCellError {...props} />,
});
