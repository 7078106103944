import assets from "assets";
import { Alert as AlertComponent } from "components";
import { Audio } from "expo-av";
import { PureComponent } from "react";
import { useTranslation } from "react-i18next";
import { captureException } from "util/sentry";

type AlertProps = {
  visible: boolean;
  onRequestClose: () => void;
  onConfirm: () => void;
  orderDescription: string;
};

function Alert({
  visible,
  onConfirm,
  onRequestClose,
  orderDescription,
}: AlertProps) {
  const { t } = useTranslation();

  return (
    <AlertComponent
      visible={visible}
      onRequestClose={onRequestClose}
      onConfirm={onConfirm}
      title={t("components.alerts.newOrder", {
        orderDescription: orderDescription ?? "???",
      })}
      message={t("components.alerts.pressOkToStopSound")}
    />
  );
}

interface Props {
  shouldRepeatSound: boolean;
}

interface State {
  isDialogVisible: boolean;
  orderDescription: string;
}

export class SoundManager extends PureComponent<Props, State> {
  public readonly state: State = {
    isDialogVisible: false,
    orderDescription: null,
  };
  private audioPlayer: Audio.Sound = new Audio.Sound();

  async componentDidMount() {
    await Audio.setIsEnabledAsync(true);
    await this.audioPlayer.loadAsync(assets.sounds.bell);
    await this.updateSoundRepeatingMode();
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ) {
    if (this.props.shouldRepeatSound !== prevProps.shouldRepeatSound) {
      this.updateSoundRepeatingMode().catch(captureException);
    }
  }

  setDialogVisibility = (isDialogVisible) => this.setState({ isDialogVisible });

  updateSoundRepeatingMode = async () => {
    await this.audioPlayer.setIsLoopingAsync(this.props.shouldRepeatSound);
  };

  public showNotification = (orderDescription: string) => {
    this.setState({ orderDescription });
    if (this.props.shouldRepeatSound) {
      this.setDialogVisibility(true);
    }
    this.playSound().catch(captureException);
  };

  onDismiss = () => {
    this.setDialogVisibility(false);
    this.stopSound().catch(captureException);
  };

  playSound = async () => {
    await this.audioPlayer.setPositionAsync(0);
    await this.audioPlayer.playAsync();
  };

  stopSound = async () => {
    await this.audioPlayer.stopAsync();
  };

  render() {
    const { isDialogVisible } = this.state;
    return (
      <Alert
        visible={isDialogVisible}
        onRequestClose={this.onDismiss}
        onConfirm={this.onDismiss}
        orderDescription={this.state.orderDescription}
      />
    );
  }
}

export default SoundManager;
