import { MaterialCommunityIcons } from "@expo/vector-icons";
import colors from "config/colors";
import { DEFAULT_ACTIVE_OPACITY } from "config/constants";
import { forwardRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Insets,
  StyleProp,
  StyleSheet,
  TextInput,
  TextInputProps,
  TouchableOpacity,
  View,
  ViewStyle,
} from "react-native";
import { isWeb } from "util/helpers";

type Props = TextInputProps & {
  placeholderKey?: string;
  containerStyle?: StyleProp<ViewStyle>;
};

export const Input = forwardRef(
  (
    {
      style,
      containerStyle,
      placeholderKey,
      secureTextEntry,
      value,
      ...props
    }: Props,
    ref: React.ForwardedRef<TextInput>
  ) => {
    const { t } = useTranslation();

    const [isPasswordVisible, setPasswordVisibility] = useState(false);

    useEffect(() => {
      if (!value) {
        setPasswordVisibility(false);
      }
    }, [value]);

    return (
      <View style={[styles.container, containerStyle]}>
        <TextInput
          style={[styles.input, style]}
          placeholderTextColor={`${colors.gray}88`}
          underlineColorAndroid="transparent"
          secureTextEntry={secureTextEntry && !isPasswordVisible}
          ref={ref}
          value={value}
          {...props}
          {...(placeholderKey
            ? {
                placeholder: t(placeholderKey),
              }
            : {})}
        />
        {Boolean(value) && secureTextEntry && (
          <TouchableOpacity
            hitSlop={12 as Insets}
            style={styles.eyeIcon}
            activeOpacity={DEFAULT_ACTIVE_OPACITY}
            onPress={() => setPasswordVisibility((curr) => !curr)}
          >
            {!isPasswordVisible ? (
              <MaterialCommunityIcons
                name="eye-off"
                size={16}
                color={colors.lightBlue}
              />
            ) : (
              <MaterialCommunityIcons
                name="eye"
                size={16}
                color={colors.lightBlue}
              />
            )}
          </TouchableOpacity>
        )}
      </View>
    );
  }
);

const styles = StyleSheet.create({
  container: {
    backgroundColor: "white",
    borderRadius: 4,
    padding: 8,
    borderColor: `${colors.gray}55`,
    borderWidth: 1,
    flexDirection: "row",
    alignItems: "center",
  },
  input: {
    flexGrow: 1,
    flexShrink: 1,
    ...(isWeb
      ? {
          outlineStyle: "none",
        }
      : {}),
  },
  eyeIcon: {
    marginLeft: 4,
  },
});
