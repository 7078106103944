export enum OrderKinds {
  // restaurant is fulfilling delivery
  DELIVERY = "delivery",
  // customer is coming to pick it up
  CUSTOMER_PICKUP = "collection",
  // dine in
  WALK_IN = "walk_in",
  DRIVE_THRU = "drive_thru",
  // marketplace order, driver will collect and deliver from third party
  THIRD_PARTY_DELIVERY = "third_party_delivery",
}

export enum OrderPreparationStatuses {
  BEING_PREPARED = "being_prepared",
  PREPARED = "prepared",
  NOT_BEING_PREPARED = "not_being_prepared",
  SUGGESTED = "suggested",
  COMPLETED = "completed",
  SCHEDULED = "scheduled",
  CANCELLED = "cancelled",
}

export enum OrderInternalStatuses {
  BEING_PREPARED = "being_prepared",
  PREPARED = "prepared",
  NOT_BEING_PREPARED = "not_being_prepared",
  SCHEDULED = "scheduled",
  CANCELLED = "cancelled",
}

export enum AddonTypes {
  PIZZA = "pizza",
}

export enum OrderStatuses {
  BEING_PREPARED = "being_prepared",
  PREPARED = "prepared",
  IN_PROGRESS = "in_progress",
  NOT_DISPATCHED = "not_dispatched",
  COMPLETED = "completed",
  SCHEDULED = "scheduled",
  FAILED = "failed",
  CANCELLED = "cancelled",
  AUTO_CLOSED = "auto_closed",
}

export enum ItemPreparationStatuses {
  BEING_PREPARED = "being_prepared",
  PREPARED = "prepared",
  NOT_BEING_PREPARED = "not_being_prepared",
  COMPLETED = "completed",
}

export enum OrderDeliveryChannels {
  UNKNOWN = "unknown",
}

export enum OrderDeliveryStatuses {
  UNASSIGNED = "unassigned_to_driver",
  ASSIGNED = "assigned_to_driver",
  ACCEPTED = "assigned_and_accepted_by_driver",
  COLLECTED = "collected",
  DROPPED = "dropped_off",
  FAILED = "failed",
  RETURNING_TO_PICKUP_WITH_DELIVERY = "returning_to_pickup_with_delivery",
  CANCELLED = "cancelled",
}
