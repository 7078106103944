import colors from "config/colors";
import { OrderDeliveryStatuses } from "store/Orders/enums";
import { MaterialCommunityIconName } from "types/icons";

export const OrderStartPreparationStatusInterfaces: Record<
  StartPreparationStatus,
  {
    color: string;
    iconName: MaterialCommunityIconName;
    translationKey: string;
  }
> = {
  late: {
    color: colors.ui.startPreparationStatuses.late,
    iconName: "alert-circle",
    translationKey: "common.startPreparationStatus.late",
  },
  risk_late: {
    color: colors.ui.startPreparationStatuses.riskLate,
    iconName: "alert-circle",
    translationKey: "common.startPreparationStatus.riskLate",
  },
  now: {
    color: colors.ui.startPreparationStatuses.now,
    iconName: "fire",
    translationKey: "common.startPreparationStatus.now",
  },
  early: {
    color: colors.ui.startPreparationStatuses.early,
    iconName: "checkbox-blank-circle-outline",
    translationKey: "common.startPreparationStatus.early",
  },
} as const;

export const DeliveryStatusInterfaces: Record<
  OrderDeliveryStatuses,
  {
    bgColor: string;
    color?: string;
    translationKey: string;
  }
> = {
  unassigned_to_driver: {
    bgColor: colors.ui.deliveryStatuses.unassigned,
    color: colors.white,
    translationKey: "common.deliveryStatus.unassigned",
  },
  assigned_to_driver: {
    bgColor: colors.ui.deliveryStatuses.assigned,
    color: colors.white,
    translationKey: "common.deliveryStatus.assigned",
  },
  assigned_and_accepted_by_driver: {
    bgColor: colors.ui.deliveryStatuses.accepted,
    color: colors.white,
    translationKey: "common.deliveryStatus.accepted",
  },
  collected: {
    bgColor: colors.ui.deliveryStatuses.collected,
    color: colors.white,
    translationKey: "common.deliveryStatus.collected",
  },
  dropped_off: {
    bgColor: colors.ui.deliveryStatuses.dropped,
    color: colors.white,
    translationKey: "common.deliveryStatus.dropped",
  },
  failed: {
    bgColor: colors.ui.deliveryStatuses.failed,
    color: colors.white,
    translationKey: "common.deliveryStatus.failed",
  },
  returning_to_pickup_with_delivery: {
    bgColor: colors.ui.deliveryStatuses.failed,
    color: colors.white,
    translationKey: "common.deliveryStatus.failed",
  },
  cancelled: {
    bgColor: colors.ui.deliveryStatuses.cancelled,
    color: colors.white,
    translationKey: "common.deliveryStatus.cancelled",
  },
} as const;
