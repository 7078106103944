import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SortingOrders } from "config/constants";
import _ from "lodash";
import { AuthActions } from "store/Auth";
import { DeviceThunks } from "store/Device";
import type { Category, OrderKind, SettingState } from "store/Settings/types";
import { UserActions } from "store/User";
import SettingActions from "./actions";
import { resetInterfaceSettings } from "./utils";

export const initialSettingsState: SettingState = {
  allCategories: [],
  selectedCategories: [],
  isSelectedAllCategories: true,
  selectedOrderKinds: [],
  isSelectedAllOrderKinds: true,
  isItemCookDelayEnabled: false,
  itemCookDelayInSeconds: 2,
  ordersSortingOrder: SortingOrders.BY_START_PREPARATION_STATUS_DESC,
  loopNotificationSound: false,
  isCountDownTimerEnabled: true,
  isCountDownTimerBlinkingThresholdEnabled: true,
  countDownTimerBlinkingThreshold: 20,
  interface: {
    itemList: {
      shouldDisplayActionButton: true,
      shouldDisplayInternalNotes: true,
    },
    itemDetails: {
      shouldDisplayActionButton: true,
      shouldDisplayInternalNotes: true,
    },
    orderList: {
      shouldDisplayCustomerName: true,
      shouldDisplayCustomerAddress: true,
    },
    orderDetails: {
      shouldDisplayCustomFields: true,
      shouldDisplayActionButtonHeader: true,
      shouldDisplayActionButtonItems: true,
      shouldDisplayFinancialInfo: true,
      shouldDisplayCustomerAddress: true,
      shouldDisplayDriverDetails: true,
      shouldDisplayItemPricing: true,
      shouldDisplayOrderChannel: true,
      shouldDisplayInternalNotes: true,
    },
  },
};

const slice = createSlice({
  name: "settings",
  initialState: initialSettingsState,
  reducers: {
    setSelectedCategories: (state, action: PayloadAction<Category[]>) => {
      state.selectedCategories = action.payload;
    },
    toggleAllCategories: (state, action: PayloadAction<boolean>) => {
      state.isSelectedAllCategories = action.payload;
    },
    setSelectedOrderKinds: (state, action: PayloadAction<OrderKind[]>) => {
      state.selectedOrderKinds = action.payload;
    },
    toggleAllOrderKinds: (state, action: PayloadAction<boolean>) => {
      state.isSelectedAllOrderKinds = action.payload;
    },
    setOrdersSorting: (state, action) => {
      state.ordersSortingOrder = action.payload;
    },
    toggleItemCookDelayEnabled: (state, action) => {
      state.isItemCookDelayEnabled = action.payload;
    },
    setItemCookDelay: (state, action: PayloadAction<number>) => {
      state.itemCookDelayInSeconds = action.payload;
    },
    setRepeatSoundsOptions: (state, action) => {
      state.loopNotificationSound = action.payload;
    },
    toggleCountDownTimer: (state, action) => {
      state.isCountDownTimerEnabled = action.payload;
    },
    toggleCountDownBlinkingThresholdEnabled: (state, action) => {
      state.isCountDownTimerBlinkingThresholdEnabled = action.payload;
    },
    setCountDownTimerBlinkingThreshold: (state, action) => {
      state.countDownTimerBlinkingThreshold = action.payload;
    },
    setItemListInterface: (state, action) => {
      const newState = {
        ...state.interface.itemList,
        ...action.payload,
      };

      state.interface = {
        ...state.interface,
        itemList: newState,
      };
    },
    setItemDetailsInterface: (state, action) => {
      const newState = {
        ...state.interface.itemDetails,
        ...action.payload,
      };

      state.interface = {
        ...state.interface,
        itemDetails: newState,
      };
    },
    setOrderListInterface: (state, action) => {
      const newState = {
        ...state.interface.orderList,
        ...action.payload,
      };

      state.interface = {
        ...state.interface,
        orderList: newState,
      };
    },
    setOrderDetailsInterface: (state, action) => {
      const newState = {
        ...state.interface.orderDetails,
        ...action.payload,
      };

      state.interface = {
        ...state.interface,
        orderDetails: newState,
      };
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(SettingActions.fetchSuccess, (state, action) => {
        const { items } = action.payload;
        state.allCategories = items;
        // these categories were selected, but they aren't presented in 'all' anymore
        const missingCategories = _.difference(
          state.selectedCategories,
          state.allCategories
        );
        if (missingCategories.length) {
          state.selectedCategories = _.without(
            state.selectedCategories,
            ...missingCategories
          );
        }
      })
      .addCase(UserActions.fetchAccountSuccess, (state, action) => {
        const { interface_settings: interfaceSettings } = action.payload;
        resetInterfaceSettings(state, interfaceSettings);
      })
      .addCase(UserActions.selectAccount, (state, action) => {
        const account = action.payload;

        if (account) {
          const interfaceSettings = account.interface_settings;
          resetInterfaceSettings(state, interfaceSettings);
        }
      })
      .addCase(DeviceThunks.setScreen.fulfilled, (state) => {
        state.isSelectedAllCategories = false;
        state.selectedCategories = [];

        state.isSelectedAllOrderKinds = false;
        state.selectedOrderKinds = [];
      })
      .addCase(AuthActions.signOut, (state) => {
        state.isSelectedAllCategories =
          initialSettingsState.isSelectedAllCategories;
        state.selectedCategories = initialSettingsState.selectedCategories;

        state.isSelectedAllOrderKinds =
          initialSettingsState.isSelectedAllOrderKinds;
        state.selectedOrderKinds = initialSettingsState.selectedOrderKinds;
      }),
});

export const sliceActions = slice.actions;
export default slice.reducer;
