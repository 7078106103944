import { Text } from "components";
import colors from "config/colors";
import { DEFAULT_SPRING_CONFIG } from "config/constants";
import { useEffect } from "react";
import { StyleSheet } from "react-native";
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withRepeat,
  withSequence,
  withSpring,
} from "react-native-reanimated";
import { useSelector } from "react-redux";
import { SettingSelectors } from "store/Settings";
import { UserSelectors } from "store/User";
import { useCountDownFromNowLabel } from "util/hooks";

type Props = {
  latestPickupTime?: string;
};

export const CountDownLabelComponent = ({ latestPickupTime }: Props) => {
  const isBlinkingEnabled = useSelector(
    SettingSelectors.selectCountDownTimerBlinkingEnabled
  );
  const threshold = useSelector(
    SettingSelectors.selectCountDownTimerBlinkingThreshold
  );

  const { label, isBlinking } = useCountDownFromNowLabel(
    latestPickupTime,
    threshold,
    isBlinkingEnabled
  );

  const sharedAnimatedValue = useSharedValue(colors.gray);

  useEffect(() => {
    if (isBlinking) {
      sharedAnimatedValue.value = withRepeat(
        withSequence(
          withSpring(colors.lightRed, DEFAULT_SPRING_CONFIG),
          withSpring(colors.gray, DEFAULT_SPRING_CONFIG)
        ),
        -1,
        true
      );
    } else {
      sharedAnimatedValue.value = withSpring(
        colors.gray,
        DEFAULT_SPRING_CONFIG
      );
    }
  }, [isBlinking]);

  const animatedContainerStyle = useAnimatedStyle(() => {
    const backgroundColor = sharedAnimatedValue.value;

    return {
      backgroundColor,
    };
  }, []);

  return (
    <Animated.View style={[animatedContainerStyle, styles.countDown]}>
      <Text color="white" size={12} numberOfLines={1}>
        {label}
      </Text>
    </Animated.View>
  );
};

export const CountDownLabel = (props: Props) => {
  const isCountDownTimerEnabled = useSelector(
    SettingSelectors.selectCountDownTimerEnable
  );

  const featureFlags = useSelector(UserSelectors.selectFeatureFlags);

  const {
    kds_enabled_traffic_light_system: isTrafficLightSystemEnabled,
    kds_enabled: isKdsEnabled,
  } = featureFlags;

  if (
    !isCountDownTimerEnabled ||
    !isTrafficLightSystemEnabled ||
    !isKdsEnabled
  ) {
    return null;
  }

  return <CountDownLabelComponent {...props} />;
};

const styles = StyleSheet.create({
  countDown: {
    marginTop: 5,
    borderRadius: 4,
    alignItems: "center",
    justifyContent: "center",
    width: 50,
    height: 30,
    padding: 2,
  },
  label: {
    flexShrink: 1,
  },
});
