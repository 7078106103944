import { MaterialCommunityIcons } from "@expo/vector-icons";
import Text, { FontFamilies } from "components/Text";
import colors from "config/colors";
import { ReactNode, useMemo, useRef } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import RNInputSpinner from "react-native-input-spinner";

interface Props {
  value: number;
  onChange: (newValue: number) => void;
  min: number;
  max: number;
  iconSize: number;
  labelSize: number;
  labelKey: string;
  color: string;
  size: number;
  disabled?: boolean;
  children?: ReactNode;
}

const InputSpinner = (props: Props) => {
  const {
    value,
    onChange,
    min,
    max,
    color = colors.main,
    size,
    disabled,
    iconSize,
    labelSize,
    labelKey,
    children,
  } = props;
  const textInputRef = useRef();

  const textInputProps = useMemo(() => ({ ref: textInputRef }), [textInputRef]);
  const disabledColor = colors.veryLightGray;
  const textColor = !disabled ? color : disabledColor;

  return (
    <View>
      <RNInputSpinner
        disabled={disabled}
        max={max}
        min={min}
        value={value}
        onChange={onChange}
        precision={0}
        buttonStyle={{ backgroundColor: "transparent", borderRadius: 0 }}
        rounded={false}
        activeOpacity={0.75}
        style={{
          width: 170,
          height: 60,
          marginBottom: 8,
          padding: 4,
          justifyContent: "center",
          borderWidth: StyleSheet.hairlineWidth,
          borderColor: !disabled ? colors.lightGray : disabledColor,
        }}
        textColor={textColor}
        colorPress={"transparent"}
        fontFamily={FontFamilies.bold}
        fontSize={28}
        inputProps={textInputProps}
        buttonLeftImage={
          <MaterialCommunityIcons
            name={"minus"}
            size={36}
            color={value > min && !disabled ? colors.main : disabledColor}
          />
        }
        buttonRightImage={
          <MaterialCommunityIcons
            name={"plus"}
            size={36}
            color={value < max && !disabled ? colors.main : disabledColor}
          />
        }
      >
        {children}
        <TouchableOpacity
          style={[
            styles.item,
            {
              borderColor: textColor,
              borderWidth: 0,
              width: size,
              height: size,
            },
          ]}
          onPress={() => {
            // @ts-ignore
            textInputRef.current?.focus();
          }}
          activeOpacity={0.75}
        >
          {/* <MaterialCommunityIcons
            name={'pen'}
            color={textColor}
            style={styles.edit}
            size={iconSize}
          />*/}
        </TouchableOpacity>
      </RNInputSpinner>
      <Text
        color={!disabled ? colors.darkGray : disabledColor}
        font={"bold"}
        numberOfLines={1}
        size={labelSize}
        id={labelKey}
        align="center"
      />
    </View>
  );
};

const styles = StyleSheet.create({
  item: {
    position: "absolute",
    marginLeft: "auto",
    marginRight: "auto",
    borderRadius: 5,
    alignItems: "center",
    justifyContent: "center",
    borderWidth: 0,
  },
  edit: {
    position: "absolute",
    bottom: 0,
    right: 0,
  },
});

export default InputSpinner;
