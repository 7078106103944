import { MaterialCommunityIcons } from "@expo/vector-icons";
import colors from "config/colors";
import { DEFAULT_ACTIVE_OPACITY } from "config/constants";
import { TouchableOpacity, TouchableOpacityProps } from "react-native";

export function CloseButton({
  color = colors.darkGray,
  ...props
}: TouchableOpacityProps & {
  color?: string;
}) {
  return (
    <TouchableOpacity
      activeOpacity={DEFAULT_ACTIVE_OPACITY}
      hitSlop={8}
      {...props}
    >
      <MaterialCommunityIcons name="window-close" color={color} size={24} />
    </TouchableOpacity>
  );
}
