import { createAsyncThunk } from "@reduxjs/toolkit";
import * as ApiAuth from "API/modules/Auth";
import { EnvironmentServer } from "modules/Auth/components/SelectEnvironmentModal";
import * as AuthActions from "./actions";

export const login = createAsyncThunk(
  AuthActions.login.toString(),
  async (
    {
      environment,
      ...payload
    }: ApiAuth.LoginPayload & {
      environment: EnvironmentServer;
    },
    { signal, rejectWithValue }
  ) => {
    const response = await ApiAuth.login(payload, { signal });

    if (response.error) {
      return rejectWithValue(response);
    }

    return { response, environment };
  }
);

export const resetPassword = createAsyncThunk(
  AuthActions.resetPassword.toString(),
  async (payload: ApiAuth.ResetPasswordPayload) => {
    const response = await ApiAuth.resetPassword(payload);

    return response;
  }
);

export const fetchEnvironments = createAsyncThunk(
  AuthActions.fetchEnvironments.toString(),
  async (payload: ApiAuth.FetchEnvironmentsPayload) => {
    const response = await ApiAuth.fetchEnvironments(payload);

    return response;
  }
);
