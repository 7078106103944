import { useLinkProps } from "@react-navigation/native";
import { ErrorText, Text, withErrorBoundary } from "components";
import colors from "config/colors";
import Delivery from "modules/ChefScene/components/Item/Delivery";
import Items from "modules/ChefScene/components/Item/Items";
import Status from "modules/ChefScene/components/Item/Status";
import { RemakeOrderDescription } from "modules/OverviewScene/components";
import { RouteNames } from "navigation/linkingConfig";
import { useTranslation } from "react-i18next";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { useSelector } from "react-redux";
import { NotificationsSelectors } from "store/Notifications";
import {
  ItemPreparationStatuses,
  OrderDeliveryChannels,
} from "store/Orders/enums";
import type { Order, OrderItem } from "store/Orders/types";
import { SettingSelectors } from "store/Settings";
import { AnalyticsEvents, useLogEventCallback } from "util/analytics";
import { ActionButtonItem, HasUnacceptChangesLabel } from "../ActionButton";

interface Props {
  item: OrderItem;
  order: Order;
  showCookButton?: boolean;
  isScheduled?: boolean;
  isSameOrder?: boolean;
  isOdd?: boolean;
  onStatusChanged?: (props: {
    orderId: string;
    itemId: string;
    mode: "single" | "group";
    startDate: Date;
    endDate: Date;
    status: ItemPreparationStatuses;
    screen: string | undefined;
  }) => void;
}

export const ItemError = ({ item, order }: Props) => {
  const { t } = useTranslation();

  return (
    <ErrorText id="chef.item.error" style={styles.container}>
      {order && (
        <Text
          color={colors.text.dark}
          font={"bold"}
          size={18}
          style={styles.errorText}
          align="center"
        >
          #{order.description}
          {order.remake_for_order_uuid &&
            order.remake_for_order_description && (
              <RemakeOrderDescription
                style={{ marginLeft: 4 }}
                description={order.remake_for_order_description}
              />
            )}
        </Text>
      )}

      <View
        style={{
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        {order && (
          <Text
            color={colors.gray}
            font={"medium"}
            size={14}
            style={styles.errorText}
            align="center"
          >
            {t("common.orderUUID")}: {order.uuid}
          </Text>
        )}
        {item && (
          <Text
            color={colors.gray}
            font={"medium"}
            size={14}
            style={styles.errorText}
            align="center"
          >
            {t("common.itemUUID")}: {item.uuid}
          </Text>
        )}
      </View>
    </ErrorText>
  );
};

const Item = (props: Props) => {
  const {
    item,
    order,
    showCookButton,
    isScheduled,
    isSameOrder = true,
    isOdd,
    onStatusChanged,
  } = props;

  const { shouldDisplayActionButton } = useSelector(
    SettingSelectors.selectItemListInterfaceSettings
  );

  const hasUnacceptedNotifications = useSelector((state) =>
    // @ts-ignore
    NotificationsSelectors.selectOrderItemHasUnattended(state, item.uuid)
  );

  const navigationProps = useLinkProps({
    to: {
      screen: RouteNames.Home.Main.ItemDetails,
      params: { uuid: props.item.uuid },
    },
  });

  const logEvent = useLogEventCallback();

  const getStatusChangeCallback =
    (params?: any) =>
    (
      statusToUpdate: ItemPreparationStatuses,
      duration: number,
      startDate: Date,
      endDate: Date
    ) => {
      logEvent(
        AnalyticsEvents.itemStatusChanged({
          isScheduled,
          screen: RouteNames.Home.Main.Drawer.Tabs.Chef._,
          status: statusToUpdate,
          itemId: item.uuid,
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
          requestExecutionTime: duration,
          ...params,
        })
      );

      onStatusChanged?.({
        orderId: item.order_uuid,
        itemId: item.uuid,
        mode: "single",
        startDate,
        endDate,
        status: statusToUpdate,
        screen: RouteNames.Home.Main.Drawer.Tabs.Chef._,
      });
    };

  return (
    <TouchableOpacity
      style={[
        styles.container,
        {
          marginTop: isSameOrder ? 0 : StyleSheet.hairlineWidth,
        },
      ]}
      activeOpacity={0.75}
      onPress={(event) => {
        navigationProps.onPress(event);
        logEvent(
          AnalyticsEvents.itemCellPressed({
            screen: RouteNames.Home.Main.Drawer.Tabs.Chef._,
          })
        );
      }}
    >
      <View
        style={[
          styles.content,
          {
            backgroundColor: hasUnacceptedNotifications
              ? colors.ui.notifications.unread
              : isOdd
              ? "white"
              : colors.alternateWhite,
          },
        ]}
      >
        <Delivery
          deliveryProviderLogoURI={
            order.channel.slug !== OrderDeliveryChannels.UNKNOWN &&
            order.channel.logo_cloudinary_url
          }
          orderKind={order.kind}
          isBlank={isSameOrder}
          latestPickupTime={
            !isScheduled &&
            [
              ItemPreparationStatuses.NOT_BEING_PREPARED,
              ItemPreparationStatuses.BEING_PREPARED,
            ].includes(item.status) &&
            order.latest_pickup_time
          }
        />
        <Status item={item} order={order} isScheduled={isScheduled} />
        <Items
          name={item.name}
          quantity={item.quantity}
          category={item.category}
          addons={item.addons}
          addonsType={item.addons_type}
          specialInstructions={item.special_instructions}
          internalNotes={order.internal_notes}
        />
        {hasUnacceptedNotifications ? (
          <HasUnacceptChangesLabel style={styles.hasUnacceptedChangesLabel} />
        ) : (
          shouldDisplayActionButton &&
          showCookButton && (
            <View style={{ padding: 10 }}>
              <ActionButtonItem
                includePrintButton={item.cookable}
                order={order}
                isScheduled={isScheduled}
                item={item}
                onPressFinish={getStatusChangeCallback({ print: false })}
              />
            </View>
          )
        )}
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 4,
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: "50%",
    marginTop: StyleSheet.hairlineWidth,
  },
  content: {
    backgroundColor: "white",
    flexDirection: "row",
    flexGrow: 1,
  },
  hasUnacceptedChangesLabel: {
    padding: 10,
  },
  errorText: {
    marginTop: 4,
    marginHorizontal: 4,
  },
});

export default withErrorBoundary(Item, {
  screen: RouteNames.Home.Main.Drawer.Tabs.Chef._,
  key: "chef-cell",
  renderFallback: (props: Props) => <ItemError {...props} />,
});
