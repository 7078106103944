import { useFonts as useFontsExpo } from "expo-font";

export function useFonts() {
  return useFontsExpo({
    "Roboto-Bold": require("./Roboto-Bold.ttf"),
    "Roboto-Medium": require("./Roboto-Medium.ttf"),
    "Roboto-Light": require("./Roboto-Light.ttf"),
    "Roboto-Black": require("./Roboto-Black.ttf"),
    "Roboto-Regular": require("./Roboto-Regular.ttf"),
  });
}
