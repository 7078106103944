import { useMemo, useState } from "react";
import { ScrollView, View } from "react-native";
import { Document, Page, pdfjs } from "react-pdf";
import { Container } from "./Container";
import { Spinner } from "./Spinner";
import { Props } from "./types";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;
export function PDFView({ uri, isOpen, onClose }: Props) {
  const [totalPage, setTotalPage] = useState(0);
  const [dimension, setDimension] = useState([0, 0]);
  const [pageDimension, setPageDimension] = useState([0, 0]);

  const size = useMemo(() => {
    const [width, height] = dimension;
    const [pageWidth, pageHeight] = pageDimension;

    if (!height || !pageHeight) return {};

    const windowRatio = width / height;
    const pageRatio = pageWidth / pageHeight;
    const size =
      windowRatio > pageRatio
        ? { width: (pageWidth * height) / pageHeight, height: height }
        : { width: width, height: (pageHeight * width) / pageWidth };

    return size;
  }, [pageDimension, dimension]);

  return (
    <Container isOpen={isOpen} onClose={onClose} totalPage={totalPage}>
      <ScrollView
        style={{ width: "100%", height: "100%" }}
        onLayout={({ nativeEvent }) => {
          const { width, height } = nativeEvent.layout;

          setDimension([width, height]);
        }}
      >
        {!!uri && (
          <Document
            file={uri}
            onLoadSuccess={async (pdf) => {
              const firstPage = await pdf.getPage(1);
              const [_x, _y, pageWidth, pageHeight] = firstPage.view;

              setPageDimension([pageWidth, pageHeight]);
              setTotalPage(pdf.numPages);
            }}
            loading={<Spinner />}
          >
            <View
              style={{
                alignItems: "center",
                width: "100%",
                height: "100%",
              }}
            >
              {!!totalPage &&
                Array.from({ length: totalPage }).map((_, index) => {
                  return (
                    <Page
                      key={`${index}-1`}
                      pageIndex={index}
                      renderTextLayer={false}
                      renderAnnotationLayer={false}
                      loading={<Spinner />}
                      {...size}
                    />
                  );
                })}
            </View>
          </Document>
        )}
      </ScrollView>
    </Container>
  );
}
