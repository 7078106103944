import { Button, Modal, Text, TextOverflow } from "components";
import colors from "config/colors";
import { isEmpty } from "lodash";
import { useMemo } from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { useSelector } from "react-redux";
import type { InternalNote } from "store/Orders/types";
import { SettingSelectors } from "store/Settings";
import { mergeInternalNotes } from "util/helpers";
import { useDisclosure } from "util/hooks";

type InternalNotesPopupProps = {
  isOpen: boolean;
  onClose: () => void;
  internalNotes: InternalNote[];
};

function InternalNotesPopup({
  isOpen,
  onClose,
  internalNotes,
}: InternalNotesPopupProps) {
  return (
    <Modal
      withCloseButton
      closeButtonColor="white"
      visible={isOpen}
      onRequestClose={onClose}
      contentContainerStyle={styles.internalNotesModalContent}
    >
      <View style={{ height: "100%" }}>
        <Text
          id={"overview.orderDetails.internalNotes.title"}
          style={styles.internalNotesModalTitle}
          color={"white"}
          font="bold"
          size={20}
        />
        <ScrollView style={styles.internalNotesModalScrollView}>
          {internalNotes.map(({ note }, index) => {
            return (
              <Text
                key={`${index}`}
                color={colors.darkRed}
                style={styles.internalNotesModalText}
                size={16}
              >
                {note}
              </Text>
            );
          })}
        </ScrollView>
      </View>
    </Modal>
  );
}

type InternalNotesProps = {
  internalNotes: InternalNote[];
};

function InternalNotes({ internalNotes }: InternalNotesProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const notesMerged = useMemo(
    () =>
      internalNotes && internalNotes.length > 0
        ? mergeInternalNotes(internalNotes)
        : null,
    [internalNotes]
  );

  return (
    <>
      <TextOverflow
        text={notesMerged}
        onPress={onOpen}
        style={styles.internalNotes}
        textColor={colors.darkRed}
        Overflow={
          <View>
            <Button
              titleId={"overview.orderDetails.internalNotes.buttonLabel"}
              variant="outline"
              innerColor={colors.darkRed}
              color="transparent"
              style={{ marginLeft: 10 }}
              innerStyle={{
                paddingVertical: 4,
              }}
            />
          </View>
        }
      />
      <InternalNotesPopup
        isOpen={isOpen}
        onClose={onClose}
        internalNotes={internalNotes}
      />
    </>
  );
}

type OrderNotesProps = {
  specialInstructions: string;
  internalNotes: InternalNote[];
};

export function OrderNotes({
  specialInstructions,
  internalNotes,
}: OrderNotesProps) {
  const { shouldDisplayInternalNotes } = useSelector(
    SettingSelectors.selectOrderDetailsInterfaceSettings
  );

  return (
    <>
      {!!specialInstructions && (
        <View style={styles.specialInstructions}>
          <Text color={colors.darkRed}>{specialInstructions}</Text>
        </View>
      )}
      {shouldDisplayInternalNotes && !isEmpty(internalNotes) && (
        <InternalNotes internalNotes={internalNotes} />
      )}
    </>
  );
}

const styles = StyleSheet.create({
  internalNotes: {
    alignItems: "center",
    backgroundColor: colors.veryLightRed2,
    flexDirection: "row",
    height: 40,
    justifyContent: "space-between",
    paddingHorizontal: 12,
  },
  internalNotesModalContent: {
    backgroundColor: "white",
    borderRadius: 0,
    bottom: 0,
    height: "100%",
    justifyContent: "flex-start",
    marginHorizontal: 0,
    marginVertical: 0,
    maxWidth: 400,
    position: "absolute",
    right: 0,
    top: 0,
    width: "30%",
  },
  internalNotesModalScrollView: {
    marginVertical: 12,
    paddingHorizontal: 12,
  },
  internalNotesModalText: {
    marginBottom: 12,
  },
  internalNotesModalTitle: {
    backgroundColor: colors.veryLightRed2,
    padding: 12,
  },
  specialInstructions: {
    backgroundColor: colors.veryLightRed,
    padding: 12,
  },
});
