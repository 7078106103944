import { HorizontalDivider, withErrorBoundary } from "components";
import { isEmpty } from "lodash";
import Setting from "modules/Settings/components/Setting";
import { RouteNames } from "navigation/linkingConfig";
import { Fragment, useMemo } from "react";
import { StyleSheet } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { SettingActions, SettingSelectors } from "store/Settings";
import type {
  ItemDetailsInterfaceSettingKey,
  ItemListInterfaceSettingKey,
  OrderDetailsInterfaceSettingKey,
  OrderListInterfaceSettingKey,
  SettingState,
} from "store/Settings/types";
import { UserSelectors } from "store/User";
import type { FeatureFlagKeys } from "store/User/types";
import { AnalyticsEvents, useLogEventCallback } from "util/analytics";
import { Section, SectionContainer } from "./components/Section";
import { SettingError } from "./components/SettingError";

const ItemListSection = () => {
  const dispatch = useDispatch();

  const logEvent = useLogEventCallback();

  const currentSettings = useSelector(
    SettingSelectors.selectItemListInterfaceSettings
  );
  const interfaceSettings = useSelector(UserSelectors.selectInterfaceSettings);

  const settingKeys: ItemListInterfaceSettingKey[] = useMemo(() => {
    const { item_list_card } = interfaceSettings ?? {};

    return [
      "shouldDisplayActionButton",
      item_list_card?.order_internal_notes && "shouldDisplayInternalNotes",
    ].filter(Boolean) as ItemListInterfaceSettingKey[];
  }, [interfaceSettings]);

  const toggle = (key: ItemListInterfaceSettingKey) => () => {
    const payload = {
      [key]: !currentSettings[key],
    };

    dispatch(SettingActions.setItemListInterface(payload));
    logEvent(AnalyticsEvents.interfaceSettingsUpdated(payload));
  };

  return (
    <SectionContainer titleKey="settings.interface.itemList.title">
      {settingKeys.map((key, index) => {
        return (
          <Fragment key={key}>
            <Section
              titleKey={`settings.interface.itemList.${key}.title`}
              subtitleKey={`settings.interface.itemList.${key}.subtitle`}
              isChecked={currentSettings[key]}
              onPressCheckbox={toggle(key)}
            />
            {index !== settingKeys.length - 1 && (
              <HorizontalDivider style={styles.divider} />
            )}
          </Fragment>
        );
      })}
    </SectionContainer>
  );
};

const ItemDetailsSection = () => {
  const dispatch = useDispatch();

  const logEvent = useLogEventCallback();

  const currentSettings = useSelector(
    SettingSelectors.selectItemDetailsInterfaceSettings
  );
  const interfaceSettings = useSelector(UserSelectors.selectInterfaceSettings);

  const settingKeys: ItemDetailsInterfaceSettingKey[] = useMemo(() => {
    const { item_details } = interfaceSettings ?? {};

    return [
      "shouldDisplayActionButton",
      item_details?.order_internal_notes && "shouldDisplayInternalNotes",
    ].filter(Boolean) as ItemDetailsInterfaceSettingKey[];
  }, [interfaceSettings]);

  const toggle = (key: ItemDetailsInterfaceSettingKey) => () => {
    const payload = {
      [key]: !currentSettings[key],
    };

    dispatch(SettingActions.setItemDetailsInterface(payload));
    logEvent(AnalyticsEvents.interfaceSettingsUpdated(payload));
  };

  return (
    <SectionContainer titleKey="settings.interface.itemDetails.title">
      {settingKeys.map((key, index) => {
        return (
          <Fragment key={key}>
            <Section
              titleKey={`settings.interface.itemDetails.${key}.title`}
              subtitleKey={`settings.interface.itemDetails.${key}.subtitle`}
              isChecked={currentSettings[key]}
              onPressCheckbox={toggle(key)}
            />
            {index !== settingKeys.length - 1 && (
              <HorizontalDivider style={styles.divider} />
            )}
          </Fragment>
        );
      })}
    </SectionContainer>
  );
};

const OrderListSection = () => {
  const dispatch = useDispatch();

  const logEvent = useLogEventCallback();

  const interfaceSettings = useSelector(UserSelectors.selectInterfaceSettings);
  const featureFlags: Record<FeatureFlagKeys, boolean> = useSelector(
    UserSelectors.selectFeatureFlags
  );

  const currentSettings = useSelector(
    SettingSelectors.selectOrderListInterfaceSettings
  );

  const orderListSettingKeys: OrderListInterfaceSettingKey[] = useMemo(() => {
    const { order_overview_card } = interfaceSettings ?? {};

    return [
      order_overview_card.customer_name &&
        featureFlags.display_customer_address &&
        "shouldDisplayCustomerName",
      order_overview_card.address_first_line &&
        featureFlags.display_customer_address &&
        "shouldDisplayCustomerAddress",
    ].filter(Boolean) as OrderListInterfaceSettingKey[];
  }, [featureFlags, interfaceSettings]);

  const toggle = (key: keyof SettingState["interface"]["orderList"]) => () => {
    const payload = {
      [key]: !currentSettings[key],
    };

    dispatch(SettingActions.setOrderListInterface(payload));
    logEvent(AnalyticsEvents.interfaceSettingsUpdated(payload));
  };

  if (isEmpty(orderListSettingKeys)) return null;

  return (
    <>
      <SectionContainer titleKey="settings.interface.orderList.title">
        {orderListSettingKeys.map((key, index) => {
          return (
            <Fragment key={key}>
              <Section
                titleKey={`settings.interface.orderList.${key}.title`}
                subtitleKey={`settings.interface.orderList.${key}.subtitle`}
                isChecked={currentSettings[key]}
                onPressCheckbox={toggle(key)}
              />
              {index !== orderListSettingKeys.length - 1 && (
                <HorizontalDivider style={styles.divider} />
              )}
            </Fragment>
          );
        })}
      </SectionContainer>
    </>
  );
};

const OrderDetailsSection = () => {
  const dispatch = useDispatch();

  const logEvent = useLogEventCallback();

  const interfaceSettings = useSelector(UserSelectors.selectInterfaceSettings);
  const featureFlags: Record<FeatureFlagKeys, boolean> = useSelector(
    UserSelectors.selectFeatureFlags
  );

  const currentSettings = useSelector(
    SettingSelectors.selectOrderDetailsInterfaceSettings
  );

  const settingKeys = useMemo(() => {
    const { order_details_card } = interfaceSettings ?? {};

    return [
      "shouldDisplayActionButtonHeader",
      "shouldDisplayActionButtonItems",
      order_details_card.order_internal_notes && "shouldDisplayInternalNotes",
      order_details_card.financial_details &&
        featureFlags.display_financial_info &&
        "shouldDisplayFinancialInfo",
      order_details_card.customer_details &&
        featureFlags.display_customer_address &&
        "shouldDisplayCustomerAddress",
      order_details_card.driver_details &&
        featureFlags.display_driver_details &&
        "shouldDisplayDriverDetails",
      order_details_card.item_pricing && "shouldDisplayItemPricing",
      order_details_card.order_channel && "shouldDisplayOrderChannel",
      order_details_card.kds_custom_fields && "shouldDisplayCustomFields",
    ].filter(Boolean) as OrderDetailsInterfaceSettingKey[];
  }, [featureFlags, interfaceSettings]);

  const toggle = (key: OrderDetailsInterfaceSettingKey) => () => {
    const payload = {
      [key]: !currentSettings[key],
    };

    dispatch(SettingActions.setOrderDetailsInterface(payload));
    logEvent(AnalyticsEvents.interfaceSettingsUpdated(payload));
  };

  return (
    <SectionContainer titleKey="settings.interface.orderDetails.title">
      {settingKeys.map((key, index) => {
        return (
          <Fragment key={key}>
            <Section
              titleKey={`settings.interface.orderDetails.${key}.title`}
              subtitleKey={`settings.interface.orderDetails.${key}.subtitle`}
              isChecked={currentSettings[key]}
              onPressCheckbox={toggle(key)}
            />
            {index !== settingKeys.length - 1 && (
              <HorizontalDivider style={styles.divider} />
            )}
          </Fragment>
        );
      })}
    </SectionContainer>
  );
};

const Interface = () => {
  return (
    <Setting
      titleKey="settings.interface.title"
      subTitleKey="settings.interface.subtitle"
      iconName="monitor-edit"
    >
      <ItemListSection />
      <ItemDetailsSection />
      <OrderListSection />
      <OrderDetailsSection />
    </Setting>
  );
};

const styles = StyleSheet.create({
  divider: {
    marginVertical: 20,
  },
});

export default withErrorBoundary(Interface, {
  screen: RouteNames.Home.Settings.Sounds,
  key: "settings-interface",
  fallback: <SettingError />,
});
