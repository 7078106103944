import { MaterialCommunityIcons } from "@expo/vector-icons";
import { FontFamilies } from "components";
import colors from "config/colors";
import { DEFAULT_ACTIVE_OPACITY } from "config/constants";
import { useRef, useState } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { Badge } from "react-native-paper";
import { useSelector } from "react-redux";
import { NotificationsSelectors } from "store/Notifications";
import { HEADER_BUTTON_SIZE, NotificationPopup } from "./NotificationsPopup";
import { useNotificationState } from "./NotificationStateContext";

export const NotificationButton = () => {
  const [position, setPosition] = useState<{ x?: number; y?: number }>({});

  const { isOpen, onOpen, onClose } = useNotificationState();

  const companyNotifications = useSelector(
    NotificationsSelectors.selectCompanyNotifications
  );
  const unreadOrderCount = useSelector(
    NotificationsSelectors.selectUnreadOrderNotificationCount
  );
  const unreadCompanyCount = useSelector(
    NotificationsSelectors.selectUnreadCompanyNotificationCount
  );
  const containerRef = useRef<View>();

  const hasUnreadOrder = unreadOrderCount > 0;
  const hasUnreadCompany = unreadCompanyCount > 0;

  return (
    <>
      <TouchableOpacity activeOpacity={DEFAULT_ACTIVE_OPACITY} onPress={onOpen}>
        <View
          onLayout={() => {
            containerRef?.current?.measure(
              (_x, _y, _itemWidth, _itemHeight, pageX, pageY) => {
                const offsetY = pageY;

                if (position.x !== pageX || position.y !== offsetY) {
                  setPosition({
                    x: pageX,
                    y: offsetY,
                  });
                }
              }
            );
          }}
          ref={containerRef}
          style={{
            alignItems: "center",
            justifyContent: "center",
            height: HEADER_BUTTON_SIZE,
            width: HEADER_BUTTON_SIZE,
            borderRadius: HEADER_BUTTON_SIZE,
            borderColor: "white",
            borderWidth: StyleSheet.hairlineWidth,
            ...(isOpen ? { backgroundColor: "white" } : {}),
          }}
        >
          <MaterialCommunityIcons
            name={hasUnreadOrder || hasUnreadCompany ? "bell-ring" : "bell"}
            color={isOpen ? colors.darkBlue2 : "white"}
            size={20}
            style={{ padding: 4 }}
          />
        </View>
        <Badge
          visible={hasUnreadOrder}
          size={16}
          style={{
            position: "absolute",
            left: -4,
            top: -4,
            fontFamily: FontFamilies.normal,
            color: colors.white,
            fontSize: 12,
            backgroundColor: colors.ui.notifications.order,
          }}
        >
          {unreadOrderCount}
        </Badge>
        <Badge
          visible={Boolean(companyNotifications.length)}
          size={16}
          style={{
            position: "absolute",
            right: -4,
            top: -4,
            fontFamily: FontFamilies.normal,
            color: colors.white,
            fontSize: 12,
            backgroundColor: hasUnreadCompany
              ? colors.ui.notifications.company
              : colors.lightGray,
          }}
        >
          C
        </Badge>
      </TouchableOpacity>
      <NotificationPopup
        isVisible={isOpen}
        onDismiss={onClose}
        left={position.x}
      />
    </>
  );
};
