import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "API/modules/User";
import { UserActions } from "store/User/actions";
import type { CurrentUser } from "store/User/types";

export const fetch = createAsyncThunk(
  UserActions.fetch.toString(),
  async (_, { signal }) => {
    const response = await API.fetch({ signal });

    return response;
  }
);

export const update = createAsyncThunk(
  UserActions.update.toString(),
  async (payload: CurrentUser, { signal }) => {
    const response = await API.patch(payload, { signal });

    return response;
  }
);
