import { HorizontalDivider, Spacer } from "components";
import { ScrollView, StyleSheet } from "react-native";
import { useSelector } from "react-redux";
import { AppSelectors } from "store/App";
import { FilterSection } from "./FilterSection";
import { InfoSection } from "./InfoSection";
import { MenuSection } from "./MenuSection";
import { MenuSectionMinimized } from "./MenuSectionMinimized";
import { TabBarSection } from "./TabBarSection";

export const SideBar = () => {
  const isSidebarMinimized = useSelector(AppSelectors.selectIsSidebarMinimized);

  return (
    <ScrollView
      style={styles.container}
      contentContainerStyle={styles.contentContainer}
      scrollEnabled
    >
      {!isSidebarMinimized ? <MenuSection /> : <MenuSectionMinimized />}
      <TabBarSection />
      <Spacer />
      <FilterSection />
      <HorizontalDivider style={{ marginVertical: 16, opacity: 0.8 }} />
      <InfoSection />
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    marginVertical: 12,
  },
  contentContainer: {
    flexGrow: 1,
  },
});
