import NetInfo from "@react-native-community/netinfo";
import { apiInstance } from "API/init";
import type {
  RequestCallback,
  ResponseHandler,
  TransformData,
} from "API/types";
import { AxiosResponse } from "axios";
import { Platform } from "react-native";
import { RequestErrors } from "store/types";

export function convertHostNameToBaseUrl(hostname = "cds.captain.ai") {
  return `https://${hostname}/kds-app-api`;
}

export const DEFAULT_BASE_URL = convertHostNameToBaseUrl();

export function setBaseURL(newBase: string) {
  apiInstance.defaults.baseURL = newBase;
}

export function setAuthenticationHeaders(
  email?: string,
  token?: string,
  accountUUID?: string
) {
  apiInstance.defaults.headers.common["x-authentication-token"] = token;
  apiInstance.defaults.headers.common["x-authentication-email"] = email;
  apiInstance.defaults.headers.common["x-account-uuid"] = accountUUID;
}

export function setDeviceHeaders(deviceUUID?: string) {
  apiInstance.defaults.headers.common["x-device-uuid"] = deviceUUID;
}

const defaultResponseHandler = (response: AxiosResponse) => {
  if (response.data.error === "unauthorized") {
    return { error: RequestErrors.UNAUTHORIZED, statusCode: 401 };
  }

  if (response.status >= 500) {
    return {
      error: response.data?.error || RequestErrors.SERVER_ERROR,
      statusCode: response.status,
    };
  }

  switch (response.status) {
    case 200:
    case 201:
    case 304: {
      return { data: response.data };
    }
    default: {
      return {
        error: response.data?.error || RequestErrors.BAD_STATUS,
        statusCode: response.status,
      };
    }
  }
};

export const executeRequest = async (
  requestCallback: RequestCallback,
  transformData?: TransformData,
  handleResponse?: ResponseHandler
): Promise<any | Error> => {
  if (Platform.OS !== "web") {
    const { isConnected } = await NetInfo.fetch();
    if (!isConnected) {
      return { error: RequestErrors.NO_CONNECTION };
    }
  }

  const handle = handleResponse
    ? (res) => handleResponse(res, defaultResponseHandler)
    : defaultResponseHandler;

  const response = await requestCallback(apiInstance);
  const { data, error, statusCode } = handle(response);

  return error
    ? { error, statusCode }
    : transformData
    ? transformData(data)
    : data;
};
