import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  buttonGroup: {
    flexDirection: "row",
    flexGrow: 1,
    width: "100%",
  },
  container: {
    backgroundColor: "#ececec",
    justifyContent: "center",
    alignItems: "center",
    flexGrow: 1,
    padding: 4,
    borderRadius: 5,
    marginLeft: 8,
    width: 84,
    height: 84,
  },
  snackbar: {
    alignSelf: "flex-end",
    width: 200,
    zIndex: 1000,
    marginRight: 16,
    backgroundColor: "white",
  },
  snackbarText: {
    backgroundColor: "white",
  },
});
