import { Text } from "components";
import colors from "config/colors";
import _ from "lodash";
import Addons from "modules/ChefScene/components/Addons";
import {
  Arrow,
  Change,
  ControlButtons,
  NoContentPlaceholder,
  NotificationContainer,
} from "modules/Notifications/components";
import { getLabelKeyForNotificationMetaKey } from "modules/Notifications/helpers";
import { useNotificationCallbacks } from "modules/Notifications/hooks";
import { RouteNames } from "navigation/linkingConfig";
import { FlatList, View } from "react-native";
import { useSelector } from "react-redux";
import { NotificationsSelectors } from "store/Notifications";
import { ChangeNotificationTypesList } from "store/Notifications/enums";
import {
  AnalyticsEvents,
  NotificationEventActions,
  useLogEventCallback,
} from "util/analytics";

const QuantityDiff = (props) => {
  const { previous, current } = props;
  return (
    <Change
      titleKey={getLabelKeyForNotificationMetaKey("quantity")}
      current={current}
      previous={previous}
      renderItem={(quantity) => (
        <Text size={12} font={"bold"} color={colors.text.dark}>
          {quantity}
        </Text>
      )}
    />
  );
};

const SpecialInstructionsDiff = (props) => {
  const { previous, current } = props;
  return (
    <Change
      titleKey={getLabelKeyForNotificationMetaKey("special_instructions")}
      current={current}
      previous={previous}
      renderItem={(specialInstructions) => (
        <Text size={12} font={"medium"} color={colors.text.dark}>
          {specialInstructions}
        </Text>
      )}
    />
  );
};

const AddonsDiff = (props) => {
  const {
    addonsPrevious,
    addonsCurrent,
    addonsTypePrevious,
    addonsTypeCurrent,
  } = props;

  return (
    <Change
      titleKey={getLabelKeyForNotificationMetaKey("addons")}
      isRenderingChange={false}
    >
      {_.isEmpty(addonsPrevious) ? (
        <NoContentPlaceholder />
      ) : (
        <Addons addons={addonsPrevious} addonsType={addonsTypePrevious} />
      )}
      <Arrow />
      {_.isEmpty(addonsCurrent) ? (
        <NoContentPlaceholder />
      ) : (
        <Addons addons={addonsCurrent} addonsType={addonsTypeCurrent} />
      )}
    </Change>
  );
};

const Meta = (props) => {
  const { meta, addonsType } = props;

  return (
    <View>
      {!!meta.quantity && (
        <QuantityDiff
          previous={meta.quantity.previously_was}
          current={meta.quantity.current}
        />
      )}
      {!!meta.special_instructions && (
        <SpecialInstructionsDiff
          previous={meta.special_instructions.previously_was}
          current={meta.special_instructions.current}
        />
      )}
      {!!meta.addons && (
        <AddonsDiff
          addonsPrevious={meta.addons.previously_was}
          addonsCurrent={meta.addons.current}
          addonsTypePrevious={meta.addons_type?.previously_was ?? addonsType}
          addonsTypeCurrent={meta.addons_type?.current ?? addonsType}
        />
      )}
    </View>
  );
};

const Notification = (props) => {
  const { notification, addonsType, isLast, onPressAccept, onPress } = props;
  const meta = [];
  for (const key in notification.meta) {
    if (notification.meta.hasOwnProperty(key)) {
      meta.push({
        key,
        ...notification.meta[key],
      });
    }
  }

  return (
    <NotificationContainer
      notification={notification}
      isLast={isLast}
      onPressAccept={onPressAccept}
      onPress={onPress}
    >
      {ChangeNotificationTypesList.includes(notification.notification_type) && (
        <Meta meta={notification.meta} addonsType={addonsType} />
      )}
    </NotificationContainer>
  );
};

const NotificationsLog = (props) => {
  const { itemUUID, addonsType } = props;

  const logEvent = useLogEventCallback();

  const notifications = useSelector((state) =>
    // @ts-ignore
    NotificationsSelectors.selectForOrderItem(state, itemUUID)
  );

  const {
    hasUnaccepted,
    onViewableItemsChanged,
    onPressMarkAllAsRead,
    onPressMarkAllAsReadAndAccepted,
  } = useNotificationCallbacks(notifications);

  if (!notifications?.length) {
    return null;
  }

  return (
    <View style={{ paddingVertical: 12 }}>
      <FlatList
        data={notifications}
        renderItem={({ item, index }) => {
          return (
            <Notification
              notification={item}
              addonsType={addonsType}
              isLast={index === notifications.length - 1}
              key={item.uuid}
              onPressAccept={() => {
                logEvent(
                  AnalyticsEvents.notificationChanged({
                    action: NotificationEventActions.accept,
                    screen: RouteNames.Home.Main.ItemDetails,
                    isIndividualButton: true,
                  })
                );
              }}
              onPress={() => {
                logEvent(
                  AnalyticsEvents.notificationPressed({
                    screen: RouteNames.Home.Main.ItemDetails,
                    isOnAlert: false,
                  })
                );
              }}
            />
          );
        }}
        keyExtractor={(item) => item.uuid}
        onViewableItemsChanged={onViewableItemsChanged}
        ListHeaderComponent={
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              paddingHorizontal: 12,
            }}
          >
            <Text
              size={18}
              font={"bold"}
              style={{ marginVertical: 10 }}
              id={"chef.itemDetails.notifications.title"}
            />
            <ControlButtons
              hasUnread={false}
              hasUnaccepted={hasUnaccepted}
              onPressMarkAllAsRead={() => {
                onPressMarkAllAsRead();

                logEvent(
                  AnalyticsEvents.notificationChanged({
                    action: NotificationEventActions.read,
                    screen: RouteNames.Home.Main.ItemDetails,
                    isIndividualButton: false,
                  })
                );
              }}
              onPressMarkAllAsReadAndAccepted={() => {
                onPressMarkAllAsReadAndAccepted();

                logEvent(
                  AnalyticsEvents.notificationChanged({
                    action: NotificationEventActions.accept,
                    screen: RouteNames.Home.Main.ItemDetails,
                    isIndividualButton: false,
                  })
                );
              }}
            />
          </View>
        }
      />
    </View>
  );
};

export default NotificationsLog;
