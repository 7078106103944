const base = {
  primary: "#d36c55",
  darkBlue: "#202330",
  darkBlue2: "#202129",
  darkBlue3: "#161523",
  blue: "#292C40",
  blue2: "#282d41",
  lightBlue: "#363D57",
  lightBlue2: "#3b3a4a",
  veryLightBlue: "#3ac3ee",
  veryLightGray: "#f0f0f0",
  lightGray: "#9a9a9a",
  gray: "#515A5A",
  darkGray: "#2b2b2b",
  darkGrayAlpha: "#2b2b2b88",
  orange: "#E88729",
  green: "#31BA87",
  brightGreen: "#8cd269",
  lightGreen: "#2ab197",
  beige: "#DDD1D3",
  brightBeige: "#fff8bf",

  red: "#A73F49",
  lightRed: "#D2716A",
  darkRed: "#914047",
  veryLightRed: "#D5C7C3",
  veryLightRed2: "#d8b6b6",
  error: "#CC2222",
  main: "#137F8A",
  white: "#FFFFFF",
  whiteAlpha: "#FFFFFF88",
  alternateWhite: "#f5f5f5",

  text: {
    dark: "#545452",
  },
};

export default {
  ...base,

  ui: {
    notifications: {
      unread: "#fff8bf",
      order: "#FF1111",
      company: "#1195FF",
      low: base.lightGray,
      normal: "#25E100",
      high: "#FFC200",
      critical: "#FF0000",
    },
    statuses: {
      scheduled: "#02b596",
      active: "#d97f0d",
      cooking: "#74d45a",
      prepared: "#00c7f3",
      cancelled: "gray",
    },
    deliveryStatuses: {
      unassigned: base.orange,
      assigned: base.darkGray,
      accepted: base.darkGray,
      collected: base.darkGray,
      dropped: base.darkGray,
      failed: base.red,
      cancelled: base.lightGray,
    },
    startPreparationStatuses: {
      late: base.red,
      riskLate: base.orange,
      now: "#74d45a",
      early: base.gray,
    },
  },
};
