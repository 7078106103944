import colors from "config/colors";
import { ActivityIndicator } from "react-native";

export function Spinner() {
  return (
    <ActivityIndicator
      size={"large"}
      color={colors.primary}
      style={{ alignSelf: "center", marginTop: 100 }}
    />
  );
}
