import { View } from "react-native";
import Active from "./Active";
import { useSetTitleEffect } from "./utils";

export const ChefScene = () => {
  useSetTitleEffect();

  return (
    <View style={{ flex: 1 }}>
      <Active />
    </View>
  );
};

// const tabRoutes = [
//   { key: TAB_BARS.newAndCooking.value },
//   { key: TAB_BARS.scheduled.value },
//   { key: TAB_BARS.prepared.value },
// ]

// const renderScene = SceneMap({
//   [TAB_BARS.newAndCooking.value]: Active,
//   [TAB_BARS.scheduled.value]: Scheduled,
//   [TAB_BARS.prepared.value]: Completed,
// })

// const Navigator = () => {
//   const dispatch = useDispatch()
//   const { width } = useWindowDimensions()

//   const selectedTabBar = useSelector(AppSelectors.selectCurrentTabBar)

//   const navigationState = useMemo(() => {
//     const tabIndex =
//       TAB_BARS_LIST.findIndex((tabBar) => tabBar.value === selectedTabBar) ?? 0

//     const navigationState = { index: tabIndex, routes: tabRoutes }

//     return navigationState
//   }, [selectedTabBar])

//   const onIndexChange = (index: number) => {
//     dispatch(AppActions.setTabBar(TAB_BARS_LIST[index]))
//   }

//   useSetTitleEffect()

//   return (
//     <TabView
//       navigationState={navigationState}
//       onIndexChange={onIndexChange}
//       renderScene={renderScene}
//       initialLayout={{
//         width,
//       }}
//       renderTabBar={() => null}
//       style={{
//         backgroundColor: colors.lightBlue2,
//       }}
//     />
//   )
// }
