import { Payload } from "store/Analytics/types";

const createEvent =
  (scope: string, name: string) =>
  (payload?: unknown): Payload => ({
    eventName: `${scope}: ${name}`,
    eventPayload: payload,
  });

export const NotificationEventActions = {
  accept: "accept",
  read: "read",
  dismiss: "dismiss",
};

const Scopes = {
  CHEF: "chef screen",
  OVERVIEW: "overview screen",
  ITEM_DETAILS: "item details view",
  ITEM: "item",
  ORDER: "order",
  NOTIFICATION: "notification",
  SETTINGS: "settings",
  APP_HEALTH: "app health",
};

const itemStatusChanged = createEvent(Scopes.ITEM, "status changed");
const itemStatusChangedUiUpdate = createEvent(
  Scopes.ITEM,
  "status changed ui update"
);
const itemCellPressed = createEvent(Scopes.ITEM, "cell pressed");
const reprintButtonPressed = createEvent(Scopes.ITEM, "reprint button pressed");

const orderStatusChanged = createEvent(Scopes.ORDER, "status changed");
const orderCellPressed = createEvent(Scopes.ORDER, "cell pressed");

const chefScreenEntered = createEvent(Scopes.CHEF, "entered");
const tabChangedInChefScreen = createEvent(Scopes.CHEF, "tab changed");
const overviewScreenEntered = createEvent(Scopes.OVERVIEW, "entered");
const tabChangedInOverviewScreen = createEvent(Scopes.OVERVIEW, "tab changed");

const itemDetailsScreenEntered = createEvent(Scopes.ITEM_DETAILS, "entered");

const notificationsViewEntered = createEvent(Scopes.NOTIFICATION, "entered");
const notificationChanged = createEvent(Scopes.NOTIFICATION, "item changed");
const notificationPressed = createEvent(Scopes.NOTIFICATION, "cell press");
const notificationCompanyFileView = createEvent(
  Scopes.NOTIFICATION,
  "view attached file"
);
const notificationCompanyChangeFilter = createEvent(
  Scopes.NOTIFICATION,
  "company filter updated"
);

const settingsScreenEntered = createEvent(Scopes.SETTINGS, "entered");
const languageChanged = createEvent(Scopes.SETTINGS, "language changed");
const categoriesFilterUpdated = createEvent(
  Scopes.SETTINGS,
  "categories filter updated"
);
const orderKindsFilterUpdated = createEvent(
  Scopes.SETTINGS,
  "order types filter updated"
);
const orderSortingUpdated = createEvent(
  Scopes.SETTINGS,
  "order sorting updated"
);
const interfaceSettingsUpdated = createEvent(
  Scopes.SETTINGS,
  "interface settings updated"
);
const estimatedPreparationTimeSettingUpdate = createEvent(
  Scopes.SETTINGS,
  "estimated preparation time setting updated"
);
const delayBeforeStartSettingUpdate = createEvent(
  Scopes.SETTINGS,
  "delay before start setting updated"
);
const countDownBlinkingThresholdSettingUpdate = createEvent(
  Scopes.SETTINGS,
  "count down blinking threshold before start setting updated"
);

const crashScreenEntered = createEvent(Scopes.APP_HEALTH, "entered");
const fixAppPressed = createEvent(Scopes.APP_HEALTH, "fix app pressed");
const fixAppReload = createEvent(Scopes.APP_HEALTH, "user reloads the app");

export default {
  itemStatusChanged,
  itemStatusChangedUiUpdate,
  itemCellPressed,

  orderStatusChanged,
  orderCellPressed,

  chefScreenEntered,
  tabChangedInChefScreen,

  overviewScreenEntered,
  tabChangedInOverviewScreen,

  itemDetailsScreenEntered,
  reprintButtonPressed,

  notificationsViewEntered,
  notificationChanged,
  notificationPressed,
  notificationCompanyFileView,
  notificationCompanyChangeFilter,

  settingsScreenEntered,
  languageChanged,
  categoriesFilterUpdated,
  orderKindsFilterUpdated,
  orderSortingUpdated,
  interfaceSettingsUpdated,
  estimatedPreparationTimeSettingUpdate,
  delayBeforeStartSettingUpdate,
  countDownBlinkingThresholdSettingUpdate,

  crashScreenEntered,
  fixAppPressed,
  fixAppReload,
};
