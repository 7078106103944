import AsyncStorage from "@react-native-async-storage/async-storage";

export const userMigrations = {
  2: async (userState: UserState): Promise<UserState> => {
    try {
      const userAsJson = await AsyncStorage.getItem("persist:user");
      const user = JSON.parse(userAsJson);

      const savedAccount = user.account
        ? (JSON.parse(user.account) as Account)
        : null;
      const accountUUID: Account["uuid"] = savedAccount?.uuid;

      return {
        ...userState,
        selectedAccountUUID: accountUUID,
        accounts: savedAccount
          ? { [accountUUID]: savedAccount }
          : userState.accounts,
      };
    } catch (e) {}

    return userState;
  },
};
