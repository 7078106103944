import { FlashList } from "@shopify/flash-list";
import colors from "config/colors";
import { ControlButtons } from "modules/Notifications/components";
import { useNotificationCallbacks } from "modules/Notifications/hooks";
import { RouteNames } from "navigation/linkingConfig";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";
import { useSelector } from "react-redux";
import { NotificationsSelectors } from "store/Notifications";
import {
  AnalyticsEvents,
  NotificationEventActions,
  useLogEventCallback,
} from "util/analytics";
import { ListHeader } from "../ListHeader";
import { Notification } from "../Notification";
import { NoNotifications } from "../Placeholder";

type ListHeaderProps = {
  hasUnread?: boolean;
  hasUnaccepted?: boolean;
  onPressMarkAllAsRead: () => void | Promise<void>;
  onPressMarkAllAsReadAndAccepted: () => void | Promise<void>;
};

function ListHeaderComponent({
  hasUnread,
  hasUnaccepted,
  onPressMarkAllAsReadAndAccepted,
  onPressMarkAllAsRead,
}: ListHeaderProps) {
  const { t } = useTranslation();
  const logEvent = useLogEventCallback();

  return (
    <ListHeader
      title={t("notifications.orders.title")}
      color={colors.ui.notifications.order}
    >
      {(hasUnread || hasUnaccepted) && (
        <ControlButtons
          hasUnread={hasUnread}
          hasUnaccepted={hasUnaccepted}
          onPressMarkAllAsRead={async () => {
            await onPressMarkAllAsRead();

            logEvent(
              AnalyticsEvents.notificationChanged({
                action: NotificationEventActions.read,
                screen: RouteNames.Home.Main,
                isIndividualButton: false,
              })
            );
          }}
          onPressMarkAllAsReadAndAccepted={async () => {
            await onPressMarkAllAsReadAndAccepted();

            logEvent(
              AnalyticsEvents.notificationChanged({
                action: NotificationEventActions.accept,
                screen: RouteNames.Home.Main,
                isIndividualButton: false,
              })
            );
          }}
        />
      )}
    </ListHeader>
  );
}

type Props = {
  onDismiss: () => void;
};

export function OrderNotifications({ onDismiss }: Props) {
  const notifications = useSelector(
    NotificationsSelectors.selectOrderNotifications
  );

  const logEvent = useLogEventCallback();

  const {
    hasUnaccepted,
    onPressMarkAllAsRead,
    onPressMarkAllAsReadAndAccepted,
    onViewableItemsChanged,
  } = useNotificationCallbacks(notifications);

  const unreadOrderCount = useSelector(
    NotificationsSelectors.selectUnreadOrderNotificationCount
  );

  const hasUnread = unreadOrderCount > 0;

  return (
    <View style={{ width: 400 }}>
      <ListHeaderComponent
        hasUnread={hasUnread}
        hasUnaccepted={hasUnaccepted}
        onPressMarkAllAsRead={onPressMarkAllAsRead}
        onPressMarkAllAsReadAndAccepted={onPressMarkAllAsReadAndAccepted}
      />
      <FlashList
        estimatedItemSize={100}
        data={notifications}
        contentContainerStyle={{
          paddingHorizontal: 8,
        }}
        renderItem={({ item }) => (
          <Notification
            notification={item}
            onPressAccept={() => {
              logEvent(
                AnalyticsEvents.notificationChanged({
                  action: NotificationEventActions.accept,
                  screen: RouteNames.Home.Main._,
                  isIndividualButton: true,
                })
              );
            }}
            onPress={() => {
              onDismiss();
              logEvent(
                AnalyticsEvents.notificationPressed({
                  screen: RouteNames.Home.Main._,
                  isOnAlert: false,
                })
              );
            }}
          />
        )}
        keyExtractor={(item) => item.uuid}
        ListEmptyComponent={NoNotifications}
        onViewableItemsChanged={onViewableItemsChanged}
        ItemSeparatorComponent={() => (
          <View
            style={{
              borderBottomWidth: StyleSheet.hairlineWidth,
              borderBottomColor: "#efefef",
            }}
          />
        )}
      />
    </View>
  );
}
