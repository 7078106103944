import { createNativeStackNavigator } from "@react-navigation/native-stack";
import colors from "config/colors";
import { AlertsContainer } from "modules/Alerts";
import { ChefScene } from "modules/ChefScene";
import ItemDetailView from "modules/ChefScene/ItemDetailView";
import OverviewNavigator from "modules/OverviewScene";
import { forwardRef, useCallback, useEffect, useMemo, useState } from "react";
import type { ForwardedRef } from "react";
import { View } from "react-native";
import { useSelector } from "react-redux";
import { AppSelectors } from "store/App";
import { AnalyticsEvents, useLogEventCallback } from "util/analytics";
import { createCtx } from "util/createCtx";
import { SideBar } from "./components/SideBar";
import { HideSideBarButton } from "./components/SideBar/HideSideBarButton";
import { RouteNames } from "./linkingConfig";

const Stack = createNativeStackNavigator();

export type MainScreen = "chef" | "overview";

export const [useScreenContext, ScreenProvider] = createCtx<{
  screen: MainScreen;
  updateScreen: (screen: MainScreen) => void;
}>();

const StackNavigator = () => {
  const [screen, setScreen] = useState<MainScreen>("overview");

  const screenProviderValue = useMemo(
    () => ({ screen, updateScreen: setScreen }),
    [screen]
  );

  return (
    <ScreenProvider value={screenProviderValue}>
      <View style={{ flex: 1 }}>
        <AlertsContainer />
        <Stack.Navigator
          screenOptions={{
            headerShown: false,
            contentStyle: {
              backgroundColor: colors.lightBlue2,
            },
          }}
          initialRouteName={RouteNames.Home.Main.Drawer._}
        >
          <Stack.Screen
            name={RouteNames.Home.Main.Drawer._}
            component={MainView}
          />
          <Stack.Screen
            name={RouteNames.Home.Main.ItemDetails}
            component={ItemDetailView}
            options={{
              presentation: "transparentModal",
              contentStyle: { backgroundColor: "rgba(59,58,74,0.85)" },
            }}
          />
        </Stack.Navigator>
      </View>
    </ScreenProvider>
  );
};

const MainView = () => {
  const logEvent = useLogEventCallback();

  const { screen } = useScreenContext();

  useEffect(() => {
    switch (screen) {
      case "chef":
        logEvent(AnalyticsEvents.chefScreenEntered());
        break;
      case "overview":
        logEvent(AnalyticsEvents.overviewScreenEntered());
        break;
    }
  }, [screen]);

  const isSidebarMinimized = useSelector(AppSelectors.selectIsSidebarMinimized);

  return (
    <>
      <View style={{ flexDirection: "row", width: "100%", height: "100%" }}>
        <View
          style={{
            width: isSidebarMinimized ? 80 : 200,
            borderRightWidth: 0,
            backgroundColor: colors.darkBlue,
          }}
        >
          <SideBar />
        </View>
        <View style={{ backgroundColor: colors.lightBlue2, flex: 1 }}>
          <View style={{ width: "100%", height: "100%" }}>
            {screen === "chef" ? <ChefScene /> : <OverviewNavigator />}
          </View>
          <HideSideBarButton />
        </View>
      </View>
    </>
  );
};

export default StackNavigator;
