import { FlashList } from "@shopify/flash-list";
import colorsConfig from "config/colors";
import { isEmpty } from "lodash";
import { ItemSectionHeader } from "modules/ChefScene/components";
import { EmptyList } from "modules/ChefScene/components/EmptyList";
import { useTranslation } from "react-i18next";
import { SectionList, StyleSheet, View } from "react-native";
import { useSelector } from "react-redux";
import useUpdateEffect from "react-use/lib/useUpdateEffect";
import { AppActions, AppSelectors } from "store/App";
import { TAB_BARS_LIST } from "store/App/constants";
import { useAppDispatch } from "store/hooks";
import { OrderSelectors } from "store/Orders";
import { AnalyticsEvents, useLogEventCallback } from "util/analytics";
import { useFormatDateTime } from "util/helpers";
import { usePullToRefreshOrder } from "util/hooks";
import { useOverviewNavigationContext } from "../OverviewNavigationContext";
import { NavItem } from "./Lists";

export const Drawer = () => {
  const { navigationState } = useOverviewNavigationContext();

  const logEvent = useLogEventCallback();

  const routeState = navigationState.routes[navigationState.index];
  const formatDateTime = useFormatDateTime();

  useUpdateEffect(() => {
    logEvent(
      AnalyticsEvents.tabChangedInOverviewScreen({
        tab: TAB_BARS_LIST[navigationState.index].value,
      })
    );
  }, [navigationState.index]);

  const orders = useSelector((state) => {
    if (routeState.key === "cooking") {
      return OrderSelectors.selectBeingPrepared(state);
    } else if (routeState.key === "new") {
      return OrderSelectors.selectNotBeingPrepared(state);
    } else if (routeState.key === "prepared") {
      return OrderSelectors.selectPrepared(state);
    } else if (routeState.key === "scheduled") {
      const [today, rest] = OrderSelectors.selectScheduledGrouped(state);

      return [
        ...(!isEmpty(today)
          ? [{ data: today, date: new Date(), isToday: true }]
          : []),
        ...rest.map((group) => ({
          data: group.items,
          date: group.date,
          isToday: false,
        })),
      ];
    }
    return undefined;
  });

  const dispatch = useAppDispatch();

  useUpdateEffect(() => {
    dispatch((action, getState) => {
      const state = getState();
      const selectedOrderUUID = AppSelectors.selectOrderUUID(state);

      const flattenedOrders =
        routeState.key === "scheduled"
          ? orders.flatMap((order) => order.data)
          : orders;

      const selectedOrderIndex = flattenedOrders.findIndex(
        (order) => order.uuid === selectedOrderUUID
      );

      if (selectedOrderIndex === -1) {
        action(
          AppActions.setSelectedOrderUUID({
            orderUUID: flattenedOrders?.[0]?.uuid,
          })
        );
      }
    });
  }, [orders]);

  const [isFetching, onRefresh] = usePullToRefreshOrder();
  const { t } = useTranslation();

  const headerOptions: { text: string; color: string } | undefined = (() => {
    switch (routeState.key) {
      case "cooking":
        return {
          text: t("overview.drawer.cookingOrders"),
          color: colorsConfig.ui.statuses.cooking,
        };
      case "new":
        return {
          text: t("overview.drawer.newOrders"),
          color: colorsConfig.ui.statuses.active,
        };
      case "prepared":
        return {
          text: t("overview.drawer.preparedOrders"),
          color: colorsConfig.ui.statuses.prepared,
        };
      case "scheduled":
        return undefined;
    }
  })();

  return (
    <View style={{ backgroundColor: colorsConfig.lightBlue2, height: "100%" }}>
      <View style={styles.container}>
        {headerOptions && (
          <ItemSectionHeader
            label={headerOptions.text}
            color={headerOptions.color}
          />
        )}
        {!isEmpty(orders) ? (
          routeState.key === "scheduled" ? (
            <SectionList
              sections={orders}
              renderItem={({ item }) => {
                return (
                  <NavItem
                    order={item}
                    tab={routeState.key}
                    isScheduled={true}
                  />
                );
              }}
              renderSectionHeader={({ section }) => {
                return (
                  <ItemSectionHeader
                    label={
                      section.isToday
                        ? t("common.today")
                        : formatDateTime(section.date, {
                            weekday: "long",
                            day: "numeric",
                            month: "long",
                          })
                    }
                    color={colorsConfig.ui.statuses.scheduled}
                  />
                );
              }}
              onRefresh={onRefresh}
              refreshing={isFetching}
              style={styles.sectionList}
            />
          ) : (
            <FlashList
              estimatedItemSize={100}
              data={orders}
              onRefresh={onRefresh}
              refreshing={isFetching}
              renderItem={({ item }) => (
                <NavItem order={item} tab={routeState.key} />
              )}
              keyExtractor={(item) => item.uuid}
            />
          )
        ) : (
          <EmptyList onRefresh={onRefresh} isFetching={isFetching} />
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    flexShrink: 1,
    marginHorizontal: 16,
    marginTop: 12,
    marginBottom: 12,
  },
  flastListContainer: {
    flex: 1,
  },
  sectionList: {
    flex: 1,
    paddingVertical: 4,
  },
});
