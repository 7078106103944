import { NotificationAlert } from "modules/Alerts/alerts";
import { AlertTypes } from "modules/Alerts/constants";
import React, { useEffect } from "react";
import { View } from "react-native";

type Props = {
  alert: Alert;
  onDismiss(): void;
};

export function Alert(props: Props) {
  const { alert, onDismiss } = props;

  useEffect(() => {
    const { options } = alert;

    if (
      options.auto_dismiss_timeout_ms &&
      options.auto_dismiss_timeout_ms > 0
    ) {
      const timer = setTimeout(() => {
        onDismiss();
      }, options.auto_dismiss_timeout_ms);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [alert, onDismiss]);

  return (
    <View
      style={{
        backgroundColor: "#fff",
        borderRadius: 15,
        marginVertical: 10,
        marginBottom: 10,
        marginHorizontal: 10,
        overflow: "hidden",
      }}
    >
      {alert.type === AlertTypes.NOTIFICATION ? (
        <NotificationAlert
          notification={alert.meta.notification}
          onDismiss={onDismiss}
          options={alert.options}
        />
      ) : null}
    </View>
  );
}
