import { MaterialCommunityIcons } from "@expo/vector-icons";
import { Button } from "components";
import colors from "config/colors";
import { StyleSheet } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { AppActions, AppSelectors } from "store/App";

export const HideSideBarButton = () => {
  const dispatch = useDispatch();
  const isSidebarMinimized = useSelector(AppSelectors.selectIsSidebarMinimized);

  return (
    <Button
      onPress={() => {
        dispatch(AppActions.setDrawerSize(!isSidebarMinimized));
      }}
      icon={
        <MaterialCommunityIcons
          name={isSidebarMinimized ? "chevron-right" : "chevron-left"}
          color="#FFF"
          size={20}
        />
      }
      color={colors.darkBlue3}
      size={12}
      hitSlop={12}
      style={styles.item}
      innerStyle={styles.itemInner}
      iconStyle={{ padding: 0 }}
      font="medium"
    />
  );
};

const styles = StyleSheet.create({
  item: {
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.darkBlue,
    position: "absolute",
    left: 0,
    bottom: 40,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  itemInner: {
    paddingVertical: 0,
    paddingHorizontal: 0,
    height: 80,
    width: 16,
  },
});
