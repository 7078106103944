import { Text } from "components";
import colors from "config/colors";
import { DateTime } from "luxon";
import {
  Change,
  ControlButtons,
  NotificationContainer,
} from "modules/Notifications/components";
import {
  getLabelKeyForNotificationMetaKey,
  useNotificationLinkProps,
} from "modules/Notifications/helpers";
import { useNotificationCallbacks } from "modules/Notifications/hooks";
import { RouteNames } from "navigation/linkingConfig";
import { FlatList, View } from "react-native";
import { useSelector } from "react-redux";
import { NotificationsSelectors } from "store/Notifications";
import { NotificationTypes } from "store/Notifications/enums";
import { AppNotification } from "store/Notifications/types";
import { OrderSelectors } from "store/Orders";
import { MaterialCommunityIconName } from "types/icons";
import {
  AnalyticsEvents,
  NotificationEventActions,
  useLogEventCallback,
} from "util/analytics";
import { getLabelKeyForOrderKind, useFormatDateTime } from "util/helpers";

const ScheduledForDiff = (props) => {
  const { previous, current } = props;

  const formatDateTime = useFormatDateTime();

  return (
    <Change
      titleKey={getLabelKeyForNotificationMetaKey("scheduled_for")}
      current={current}
      previous={previous}
      renderItem={(scheduledFor) => (
        <Text size={12}>
          {formatDateTime(scheduledFor, DateTime.DATETIME_MED)}
        </Text>
      )}
    />
  );
};

const SpecialInstructionsDiff = (props) => {
  const { previous, current } = props;
  return (
    <Change
      titleKey={getLabelKeyForNotificationMetaKey("special_instructions")}
      current={current}
      previous={previous}
      renderItem={(specialInstructions) => (
        <Text size={12}>{specialInstructions}</Text>
      )}
    />
  );
};

const InternalNotesDiff = (props) => {
  const { previous, current } = props;

  return (
    <Change
      titleKey={getLabelKeyForNotificationMetaKey("internal_notes")}
      current={current}
      previous={previous}
      renderItem={(notes: { note: string }[]) => (
        <View style={{ maxWidth: "40%" }}>
          {notes.map(({ note }) => (
            <Text
              key={note}
              size={12}
              numberOfLines={1}
              style={{
                flexGrow: 1,
                flexShrink: 1,
              }}
            >
              {note}
            </Text>
          ))}
        </View>
      )}
    />
  );
};

const OrderKindDiff = (props) => {
  const { previous, current } = props;
  return (
    <Change
      titleKey={getLabelKeyForNotificationMetaKey("kind")}
      current={current}
      previous={previous}
      renderItem={(orderKind) => (
        <Text size={12} id={getLabelKeyForOrderKind(orderKind)} />
      )}
    />
  );
};

const Meta = (props) => {
  const { meta } = props;

  return (
    <View>
      {Boolean(meta?.scheduled_for) && (
        <ScheduledForDiff
          previous={meta.scheduled_for.previously_was}
          current={meta.scheduled_for.current}
        />
      )}
      {Boolean(meta?.kind) && (
        <OrderKindDiff
          previous={meta.kind.previously_was}
          current={meta.kind.current}
        />
      )}
      {Boolean(meta?.special_instructions) && (
        <SpecialInstructionsDiff
          previous={meta.special_instructions.previously_was}
          current={meta.special_instructions.current}
        />
      )}
      {Boolean(meta?.internal_notes) && (
        <InternalNotesDiff
          previous={meta.internal_notes.previously_was}
          current={meta.internal_notes.current}
        />
      )}
    </View>
  );
};

const Notification = (props) => {
  const { notification, isLast, onPress, onPressAccept } = props;

  const item = useSelector((state) =>
    notification.item_uuid
      ? OrderSelectors.selectItemById(state, notification.item_uuid)
      : null
  );

  const navigateToProps = useNotificationLinkProps(
    notification?.order_uuid,
    notification?.item_uuid
  );

  return (
    <NotificationContainer
      notification={notification}
      isLast={isLast}
      navigationProps={
        ![
          NotificationTypes.ORDER_CHANGED,
          NotificationTypes.ORDER_CANCELLED,
        ].includes(notification.notification_type) && navigateToProps
      }
      onPressAccept={onPressAccept}
      onPress={onPress}
    >
      {notification.notification_type === NotificationTypes.ORDER_CHANGED ? (
        <Meta meta={notification.meta} />
      ) : (
        (item ||
          notification.notification_type ===
            NotificationTypes.ITEM_REMOVED) && (
          <Text color={"#9b9b9b"} size={14}>
            [
            {notification.notification_type === NotificationTypes.ITEM_REMOVED
              ? notification.meta.name
              : item.name}
            ]
          </Text>
        )
      )}
    </NotificationContainer>
  );
};

type Props = {
  notifications: AppNotification[];
  titleKey?: string;
  iconName?: MaterialCommunityIconName | string;
};

const List = (props: Props) => {
  const { titleKey, notifications } = props;

  const logEvent = useLogEventCallback();

  const {
    hasUnaccepted,
    onViewableItemsChanged,
    onPressMarkAllAsRead,
    onPressMarkAllAsReadAndAccepted,
  } = useNotificationCallbacks(notifications);

  return (
    <View style={{ backgroundColor: "white", paddingVertical: 12 }}>
      <FlatList
        data={notifications}
        renderItem={({ item, index }) => {
          return (
            <Notification
              notification={item}
              isLast={index === notifications.length - 1}
              key={item.uuid}
              onPressAccept={() => {
                logEvent(
                  AnalyticsEvents.notificationChanged({
                    action: NotificationEventActions.accept,
                    screen: RouteNames.Home.Main.Drawer.Tabs.Overview._,
                    isIndividualButton: true,
                  })
                );
              }}
              onPress={() => {
                logEvent(
                  AnalyticsEvents.notificationPressed({
                    screen: RouteNames.Home.Main.Drawer.Tabs.Overview._,
                    isOnAlert: false,
                  })
                );
              }}
            />
          );
        }}
        keyExtractor={(item) => item.uuid}
        onViewableItemsChanged={onViewableItemsChanged}
        ListHeaderComponent={
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              paddingHorizontal: 12,
            }}
          >
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <Text
                id={titleKey}
                size={18}
                font={"bold"}
                color={colors.text.dark}
              />
            </View>
            <ControlButtons
              hasUnread={false}
              hasUnaccepted={hasUnaccepted}
              onPressMarkAllAsRead={async () => {
                await onPressMarkAllAsRead();

                logEvent(
                  AnalyticsEvents.notificationChanged({
                    action: NotificationEventActions.read,
                    screen: RouteNames.Home.Main.Drawer.Tabs.Overview._,
                    isIndividualButton: false,
                  })
                );
              }}
              onPressMarkAllAsReadAndAccepted={async () => {
                await onPressMarkAllAsReadAndAccepted();

                logEvent(
                  AnalyticsEvents.notificationChanged({
                    action: NotificationEventActions.accept,
                    screen: RouteNames.Home.Main.Drawer.Tabs.Overview._,
                    isIndividualButton: false,
                  })
                );
              }}
            />
          </View>
        }
      />
    </View>
  );
};

export const UnattendedList = (props) => {
  const { orderUUID } = props;

  const notifications = useSelector((state) =>
    // @ts-ignore
    NotificationsSelectors.selectUnattendedForOrder(state, orderUUID)
  );

  if (!notifications?.length) {
    return null;
  }

  return (
    <List
      notifications={notifications}
      titleKey={"overview.orderDetails.notifications.unattended"}
      iconName={"notification-important"}
    />
  );
};

export const AttendedList = (props) => {
  const { orderUUID } = props;

  const notifications = useSelector((state) =>
    // @ts-ignore
    NotificationsSelectors.selectAttendedForOrder(state, orderUUID)
  );

  if (!notifications?.length) {
    return null;
  }

  return (
    <List
      notifications={notifications}
      titleKey={"overview.orderDetails.notifications.attended"}
      iconName={"notifications"}
    />
  );
};
