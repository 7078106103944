import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { SelectStoreScreen } from "modules/Auth/screens/SelectStore";
import { ReadyAccountScreen } from "navigation/components/ReadyAccountScreen";
import { DeviceNavigator } from "navigation/DeviceNavigator";
import { useSelector } from "react-redux";
import { UserSelectors } from "../store/User";
import { AuthenticationNavigator } from "./AuthenticationNavigator";
import { RouteNames } from "./linkingConfig";

const Stack = createNativeStackNavigator();

const RootRouter = () => {
  const isAuthenticated = useSelector(UserSelectors.selectIsAuthenticated);
  const isAuthenticationInProgress = useSelector(
    UserSelectors.selectIsAuthenticationInProgress
  );
  const selectedAccountReady = useSelector(
    UserSelectors.selectSelectedAccountReady
  );
  const account = useSelector(UserSelectors.selectAccount);

  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      {isAuthenticated ? (
        selectedAccountReady ? (
          <Stack.Screen name={RouteNames.Home._} component={DeviceNavigator} />
        ) : (
          <Stack.Screen
            name={RouteNames.ReadyAccount._}
            component={ReadyAccountScreen}
          />
        )
      ) : isAuthenticationInProgress && !account ? (
        <Stack.Screen
          name={RouteNames.SelectStore._}
          component={SelectStoreScreen}
        />
      ) : (
        <Stack.Screen
          name={RouteNames.Auth._}
          component={AuthenticationNavigator}
          options={{
            animationTypeForReplace: !isAuthenticated ? "pop" : "push",
          }}
        />
      )}
    </Stack.Navigator>
  );
};

export default RootRouter;
