import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "API/modules/Notifications";
import type { AppNotification } from "store/Notifications/types";

const markAsReadAndAccepted = createAsyncThunk(
  "NOTIFICATIONS/MARK_AS_READ_AND_ACCEPTED",
  async (payload: { uuids: AppNotification["uuid"][] }) => {
    const { uuids } = payload;
    try {
      await API.markAsRead(uuids);
      await API.markAsAccepted(uuids);
    } catch (e) {
      // todo
      console.log(e);
    }
  }
);

export default {
  markAsReadAndAccepted,
};
