import { zodResolver } from "@hookform/resolvers/zod";
import { convertHostNameToBaseUrl, DEFAULT_BASE_URL, setBaseURL } from "API";
import { Button, FormControl, Input, Text } from "components";
import colors from "config/colors";
import {
  EnvironmentServer,
  SelectEnvironmentModal,
} from "modules/Auth/components/SelectEnvironmentModal";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { StyleSheet, View } from "react-native";
import { useDispatch } from "react-redux";
import { AuthThunks } from "store/Auth";
import { FormValue, schema } from "./schema";

const defaultValues = {
  email: "",
};

type Props = {
  onSuccess: () => void;
};

export const ForgotPasswordForm = ({ onSuccess }: Props) => {
  const [authError, setAuthError] = useState<string | null>(null);
  const [environmentServerData, setEnvironmentServerData] = useState<
    EnvironmentServer[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const methods = useForm({
    mode: "onSubmit",
    reValidateMode: "onBlur",
    defaultValues,
    resolver: zodResolver(schema),
  });

  const {
    handleSubmit,
    control,
    getValues,
    formState: { isSubmitting, errors },
  } = methods;

  const onResetPassword = async (data: FormValue) => {
    const { payload } = await dispatch(AuthThunks.resetPassword(data));

    setIsLoading(false);

    if (payload.error) {
      setAuthError(payload.error);

      return;
    }

    onSuccess();
  };

  const onEnvironmentCheck = async (environment: EnvironmentServer) => {
    setBaseURL(convertHostNameToBaseUrl(environment.hostname));

    setEnvironmentServerData([]);

    await onResetPassword(getValues());
  };

  const onSubmit = handleSubmit(async (data: FormValue) => {
    if (isSubmitting) {
      return;
    }

    const { payload } = await dispatch(AuthThunks.fetchEnvironments(data));

    if (!payload?.found_environments) {
      setAuthError("UNKNOWN");

      return;
    }

    if (payload.found_environments.length > 1) {
      setEnvironmentServerData(payload.found_environments);

      setIsLoading(true);

      return;
    }

    const environment = payload.found_environments[0];

    setBaseURL(
      environment
        ? convertHostNameToBaseUrl(environment.hostname)
        : DEFAULT_BASE_URL
    );

    await onResetPassword(data);
  });

  const errorKey =
    errors.email?.message || (authError ? `errors.${authError}` : "");

  return (
    <>
      <View style={styles.container}>
        <FormControl
          control={control}
          name="email"
          defaultValue={defaultValues.email}
          labelKey="common.form.email.label"
          errorKey={errorKey}
          render={({ field: { value, onChange, onBlur } }) => {
            return (
              <Input
                autoCapitalize="none"
                keyboardType="email-address"
                placeholderKey="common.form.email.placeholder"
                textContentType="username"
                value={value}
                onChangeText={onChange}
                onBlur={onBlur}
                onSubmitEditing={onSubmit}
              />
            );
          }}
        />
        <Button
          onPress={() => onSubmit()}
          style={{
            backgroundColor: colors.main,
            marginTop: 20,
          }}
          innerStyle={{ paddingVertical: 12, paddingHorizontal: 12 }}
          isFetching={isSubmitting || isLoading}
          titleId="authScreen.forgotPassword.submitButton.label"
        />
      </View>
      <SelectEnvironmentModal
        isVisible={environmentServerData.length > 1}
        onConfirm={onEnvironmentCheck}
        onClose={() => {
          if (environmentServerData.length > 1) {
            setEnvironmentServerData([]);
            setIsLoading(false);
          }
        }}
        data={environmentServerData}
      />
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    marginVertical: 20,
  },
  subtitle: {
    marginVertical: 8,
  },
});
