import { MaterialCommunityIcons } from "@expo/vector-icons";
import { useNavigation, useRoute } from "@react-navigation/native";
import {
  CancelledLabel,
  CloseButton,
  DeliveryStatusBadge,
  NonCookableBadge,
  OrderStartPreparationStatusBadge,
  Text,
  withErrorBoundary,
} from "components";
import colors from "config/colors";
import { isEmpty } from "lodash";
import {
  ActionButtonItem,
  HasUnacceptChangesLabel,
} from "modules/ChefScene/components/ActionButton";
import { ReprintButtonItem } from "modules/ChefScene/components/ActionButton/ReprintButton";
import Addons from "modules/ChefScene/components/Addons";
import { ItemError } from "modules/ChefScene/components/Item";
import NotificationsLog from "modules/ChefScene/ItemDetailView/NotificationsLog";
import { RemakeOrderDescription } from "modules/OverviewScene/components";
import { RouteNames } from "navigation/linkingConfig";
import { useScreenContext } from "navigation/MainNavigator";
import { useEffect, useMemo, useRef } from "react";
import {
  ActivityIndicator,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native";
import { useSelector } from "react-redux";
import { AppActions } from "store/App";
import { useAppDispatch } from "store/hooks";
import {
  NotificationActions,
  NotificationsSelectors,
} from "store/Notifications";
import { NotificationTypes } from "store/Notifications/enums";
import { OrderActions, OrderSelectors } from "store/Orders";
import { ItemPreparationStatuses, OrderStatuses } from "store/Orders/enums";
import type { Order, OrderItem } from "store/Orders/types";
import { checkIfScheduledOrder } from "store/Orders/utils";
import { SettingSelectors } from "store/Settings";
import { UserSelectors } from "store/User";
import { selectIsFetching } from "store/util/functions";
import {
  AnalyticsEvents,
  useEnterScreen,
  useLogEventCallback,
  useLogItemStatusChangedUiUpdate,
} from "util/analytics";
import {
  getAddressLine,
  getColorForItemStatus,
  getIconNameForOrderKind,
  getLabelKeyForOrderKind,
} from "util/helpers";

const Block = (props) => {
  const { titleKey, backgroundColor = "white", children } = props;

  return (
    <View
      style={[
        styles.block,
        {
          backgroundColor,
        },
      ]}
    >
      {!!titleKey && (
        <Text
          size={16}
          font={"medium"}
          color={colors.text.dark}
          id={titleKey}
          style={{ marginBottom: 5 }}
        />
      )}
      <View style={{ marginLeft: 5 }}>{children}</View>
    </View>
  );
};

const getHeaderColor = (status, isScheduledFutureOrder, orderStatus) => {
  return orderStatus === OrderStatuses.CANCELLED
    ? colors.ui.statuses.cancelled
    : isScheduledFutureOrder &&
      ![
        ItemPreparationStatuses.BEING_PREPARED,
        ItemPreparationStatuses.PREPARED,
      ].includes(status)
    ? colors.ui.statuses.scheduled
    : getColorForItemStatus(status);
};
// todo removed web title
const ItemDetailView = (props) => {
  const { canGoBack, item, order, onPressGoBack, isRemoved } = props;

  const { shouldDisplayActionButton } = useSelector(
    SettingSelectors.selectItemDetailsInterfaceSettings
  );

  const logEvent = useLogEventCallback();
  useEnterScreen("itemDetails");

  const hasUnacceptedNotifications = useSelector((state) =>
    NotificationsSelectors.selectOrderItemHasUnattended(state, item?.uuid)
  );
  const { shouldDisplayInternalNotes } = useSelector(
    SettingSelectors.selectItemDetailsInterfaceSettings
  );

  const featureFlags = useSelector(UserSelectors.selectFeatureFlags);

  const navigation = useNavigation();

  const dispatch = useAppDispatch();

  const { updateScreen } = useScreenContext();

  const isScheduledFutureOrder = checkIfScheduledOrder(order);

  const headerColor = item
    ? getHeaderColor(item?.status, isScheduledFutureOrder, order?.order_status)
    : null;

  const { addItemToUpdatedItemList } = useLogItemStatusChangedUiUpdate([item]);

  const getStatusChangeCallback =
    (params?: any) =>
    (
      statusToUpdate: ItemPreparationStatuses,
      duration: number,
      startDate: Date,
      endDate: Date
    ) => {
      logEvent(
        AnalyticsEvents.itemStatusChanged({
          isScheduled: isScheduledFutureOrder,
          screen: RouteNames.Home.Main.ItemDetails,
          status: statusToUpdate,
          itemId: item.uuid,
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
          executionTime: duration,
          ...params,
        })
      );

      addItemToUpdatedItemList({
        mode: "single",
        startDate,
        endDate,
        status: statusToUpdate,
        itemId: item.uuid,
        orderId: item.order_uuid,
        screen: RouteNames.Home.Main.ItemDetails,
      });
    };

  return (
    <ScrollView
      style={{
        flexGrow: 1,
        backgroundColor: !canGoBack ? colors.lightBlue2 : null,
      }}
      contentContainerStyle={{
        flexGrow: 1,
      }}
    >
      {isRemoved && (
        <CancelledLabel
          titleKey={!item || !order ? "chef.itemDetails.notFound" : undefined}
        />
      )}
      <TouchableOpacity
        style={styles.overlay}
        activeOpacity={0.9}
        onPress={onPressGoBack}
      />
      {!item || !order ? (
        <View
          style={[styles.container, !canGoBack && { marginHorizontal: "10%" }]}
        >
          <View style={[styles.header, { backgroundColor: headerColor }]}>
            <View />
            <CloseButton onPress={onPressGoBack} />
          </View>
        </View>
      ) : (
        <View
          style={[styles.container, !canGoBack && { marginHorizontal: "10%" }]}
        >
          {order.order_status === OrderStatuses.CANCELLED && <CancelledLabel />}
          <View style={[styles.header, { backgroundColor: headerColor }]}>
            <Text color={"white"} size={20} font={"bold"}>
              #{order.description}{" "}
              {order.remake_for_order_uuid &&
                order.remake_for_order_description && (
                  <RemakeOrderDescription
                    description={order.remake_for_order_description}
                    color={colors.brightBeige}
                  />
                )}
            </Text>
            <CloseButton onPress={onPressGoBack} color="white" />
          </View>
          <View style={styles.top}>
            <View style={{ flexDirection: "row" }}>
              <View style={styles.delivery}>
                <MaterialCommunityIcons
                  name={getIconNameForOrderKind(order.kind)}
                  color={"white"}
                  size={28}
                />
                <Text
                  color={"white"}
                  font={"bold"}
                  size={12}
                  align="center"
                  style={{ marginTop: 4 }}
                  id={getLabelKeyForOrderKind(order.kind)}
                />
              </View>
              {featureFlags.display_customer_address && (
                <View style={{ marginHorizontal: 12 }}>
                  <Text font={"bold"} size={18} color={colors.text.dark}>
                    {order.recipient.full_name}
                  </Text>
                  {order.dropoff_location && (
                    <Text font={"medium"} color={"#555553"} size={14}>
                      {getAddressLine(order.dropoff_location)}
                    </Text>
                  )}
                </View>
              )}
            </View>
            <TouchableOpacity
              onPress={() => {
                navigation.navigate(RouteNames.Home.Main.Drawer._);
                updateScreen("overview");

                dispatch(
                  AppActions.setSelectedOrderUUID({ orderUUID: order?.uuid })
                );
              }}
              style={styles.unableToPrepare}
              activeOpacity={0.75}
            >
              <Text
                font={"medium"}
                color={colors.text.dark}
                id={"chef.itemDetails.viewEntireOrder"}
                size={14}
              />
              <MaterialCommunityIcons
                name={"chevron-right"}
                color={colors.text.dark}
                size={18}
              />
            </TouchableOpacity>
            <View style={{ alignItems: "stretch" }}>
              {order.order_status === OrderStatuses.CANCELLED ||
              isRemoved ? null : hasUnacceptedNotifications ? (
                <HasUnacceptChangesLabel />
              ) : shouldDisplayActionButton ? (
                item.status !== ItemPreparationStatuses.PREPARED ? (
                  <ActionButtonItem
                    includePrintButton={item.cookable}
                    order={order}
                    item={item}
                    isScheduled={isScheduledFutureOrder}
                    onPressFinish={getStatusChangeCallback({ print: false })}
                  />
                ) : item.cookable &&
                  item.status === ItemPreparationStatuses.PREPARED &&
                  featureFlags.display_finish_and_print_button ? (
                  <ReprintButtonItem
                    itemUUID={item.uuid}
                    onPress={() => {
                      logEvent(
                        AnalyticsEvents.reprintButtonPressed({
                          screen: RouteNames.Home.Main.ItemDetails,
                        })
                      );
                    }}
                  />
                ) : null
              ) : null}
            </View>
          </View>
          <View
            style={[styles.top, { paddingTop: 0, alignSelf: "flex-start" }]}
          >
            {featureFlags.kds_enabled_traffic_light_system &&
              item.status === ItemPreparationStatuses.NOT_BEING_PREPARED && (
                <View style={{ marginTop: 4 }}>
                  <OrderStartPreparationStatusBadge
                    status={order.start_preparation_status}
                  />
                </View>
              )}
            {!item.cookable && <NonCookableBadge />}
            {order.order_status !== OrderStatuses.CANCELLED &&
              order.job &&
              [
                ItemPreparationStatuses.PREPARED,
                ItemPreparationStatuses.COMPLETED,
              ].includes(item.status) && (
                <View style={{ marginTop: 4 }}>
                  <DeliveryStatusBadge
                    status={order.job.delivery_status}
                    geoVerified={order.job.geo_verified}
                  />
                </View>
              )}
          </View>
          <View
            style={{
              backgroundColor: "#e9e9e9",
              padding: 12,
            }}
          >
            <Text size={24} font={"bold"} color={colors.text.dark}>
              {item.quantity} - {item.name}
            </Text>
            {!isEmpty(item.addons) && (
              <Block>
                <Addons
                  fontSize={30}
                  addonsType={item.addons_type}
                  addons={item.addons}
                />
              </Block>
            )}
            {!!(
              item.special_instructions && item.special_instructions.length > 0
            ) && (
              <Block titleKey={"chef.itemDetails.specialInstructions"}>
                <Text size={18}>{item.special_instructions}</Text>
              </Block>
            )}
            <NotificationsLog
              itemUUID={item.uuid}
              addonsType={item.addons_type}
            />
            {shouldDisplayInternalNotes &&
              order.internal_notes &&
              order.internal_notes.length > 0 && (
                <Block
                  titleKey={"chef.itemDetails.orderComments"}
                  backgroundColor={"#ffbec0"}
                >
                  <Text size={18}>
                    {order.internal_notes.map((note) => note.note).join("\n")}
                  </Text>
                </Block>
              )}
          </View>
        </View>
      )}
    </ScrollView>
  );
};

const ItemDetailError = (props) => {
  const { canGoBack, onPressGoBack } = props;

  return (
    <View style={[styles.container, !canGoBack && { marginHorizontal: "10%" }]}>
      <View
        style={[
          styles.header,
          { backgroundColor: "white", justifyContent: "flex-end" },
        ]}
      >
        <CloseButton onPress={onPressGoBack} color="white" />
      </View>
      <ItemError {...props} />
    </View>
  );
};

const WithErrorBoundary = withErrorBoundary(ItemDetailView, {
  screen: RouteNames.Home.Main.ItemDetails,
  key: "itemDetails-item",
  renderFallback: (props: any) => <ItemDetailError {...props} />,
});

const WithProps = (props) => {
  const { navigation } = props;

  const { uuid } = (useRoute().params || {}) as {
    uuid?: string;
  };

  const isFetchingNotification = useSelector((state) =>
    selectIsFetching(state, NotificationActions.fetch)
  );
  const notifications = useSelector(NotificationsSelectors.selectAll);

  const isFetchingOrder = useSelector((state) =>
    selectIsFetching(state, OrderActions.fetch)
  );
  const storedItem: OrderItem = useSelector((state) =>
    uuid ? OrderSelectors.selectItemById(state, uuid) : null
  );

  const isFetched = useRef(false);
  const isFetching =
    !isFetched.current && (isFetchingNotification || isFetchingOrder);

  const isRemoved = Boolean(!isFetching && uuid && !storedItem);

  useEffect(() => {
    if (isFetching || isFetched.current) return;

    isFetched.current = true;
  }, [isFetching]);

  const { item, orderUUID } = useMemo(() => {
    if (storedItem) {
      return {
        item: storedItem,
        orderUUID: storedItem.order_uuid,
      };
    }

    const notification = notifications.find(
      (notification) =>
        notification.notification_type === NotificationTypes.ITEM_REMOVED &&
        notification.item_uuid === uuid
    );

    if (notification) {
      return {
        item: notification.meta,
        orderUUID: notification.order_uuid,
      };
    }

    return {};
  }, [notifications, storedItem, uuid]);

  const order: Order = useSelector((state) =>
    orderUUID ? OrderSelectors.selectById(state, orderUUID) : null
  );

  const onPressGoBack = () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.replace(RouteNames.Home.Main._);
    }
  };

  if (isFetching) {
    return (
      <View
        style={{
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ActivityIndicator color="white" size={50} />
      </View>
    );
  }

  return (
    <WithErrorBoundary
      item={item}
      order={order}
      canGoBack={navigation.canGoBack()}
      onPressGoBack={onPressGoBack}
      isRemoved={isRemoved}
    />
  );
};

const styles = StyleSheet.create({
  block: {
    borderColor: "black",
    borderRadius: 4,
    borderWidth: StyleSheet.hairlineWidth,
    marginTop: 8,
    padding: 12,
  },
  container: {
    backgroundColor: "white",
    borderRadius: 4,
    flexGrow: 1,
    marginHorizontal: "10%",
    marginVertical: 50,
  },
  delivery: {
    alignItems: "center",
    backgroundColor: "#212121",
    borderRadius: 4,
    height: 84,
    justifyContent: "center",
    padding: 4,
    width: 84,
  },
  header: {
    alignItems: "center",
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 4,
    paddingHorizontal: 12,
  },
  overlay: {
    bottom: 0,
    left: 0,
    position: "absolute",
    right: 0,
    top: 0,
  },
  top: {
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 12,
  },
  unableToPrepare: {
    alignItems: "center",
    backgroundColor: "#efd3d4",
    borderRadius: 4,
    flexDirection: "row",
    height: 30,
    justifyContent: "center",
    marginLeft: "auto",
    marginRight: 12,
    paddingHorizontal: 10,
  },
});

export default WithProps;
