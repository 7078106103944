import { executeRequest } from "API/util/functions";
import { AxiosRequestConfig } from "axios";
import type { Account } from "store/User/types";

const fetch = (config?: AxiosRequestConfig) =>
  executeRequest((api) => api.get("/v1/current_user", config));

const patch = (payload: any, config?: AxiosRequestConfig) =>
  executeRequest((api) => api.patch("/v1/current_user", payload, config));

const fetchAccount = (config?: AxiosRequestConfig) =>
  executeRequest((api) => api.get("/v1/account", config));

const patchAccount = (payload: Partial<Account>, config?: AxiosRequestConfig) =>
  executeRequest((api) =>
    api.patch("/v1/account", { account: payload }, config)
  );

export default {
  fetch,
  patch,
  fetchAccount,
  patchAccount,
};
