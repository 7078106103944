export enum NotificationTypes {
  ORDER_CANCELLED = "order_cancelled",
  ORDER_CHANGED = "order_changed",
  ITEM_ADDED = "item_added",
  ITEM_REMOVED = "item_removed",
  ITEM_CHANGED = "item_changed",
  COMPANY = "account",
}

export enum NotificationPriority {
  "LOW" = "low",
  "NORMAL" = "normal",
  "HIGH" = "high",
  "CRITICAL" = "critical",
}

export const ChangeNotificationTypesList = [
  NotificationTypes.ORDER_CHANGED,
  NotificationTypes.ITEM_CHANGED,
];
