import colors from "config/colors";
import { PureComponent } from "react";
import { withTranslation, WithTranslationProps } from "react-i18next";
import {
  Text as RNText,
  TextProps as RNTextProps,
  TextStyle,
} from "react-native";

/***
 * This component accepts the following props.
 *
 * font
 * - black
 * - bold
 * - medium
 * - normal (default)
 * - light
 *
 * color
 * - any hex/rgb/rgba/etc colors
 * - any color defined in config/colors
 *
 * size
 * - a font size integer
 *
 * align
 * - same as textAlign
 ***/
export type TextFont = "black" | "bold" | "medium" | "normal" | "light";

export interface TextProps extends RNTextProps {
  font?: TextFont;
  color?: string;
  size?: number;
  align?: "left" | "right" | "center";
  style?: TextStyle | any;
  id?: string;
}

class Text extends PureComponent<TextProps & WithTranslationProps> {
  static defaultProps = {
    font: "normal",
  };

  render() {
    const { size, color, style, align, font, id, children, i18n, ...rest } =
      this.props;

    return (
      <RNText
        style={[
          { flexWrap: "wrap" },
          this.fontFamily(),
          this.color(),
          this.fontSize(),
          this.textAlign(),
          this.props.style,
        ]}
        {...rest}
      >
        {id ? i18n.t(id) : children}
      </RNText>
    );
  }

  fontFamily() {
    switch (this.props.font) {
      case "black":
        return { fontFamily: FontFamilies.black };
      case "bold":
        return { fontFamily: FontFamilies.bold };
      case "medium":
        return { fontFamily: FontFamilies.medium };
      case "light":
        return { fontFamily: FontFamilies.light };
      case "normal":
      default:
        return { fontFamily: FontFamilies.normal };
    }
  }

  color() {
    if (this.props.color in colors) {
      return { color: colors[this.props.color] };
    } else {
      return { color: this.props.color };
    }
  }

  fontSize() {
    return { fontSize: this.props.size };
  }

  textAlign() {
    return { textAlign: this.props.align };
  }
}

export enum FontFamilies {
  black = "Roboto-Black",
  bold = "Roboto-Bold",
  medium = "Roboto-Medium",
  normal = "Roboto-Regular",
  light = "Roboto-Light",
}

export default withTranslation()(Text);
