import { MaterialCommunityIcons } from "@expo/vector-icons";
import { Button } from "components";
import colors from "config/colors";
import { StyleSheet } from "react-native";
import { MaterialCommunityIconName } from "types/icons";

type NavigationButtonProps = {
  label?: string;
  labelKey?: string;
  iconName?: MaterialCommunityIconName;
  onPress?: () => void;
  isSelected?: boolean;
  activeColor?: string;
  isDisabled?: boolean;
  isMinimized?: boolean;
  isShownBadge?: boolean;
};
export const NavigationButton = (props: NavigationButtonProps) => {
  const {
    label,
    labelKey,
    iconName,
    onPress,
    isSelected,
    activeColor,
    isDisabled,
    isMinimized,
    isShownBadge,
  } = props;

  const _styles = isSelected ? stylesSelected : stylesUnselected;

  return (
    <Button
      onPress={onPress}
      style={[
        styles.item,
        _styles.item,
        isSelected &&
          activeColor && {
            backgroundColor: activeColor,
          },
      ]}
      innerStyle={[
        styles.itemInner,
        { flexDirection: isMinimized ? "column" : "row" },
      ]}
      font="bold"
      textStyle={[
        isMinimized
          ? {
              marginTop: 4,
              marginLeft: 0,
            }
          : { marginLeft: 8 },
        _styles.itemLabel,
        isSelected &&
          activeColor && {
            color: "white",
          },
      ]}
      color={isSelected ? colors.darkBlue2 : colors.lightBlue2}
      size={isMinimized ? 10 : 12}
      titleId={labelKey}
      title={label}
      disabled={isDisabled}
      badgeContent={isShownBadge ? "!" : null}
      icon={
        <MaterialCommunityIcons
          name={iconName}
          size={20}
          style={[
            _styles.itemLabel,
            isSelected &&
              activeColor && {
                color: "white",
              },
          ]}
        />
      }
    />
  );
};

export const NavigationButtonSecondary = (props: NavigationButtonProps) => {
  const { label, labelKey, iconName, onPress, isMinimized, isShownBadge } =
    props;

  return (
    <Button
      onPress={onPress}
      icon={<MaterialCommunityIcons name={iconName} color="#FFF" size={20} />}
      color={colors.darkBlue3}
      size={isMinimized ? 10 : 12}
      style={styles.item}
      innerStyle={[
        styles.itemInner,
        { flexDirection: isMinimized ? "column" : "row" },
      ]}
      textStyle={
        isMinimized
          ? {
              marginTop: 4,
              marginLeft: 0,
            }
          : { marginLeft: 8 }
      }
      font="medium"
      titleId={labelKey}
      title={label}
      badgeContent={isShownBadge ? "!" : null}
    />
  );
};

export const NavigationButtonTertiary = (props: NavigationButtonProps) => {
  const { label, labelKey, iconName, onPress, isSelected, isShownBadge } =
    props;

  return (
    <Button
      onPress={onPress}
      icon={
        <MaterialCommunityIcons
          name={iconName}
          color={colors.darkGray}
          size={20}
        />
      }
      color={colors.white}
      size={12}
      style={[
        styles.item,
        { backgroundColor: isSelected ? colors.veryLightGray : colors.white },
      ]}
      innerStyle={styles.itemInner}
      textStyle={{ color: colors.darkGray }}
      font="medium"
      titleId={labelKey}
      title={label}
      badgeContent={isShownBadge ? "!" : null}
    />
  );
};

const styles = StyleSheet.create({
  item: {
    flexDirection: "row",
    marginTop: 8,
    borderRadius: 4,
    alignItems: "center",
    backgroundColor: colors.darkBlue3,
    justifyContent: "flex-start",
    overflow: "visible",
  },
  itemInner: {
    padding: 8,
    justifyContent: "flex-start",
  },
});

const stylesUnselected = StyleSheet.create({
  item: {
    backgroundColor: colors.lightBlue2,
  },
  itemLabel: {
    color: "white",
  },
});

const stylesSelected = StyleSheet.create({
  item: {
    backgroundColor: "white",
  },
  itemLabel: {
    color: colors.darkBlue2,
  },
});
