import Text, { TextProps } from "components/Text";
import colors from "config/colors";
import { View } from "react-native";

type Props = Partial<TextProps>;

export function HasUnacceptChangesLabel({ style, ...props }: Props) {
  return (
    <View
      style={[
        {
          justifyContent: "center",
          alignItems: "center",
          width: 84,
          height: 84,
          marginHorizontal: 4,
        },
        style,
      ]}
    >
      <Text
        font={"bold"}
        align={"center"}
        color={colors.text.dark}
        id={"notifications.hasUnacceptedChanges"}
        size={12}
        {...props}
      />
    </View>
  );
}
