import { MaterialCommunityIcons } from "@expo/vector-icons";
import Color from "color";
import Text, { TextFont } from "components/Text";
import { forwardRef } from "react";
import {
  ActivityIndicator,
  StyleProp,
  StyleSheet,
  TextStyle,
  View,
} from "react-native";
import { RectButton, RectButtonProps } from "react-native-gesture-handler";
import { MaterialCommunityIconName } from "types/icons";
import colors from "../config/colors";

const BADGE_SIZE = 16;

const getBackgroundColor = (color) => {
  switch (color) {
    case "green":
      return colors.green;
    case "red":
      return colors.red;
    case "blue":
      return colors.blue;
    case "gray":
      return "#b7b7b7";
    default:
      return color;
  }
};

export interface ButtonProps extends RectButtonProps {
  disabled?: boolean;
  isFetching?: boolean;
  titleId?: string;
  title?: string;
  iconName?: MaterialCommunityIconName;
  style?: StyleProp<View> | any;
  innerStyle?: StyleProp<View> | any;
  textStyle?: StyleProp<TextStyle>;
  iconStyle?: StyleProp<TextStyle>;
  icon?: React.ReactNode;
  children?: React.ReactNode;
  color?: string;
  innerColor?: string;
  font?: TextFont;
  size?: number;
  variant?: "solid" | "outline";
  badgeContent?: React.ReactNode;
}

export const Button = forwardRef(
  (props: ButtonProps, ref: React.ForwardedRef<RectButton>) => {
    const {
      onPress,
      disabled = false,
      isFetching,
      titleId,
      title,
      iconName,
      style,
      innerStyle,
      textStyle,
      iconStyle,
      icon,
      children,
      color = colors.blue,
      innerColor = "#fff",
      font = "bold",
      size = 14,
      variant = "solid",
      badgeContent,
      ...rest
    } = props;

    const backgroundColor = getBackgroundColor(color);
    const effectColor = Color(backgroundColor)
      .lighten(0.5)
      .saturate(1)
      .opaquer(0.5)
      .hexa();

    const isSolidVariant = variant === "solid";

    const isDisabled = disabled || isFetching;

    return (
      <View
        style={[
          styles.button,
          isSolidVariant
            ? { backgroundColor }
            : {
                backgroundColor,
                borderWidth: 1,
                borderStyle: "solid",
                borderColor: innerColor,
              },
          style,
        ]}
      >
        <RectButton
          {...rest}
          ref={ref}
          onPress={onPress}
          enabled={!isDisabled}
          rippleColor={effectColor}
          underlayColor={effectColor}
          activeOpacity={0.5}
          borderless={true}
          style={[
            styles.inner,
            innerStyle,
            {
              opacity: disabled ? 0.5 : 1,
              cursor: isDisabled ? "not-allowed" : "pointer",
            },
          ]}
          // exclusive={false}
        >
          <>
            {isFetching ? (
              <ActivityIndicator color={innerColor} size={"small"} />
            ) : icon ? (
              icon
            ) : iconName ? (
              <MaterialCommunityIcons
                color={innerColor}
                name={iconName}
                size={18}
                style={iconStyle}
              />
            ) : null}
            <Text
              style={[styles.text, textStyle]}
              id={titleId}
              color={innerColor}
              font={font}
              size={size}
              numberOfLines={1}
              align={"center"}
            >
              {title}
            </Text>
            {children}
          </>
        </RectButton>
        {Boolean(badgeContent) && (
          <View style={styles.badge}>
            <Text
              size={12}
              font="bold"
              color={colors.white}
              align="center"
              style={{ lineHeight: BADGE_SIZE }}
            >
              {badgeContent}
            </Text>
          </View>
        )}
      </View>
    );
  }
);

const styles = StyleSheet.create({
  button: {
    borderRadius: 4,
    justifyContent: "center",
    cursor: "pointer",
    overflow: "hidden",
  },
  inner: {
    paddingHorizontal: 8,
    paddingVertical: 6,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
    flexShrink: 1,
    flexGrow: 1,
  },
  text: {
    marginLeft: 5,
    flexShrink: 1,
  },
  icon: {
    marginRight: 5,
  },
  badge: {
    position: "absolute",
    top: -BADGE_SIZE / 3,
    right: -BADGE_SIZE / 3,
    borderRadius: 9999,
    width: BADGE_SIZE,
    height: BADGE_SIZE,
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    margin: 0,
    backgroundColor: colors.error,
  },
});
