import { Text } from "components/index";
import { TextProps } from "components/Text";
import { DEFAULT_ACTIVE_OPACITY } from "config/constants";
import { ReactNode, useRef, useState } from "react";
import { TouchableOpacity, View, ViewStyle } from "react-native";

type TextOverflowProps = {
  text: string;
  onPress?: () => void;
  Overflow?: ReactNode;
  style?: ViewStyle;
  textColor?: TextProps["color"];
  textSize?: TextProps["size"];
  textFont?: TextProps["font"];
};

export function TextOverflow(props: TextOverflowProps) {
  const { onPress, text, style, Overflow, textSize, textFont, textColor } =
    props;
  const containerWidth = useRef(0);
  const fullTextWidth = useRef(Number.MAX_SAFE_INTEGER);

  const getShouldHideButton = () => {
    return fullTextWidth.current < containerWidth.current;
  };

  const [isButtonHidden, setHideButton] = useState(getShouldHideButton);

  const onLayoutUpdate = () => {
    const shouldHideButton = getShouldHideButton();

    if (shouldHideButton !== isButtonHidden) {
      setHideButton(shouldHideButton);
    }
  };

  return (
    <TouchableOpacity
      activeOpacity={DEFAULT_ACTIVE_OPACITY}
      onPress={onPress}
      style={style}
      disabled={isButtonHidden || !onPress}
    >
      <View
        key={text}
        style={{
          flexGrow: 1,
          flexShrink: 1,
          overflow: "hidden",
        }}
        onLayout={({ nativeEvent }) => {
          const { width } = nativeEvent.layout;

          containerWidth.current = width;
          onLayoutUpdate();
        }}
      >
        <View
          style={{
            position: "absolute",
            maxWidth: null,
            opacity: 0,
          }}
        >
          <Text
            style={{
              position: "absolute",
              maxWidth: null,
              opacity: 0,
            }}
            size={textSize}
            font={textFont}
            numberOfLines={1}
            onLayout={({ nativeEvent }) => {
              const { width } = nativeEvent.layout;

              fullTextWidth.current = width;
              onLayoutUpdate();
            }}
          >
            {text}
          </Text>
        </View>
        <Text
          color={textColor}
          size={textSize}
          font={textFont}
          numberOfLines={1}
        >
          {text}
        </Text>
      </View>
      {!isButtonHidden && Overflow}
    </TouchableOpacity>
  );
}
